import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class SignalerReparation extends Component {

    constructor(props){
        super(props);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.state = ({     
            nomSite: '',
            nomMateriel: '',
            typeIntervention: '',
            dateIntervention: '',
            remarque: '',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/materielDetails?id=${ctx.props.userIdfromStore}&idMateriel=${ctx.props.materielIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nomSite: data.materielDetail.nomSite,
                nomMateriel: data.materielDetail.nomMateriel
              })
            })
            .catch((error)=> {
                console.log('Request failed in my SignalerReparation', error)
            });
          }
        

handleSubmitUpdate() {
    let ctx = this;

    fetch(`${ip}/enregistrerReparation?idMateriel=${ctx.state.materielIdfromStore}`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `idMateriel=${ctx.props.materielIdfromStore}&nomSite=${ctx.state.nomSite}&typeIntervention=${ctx.state.typeIntervention}&dateIntervention=${ctx.state.dateIntervention}&remarque=${ctx.state.remarque}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            isClick: true                   
           })
        })
        .catch((error)=> {
            console.log('Request failed in my /miseAjourPlanningSite request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationReparation" />
          }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Intervention</h1>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginBottom:"40px", fontSize:"14px"}}> 
                <Col style={{textAlign:"center"}}>
                  MATERIEL:                   
                  <span style={{paddingLeft:"5px", paddingRight:"30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                    {this.state.nomMateriel}
                  </span>
                  SITE:                   
                    <span style={{paddingLeft:"5px", paddingRight: "30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                      {this.state.nomSite}
                    </span>
                </Col>
            </Row>

            <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>
                <Row className="justify-content-center align-items-center" 
                style={{paddingBottom:"60px"}} form>             
                    <Col className="justify-content-center align-items-center" md={6}>
                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="typeIntervention" style={{fontWeight: 'bolder'}}>Type d'intervention:</Label>
                                <Input type="text" name="typeIntervention" id="typeIntervention" rows={1}
                                placeholder=""
                                onChange={(e) => {this.setState({typeIntervention: e.target.value});}} />
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="dateIntervention" style={{fontWeight: 'bolder'}}>Date d'intervention:</Label>
                                <Input type="date" name="dateIntervention" id="dateIntervention" rows={1}
                                    placeholder=""
                                    onChange={(e) => {this.setState({dateIntervention: e.target.value}); }} />
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="remarque" style={{fontWeight: 'bolder'}}>Remarque:</Label>
                                <Input type="textarea" name="remarque" id="remarque" 
                                placeholder=""
                                onChange={(e) => {this.setState({remarque: e.target.value})}}/>
                            </FormGroup>

                            <Row className="justify-content-center text-center align-items-center">
                                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                    <Link to="/MaterielDetails" style={{color:"white"}}>Annuler</Link></Button>
                                <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                                onClick={()=>{console.log("clic"); this.handleSubmitUpdate()}}>Valider</Button>
                            </Row>
                        
                    </Col>
                </Row>
            </Form>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
    console.log('materielIdfromStore', state.materielId)
      return { materielIdfromStore: state.materielId }
  }
    
    
  function mapDispatchToProps(dispatch) {
      return {
        saveId: function(idMateriel) {
            dispatch( {type: 'connect7',
             idMateriel,
            } );
         }
        }
      }
      
      export default connect(
        mapStatetoProps, 
        mapDispatchToProps
      )(SignalerReparation);