export default function(idMessage = '', action) {

  if(action.type == 'connect2') {
       console.log('Dans mon reducer --->',action.idMessage)
       return action.idMessage;
  } else {
      return idMessage;
  }
}

