import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import TableauSitesPrdts from './TableauSitesPrdts';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';

import TableFilter from 'react-table-filter';



class DemandesPrdtsPerso extends Component {

    constructor(props){
        super(props)
        this.state = ({     
            matricule:"",
            prenom:"",
            nom:"",
            allSite: [],
        })}
 

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/demandesPrdtsPerso?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
          //  console.log('RESULTAT DE demandesPrdtsPerso--->', data)
           var userSiteList = [];
           for (var i =0; i<data.user.listSites.length; i++) {
              userSiteList.push(data.user.listSites[i])
          }
          ctx.setState({
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            allSite: userSiteList})
        })
        .catch((error)=> {
            console.log('Request failed in my demandesPrdtsPerso request', error)
        });
    }


    render(){

      var usersListSite = [];
      var usersListSiteCopy=[...this.state.allSite]
    
    usersListSite = usersListSiteCopy.map((data,i) =>{console.log('rrrrr')
    return  (  
        <TableauSitesPrdts
            key={i}
            nomSite={data.nomSite}
            adresseSite={data.adresseSite}
            idSite={data._id}
            matricule={data.matricule}
            nom={data.nom}
            prenom={data.prenom}
            prenomCharge={data.prenomCharge}
            nomCharge={data.nomCharge}
            dateTraitement={data.dateTraitement}
        />)})
        
        console.log('usersListSite', usersListSite)


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    1. Profil
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Sites
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/PointagesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Pointages
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link" style={{fontSize: "14px"}}>
                    4. Demandes produits / matériels
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      onClick={()=>{
                      console.log('j ai cliqué sur le Site'); 
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Demandes congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      onClick={()=>{
                      console.log('j ai cliqué sur le Site'); 
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    6. Demandes acomptes
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{}}>
                <Col sm={{ size: 1, offset: 7 }} style={{textAlign: "right"}}>
                  <img src="./images/homme.png" height="20px" alt="ASP" style={{}}></img>
                </Col>

                <Col md={3}>
                    <h3 style={{marginLeft: "-20px", fontStyle:'italic', fontWeight:"normal", textAlign:"left", paddingTop: "10px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>
                      {this.state.nom} {this.state.prenom}
                    </h3>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Demandes de produits / matériels
                    </h1>
                </Col>
            </Row>

           
            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}} >
                
                <Table hover id="table-to-xls" 
                  className="tableau text-center align-items-center" 
                  style={{width:"800px"}}>
  
                    <thead>
                      <TableFilter 
                        rows={this.state.allUsers}
                        onFilterUpdate={this._filterUpdated}>
                            <th name="matricule" filterkey="matricule" casesensitive={'true'} showsearch={'true'}
                              style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                              SITE
                            </th>
                            <th name="nom" filterkey="nom" casesensitive={'true'} showsearch={'true'}
                              style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                              ADRESSE
                            </th>
                            <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                              VOIR
                            </th>
                      </TableFilter>
                    </thead> 
                    <tbody>
                      {usersListSite}
                    </tbody>
                </Table>
                  
              </Row>

              <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    >
                      <Link to="/Salaries" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>
                </Col>
            </Row>

  
            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    console.log("ttt pointage", state)
    console.log('je recois de mon reducer ID suivant dans "PointagesPerso : ',state.userId)
    


    return { userIdfromStore: state.userId }
  }
  
 


  export default connect(
    mapStatetoProps,
    null
    )(DemandesPrdtsPerso);