import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label, Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr'



class ModifDemandeAcomptePerso extends Component {

    constructor(props){
        super(props);
        this.handleSubmitModifDemandeAcompte = this.handleSubmitModifDemandeAcompte.bind(this);
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)

        this.state = ({     
            nomSite: "",
            matricule: this.props.matricule,
            nom: "",
            prenom: "",
            nomCharge: "",
            prenomCharge: "",
            date: "",
            montantAccompte: "",            
            remarque: "",
            etat: "",
            dateTraitement: "",
            isClick: false,
            show: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/modifierDemandeAcompte?idMessage=${ctx.props.messageAcompteIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                matricule: data.message.matricule,
                nom: data.message.nom, 
                prenom: data.message.prenom,
                date: data.message.date,
                etat: data.message.etat,
                dateTraitement: data.message.dateTraitement,
                remarque: data.message.remarque,
                nomCharge: data.message.nomCharge,
                prenomCharge: data.message.prenomCharge,
                montantAccompte: data.message.montantAccompte              })
            })
            .catch((error)=> {
                console.log('Request failed in my ModifDemandeAcompte', error)
            });
          }


    handleShow(){
    this.setState({show:!this.state.show})
    }
    
    handleClose(){
    this.setState({show: false})
    }
        

handleSubmitModifDemandeAcompte() {
    let ctx = this;

    fetch(`${ip}/miseAjourDemandeAcompte`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `montantAccompte=${ctx.state.montantAccompte}&etat=${ctx.state.etat}&dateTraitement=${ctx.state.dateTraitement}&remarque=${ctx.state.remarque}&idMessage=${ctx.props.messageAcompteIdfromStore}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            etat: data.message.etat,
            remarque: data.message.remarque,
            isClick: true 
           })
        })
        .catch((error)=> {
            console.log('Request failed in my handleSubmitModifDemandeAcompte request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationMajDemandeAcomptePerso" />
          }

          let button; 
  
          if (this.state.etat === "Acceptée"){
              button = <span style={{color: "green", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
                  {this.state.etat}
              </span>
          } 
          else if (this.state.etat === "Refusée"){
              button= <span style={{color: "red", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
                  {this.state.etat}
              </span>
          }
          else {
              button = <span style={{color: "orange", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
                  {this.state.etat}
              </span>
          }
    
  
  
  
          return (
          <Container className="justify-content-center text-center align-items-center" fluid={true} >
              <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                  <Col md={12}>
                      <Menu/>
                  </Col>
              </Row>
  
              <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    1. Profil
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Sites
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/PointagesPerso" 
                      className="link"
                      onClick={()=>{
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Pointages
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      onClick={()=>{
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    4. Demandes de congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      onClick={()=>{
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Demandes d'acomptes
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>
  
              <Row className="justify-content-center text-center align-items-center" 
               style={{ height:"120px"}}>
                  <Col md={12}>
                      <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                          Demande d'acompte</h1>
                  </Col>
              </Row>
  
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                      Date: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}> {this.state.date} </span>
                  </Col>
              </Row>
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                      Demandeur: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nom} {this.state.prenom} </span>(Matricule: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.matricule}</span>) 
                  </Col>
              </Row>
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                      Chargé de clientèle / Responsable: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nomCharge} {this.state.prenomCharge}</span>
                  </Col>
              </Row>
  
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                       Montant demandé: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}>{this.state.montantAccompte} €</span>
                  </Col>
              </Row>
  
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                       Etat: {button}
                  </Col>
              </Row>
  
              {this.state.dateTraitement? (
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                      Traitée le: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}> {this.state.dateTraitement} </span>
                  </Col>
              </Row>
              ):(
              null
              )
              }
  
              {this.state.remarque && this.state.remarque !==""? (
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                      Remarque: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}> {this.state.remarque} </span>
                  </Col>
              </Row>) :(
              null
              )}
  
                <Row className="justify-content-center text-center">
                    <Col className="justify-content-center" md={12} style={{marginBottom: "40px"}}>
                        <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                        <Link to="/DemandesAcomptesPerso" style={{color:"white"}}>Retour</Link>
                        </Button>
                        <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}  
                            onClick={this.handleShow}>
                             {!this.state.show ? 'Modifier' : 'Fermer'}
                        </Button>
                    </Col>
                </Row>
  
               {this.state.show && (<hr style={{width: "500px", marginBottom: "50px", marginTop: "50px"}}/>)}
  
  
              
               {this.state.show && (
              <Row className="justify-content-center text-left">
                  <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
  
                      <Form className="justify-content-center text-left">
  
                          <FormGroup row>
                              <Label for="montantAccompte" md={5}>Acompte demandé:</Label>
                              <Col md={7}>
                                  <Input type="text" name="montantAccompte" id="montantAccompte" 
                                  placeholder=""
                                  onChange={(e) => {this.setState({montantAccompte: e.target.value}) }}
                                  value={this.state.montantAccompte}/>
                              </Col>
                          </FormGroup>   
                          <FormGroup row style={{marginBottom: "30px"}}>
                              <Label for="etat" md={5}>
                                  Etat : 
                              </Label>
                              <Col md={7}>
                                  <Input type="select" value={this.state.etat} onChange={(e) => this.setState({etat: e.target.value})}>
                                      <option value="" ></option>
                                      <option value="Acceptée">Acceptée</option>
                                      <option value="Refusée">Refusée</option>
                                  </Input>
                              </Col>
                          </FormGroup>
  
                          <FormGroup row style={{marginBottom: "30px"}}>
                              <Label for="dateTraitement" md={5}>Traitée le:</Label>
                              <Col md={7}>
                                <Input type="date" name="dateTraitement" id="dateTraitement" 
                                    placeholder=""
                                    onChange={(e) => {this.setState({
                                        dateTraitement: moment(e.target.value,"YYYY-MM-DD").format("DD/MM/YYYY") });
                                    }} 
                                />
                              </Col>
                          </FormGroup>
  
                          <FormGroup row>
                              <Label for="remarque" md={5}>Remarques:</Label>
                              <Col md={7}>
                              <Input type="textarea" name="remarque" id="remarque" 
                              placeholder=""
                              onChange={(e) => {this.setState({remarque: e.target.value}); }} 
                              value={this.state.remarque}/>
                              </Col>
                          </FormGroup>      
  
                          <Row className="justify-content-center text-center align-items-center">
                              <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                  <Link to="/DemandesAcomptesPerso" style={{color:"white"}}>Annuler</Link>
                              </Button>
                              <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                              onClick={()=>{this.handleSubmitModifDemandeAcompte()}}>Valider</Button>
                          </Row>
                                  
                          
                      </Form>
                  </Col>
              </Row>)}
  

            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    console.log("ModifDemandeAcompte", state)
    console.log('je recois de mon reducer ID du message suivant de ModifDemandeAcompte : ',state.messageAcompteId)
  
  
    return { messageAcompteIdfromStore: state.messageAcompteId }
}
  
  
    export default connect(
      mapStatetoProps,
      null
      )(ModifDemandeAcomptePerso);