import React, { Fragment } from "react";
import ip from './ipconfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container,  Input, Button} from 'reactstrap';
import Menu from './Menu';
import Footer from './Footer';

import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";



const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};

class Salaries extends React.Component {
  
  constructor(props) {
    super(props);
    this.handleSubmitPointage=this.handleSubmitPointage.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);

    this.state = {
      allUsers:[],
      matricule: "",
      nom: "",
      visibleFilters: []
    };}

  componentDidMount(){
    let ctx=this;

    fetch(`${ip}/salaries`)
    .then(function(res, err){
        return res.json()
      }).then((data)=> {
        ctx.setState({
          allUsers: data.user,
          matricule: data.user.matricule,
          nom: ctx.state.nom,
        })
        console.log('allUsers du fetch', ctx.state.allUsers)
        console.log('matricule', ctx.state.matricule)

      })
      .catch((error)=> {
          console.log('Request failed', error)
      });
        }

  toggleFilter = id => {
    this.setState(({ visibleFilters }) => {
      let update = [...visibleFilters];
      const index = update.indexOf(id);
      index < 0 ? update.push(id) : update.splice(index, 1);
      return { visibleFilters: update };
    });
  };


  handleSubmitPointage(data){
    let ctx=this;

    fetch(`${ip}/pointage?matricule=${data.matricule}&nom=${data.nom}`)
    .then(function(res, err){
      return res.json()
    }).then((data)=>{
      if (data.result === true){
        ctx.props.saveId(data.user._id)
        if (data.result === true){      
          this.setState({
            isUserExist:true
         })
        } else {
          
          this.setState({
            isUserExist:false
         })}
        }
      }) 
      .catch((error)=> {
        console.log('Request failed in my tableausalarie Home request', error)
    });
      }

      getTrPropsType(state, rowInfo, column) {
        const { original } = rowInfo;
        const { color } = original;    
        return {
         
            style: {
                borderColor: color
            },
        }
      }
      

      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
    if (typeof content !== 'undefined') {

        if (filter.value.length < 4) {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
            }
        } else {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

    }}




  render() {
    var usersList = [];
    var usersListCopy = [ ...this.state.allUsers]
    usersList = usersListCopy.map((data,i) =>{
    return  (  
    {
      matricule: data.matricule,
      nom: data.nom,
      prenom: data.prenom,
      charge:{nomCharge: data.nomCharge, prenomCharge: data.prenomCharge},
      voir: <Button style={{backgroundColor:"white", border:"none"}} 
          onClick={()=>{
            this.handleSubmitPointage(data) 
          }}>
      <img src="./images/oeil.png" height="30px" /></Button>
    }
    )})

    console.log('userList', usersList)

    if (this.state.isUserExist){
      return < Redirect to="/PointagesPerso" />
    }



    return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"50px", marginBottom:"50px", height:"80px", fontFamily:"Quicksand, 'sans-serif'"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "30px"}}>
                        Collaborateurs</h1>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
              <ReactTable
                previousText="Précédent"
                nextText="Suivant"
                rowsText="lignes"
                ofText= 'à'
                defaultFilterMethod={this.filterCaseInsensitive}
                data={usersList}
                style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
                resolveData={data => data.map(row => row)} 
                columns={[
                  {
                    // Header: props => <div style={{fontWeight:'bolder', margin:'auto', }}>COLLABORATEURS</div>,
                    columns: [
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Matricule",
                        accessor: "matricule",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto',},
                        minWidth: 130,
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                            
                          />
                        ),
                        label: "Nom",
                        accessor: "nom",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto'},
                        minWidth: 200
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Prénom",
                        accessor: "prenom",
                        filterable: true,
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 200,
                        getProps: (state, rowInfo, column) => {
                          return {
                              style: {
                                
                              },
                          };
                      },

                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Chargé de clientèle / Responsable</div>,
                        accessor: 'charge',
                        Cell     : row => {
                          return (
                            <div>
                              {row.row.charge.nomCharge} {row.row.charge.prenomCharge}
                            </div>
                          )
                        },
                        style:{fontWeight: 'normal', margin: 'auto',},
                        minWidth: 210,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Voir</div>,
                        accessor: "voir"
                      }
                    ],
                    
                  },
                  // {
                  //   Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>CHARGE DE CLIENTELE / RESPONSABLE</div>,
                  //   columns: [
                  //     {
                  //       Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Nom</div>,
                  //       accessor: "nomCharge",
                  //       style:{fontWeight: 'normal', margin: 'auto'},
                  //       minWidth: 150,
                  //     },
                  //     {
                  //       Header: props => <div style={{fontWeight:'bolder', }}>Prénom</div>,
                  //       accessor: "prenomCharge",
                  //       style:{fontWeight: 'normal', margin: 'auto'},
                  //       minWidth: 150,
                        
                  //     }
                  //   ]
                  // },
                  
                ]
                
                }
                getTheadFilterProps={(state, rowInfo, column, instance) => {
                  return {
                    style:
                      this.state.visibleFilters.length === 0
                        ? { display: "none" }
                        : null
                  };
                }}
                getTheadFilterThProps={(state, rowInfo, column, instance) => {
                  return {
                    className:
                      this.state.visibleFilters.indexOf(column.id) < 0
                        ? "hiddenFilter"
                        : null
                  };
                }}
                defaultPageSize={10}
                getTrPropsType= {this.getTrPropsType}
                className="-striped -highlight justify-content-center text-center align-items-center "
              />
        </Row>

        <Row className="scroll_to_top">
            <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
        </Row>

        <Row className="justify-content-center text-center align-items-center" >
            <Footer/>
        </Row>
            
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveId: function(id) {
        dispatch( {type: 'connect',
         id,
        } );
    }
  }
  }
  
  export default connect(
    null, 
    mapDispatchToProps
  )(Salaries);