import React, { Fragment } from "react";
import ip from './ipconfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container,  Input, Button} from 'reactstrap';
import Menu from './Menu';
import Footer from './Footer';

import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";



const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};

class DemandesConges extends React.Component {
  
  constructor(props) {
    super(props);
    this.handleSubmitDemandeModifierCp=this.handleSubmitDemandeModifierCp.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);
    this.handleSubmitConfirmSup = this.handleSubmitConfirmSup.bind(this)

    this.state = {
      allDemandes:[],
      visibleFilters: [],
      isClick: false,
      isClick1: false,
    };}

  componentDidMount(){
    let ctx=this;

      fetch(`${ip}/demandesCp`)
        .then(function(res, err){
            return res.json()
          }).then((data)=> {
            ctx.setState({
              allDemandes: data.message,
            })
          })
      .catch((error)=> {
          console.log('Request failed', error)
      });
        }


  toggleFilter = id => {
    this.setState(({ visibleFilters }) => {
      let update = [...visibleFilters];
      const index = update.indexOf(id);
      index < 0 ? update.push(id) : update.splice(index, 1);
      return { visibleFilters: update };
    });
  };


  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
if (typeof content !== 'undefined') {

    if (filter.value.length < 4) {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
        } else {
            return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
        }
    } else {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        } else {
            return String(content).toLowerCase().includes(filter.value.toLowerCase());
        }
    }

}}


  handleSubmitDemandeModifierCp(data){
    let ctx=this;

    fetch(`${ip}/demandesModifCp?idConge=${data._id}`)
    .then(function(res, err){
      return res.json()
    }).then((data)=>{
        ctx.props.saveId(data.message._id)
        ctx.setState ({
            isClick:true,
          })
        }
      ) 
      .catch((error)=> {
        console.log('Request failed in my tableaudemandeCp request', error)
    });
  }

      getTrPropsType(state, rowInfo, column) {
        const { original } = rowInfo;
        const { color } = original;    
        return {
         
            style: {
                borderColor: color
            },
        }
      }
      

      handleSubmitConfirmSup(data){
        let ctx=this;
    
        fetch(`${ip}/demandesModifCp?idConge=${data._id}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=>{
            ctx.props.saveId(data.message._id)
            ctx.setState ({
                isClick1:true,
              })
            }
          ) 
          .catch((error)=> {
                console.log('Request failed in my tableaudemande request', error)
        });
      }




  render() {
    var demandesList = [];
    var demandesListCopy=[...this.state.allDemandes]
    
    demandesList = demandesListCopy.map((data,i) =>{
    return  (  
    {
      matricule: data.matricule,
      nom: data.nom,
      idConge: data._id,
      prenom: data.prenom,
      charge:{nomCharge: data.nomCharge, prenomCharge: data.prenomCharge},
      date: data.date,
      periode:{dateDebutCp: data.dateDebutCp, dateFinCp: data.dateFinCp,},
      nbreJoursCp: data.nbreJoursCp,
      etat: data.etat,
      voir: <Button style={{backgroundColor:"white", border:"none"}} 
          onClick={()=>{
            this.handleSubmitDemandeModifierCp(data) 
          }}>
      <img src="./images/oeil.png" height="30px" /></Button>,
      supprimer: <Button style={{backgroundColor:"white", border:"none"}} 
        onClick={()=>{
          this.handleSubmitConfirmSup(data) 
        }}>
      <img src="./images/annulation.png" height="25px" /></Button>
    }
    )})

    console.log("demande", demandesList)


    if (this.state.isClick){
      return < Redirect to="/ModifDemandeCp" />
    }

    if (this.state.isClick1){
      return < Redirect to="/DemandeCpConfirmSup" />
    }



    return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"50px", marginBottom:"50px", height:"80px", fontFamily:"Quicksand, 'sans-serif'"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "30px"}}>
                        Demandes de congés</h1>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
              <ReactTable
                previousText="Précédent"
                nextText="Suivant"
                rowsText="lignes"
                ofText= 'à'
                defaultFilterMethod={this.filterCaseInsensitive}
                data={demandesList}
                style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
                resolveData={data => data.map(row => row)} 
                columns={[
                  {
                    columns: [
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),        
                        label: 'Date',               
                        accessor: "date",
                        filterable: true,
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 100,
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Matricule",
                        accessor: "matricule",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto',},
                        minWidth: 110,
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                            
                          />
                        ),
                        label: "Nom",
                        accessor: "nom",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto'},
                        minWidth: 200
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Prénom",
                        accessor: "prenom",
                        filterable: true,
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 200,
                        getProps: (state, rowInfo, column) => {
                          return {
                              style: {
                                
                              },
                          };
                      },

                      },
                      // {
                      //   Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Chargé de clientèle / Responsable</div>,
                      //   label: "Chargé de clientèle / Responsable",
                      //   accessor: 'charge',
                      //   Cell     : row => {
                      //     return (
                      //       <div>
                      //         {row.row.charge.nomCharge} {row.row.charge.prenomCharge}
                      //       </div>
                      //     )
                      //   },
                      //   style:{fontWeight: 'normal', margin: 'auto',},
                      //   minWidth: 150,
                      // },
                      
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Période</div>,
                        label: "Période",
                        accessor: 'periode',
                        Cell     : row => {
                          if (row.row.periode.dateFinCp==='null'){
                          return (
                            <div>
                              {row.row.periode.dateDebutCp}
                            </div>
                          )} else {
                            return (
                              <div>
                              Du {row.row.periode.dateDebutCp} au {row.row.periode.dateFinCp}
                            </div>
                            )
                          }
                        },
                        style:{fontWeight: 'normal', margin: 'auto',},
                        minWidth: 190,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', }}>Etat</div>,
                        accessor: "etat",
                        // Cell     : row => {
                        //   if (row.etat==='Acceptée'){
                        //   return (
                        //     <div style={{color:'green', fontSize: '20px'}}>
                        //       {row.row.etat}
                        //     </div>
                        //   )} else if (row.etat==='Refusée'){
                        //     return (
                        //       <div style={{color:'red'}}>
                        //         {row.row.etat}
                        //       </div>
                        //     )
                        //   }
                        //   else {
                        //     return (
                        //       <div>
                        //       {row.row.etat}
                        //     </div>
                        //     )
                        //   }
                        // },
                        getProps: (state, rowInfo, column) => {
                          return {
                              style: {
                                  color: rowInfo && rowInfo.row.etat ==="En cours de traitement" ? 'red' : null,
                              },
                          };
                      },

                        style:{fontWeight: 'bolder', margin: 'auto'},
                        minWidth: 150,
                        
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Voir</div>,
                        accessor: "voir",
                        minWidth: 70,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Supprimer</div>,
                        accessor: "supprimer",
                        minWidth: 70,
                      }


                    ],
                    
                  },
                  
                 
                ]
                
                }
                getTheadFilterProps={(state, rowInfo, column, instance) => {
                  return {
                    style:
                      this.state.visibleFilters.length === 0
                        ? { display: "none" }
                        : null
                  };
                }}
                getTheadFilterThProps={(state, rowInfo, column, instance) => {
                  return {
                    className:
                      this.state.visibleFilters.indexOf(column.id) < 0
                        ? "hiddenFilter"
                        : null
                  };
                }}
                defaultPageSize={10}
                getTrPropsType= {this.getTrPropsType}
                className="-striped -highlight justify-content-center text-center align-items-center "
              />
        </Row>

        <Row className="scroll_to_top">
            <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
        </Row>

        <Row className="justify-content-center text-center align-items-center" >
            <Footer/>
        </Row>
            
      </Container>
    );
  }
}

function mapStatetoProps(state) {

  return { messageCpIdfromStore: state.messageCpId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMessageCp) {
        dispatch( {type: 'connect3',
         idMessageCp,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(DemandesConges);