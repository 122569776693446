import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class ModifDemandePrdtsPerso extends Component {

    constructor(props){
        super(props);
        this.handleSubmitModifDemande = this.handleSubmitModifDemande.bind(this);
        this.state = ({     
            nomSite: "",
            nom: "",
            prenom: "",
            matricule: "",
            nomCharge: "",
            prenomCharge: "",
            date: "",
            listProduits: "",
            remarque: "",
            etat: "",
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/modifierDemande?idMessage=${this.props.messageIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              console.log('RESULTAT DE Recuperation EN BD Request sur modifierDemande--->', data)
              this.setState({
                matricule: data.message.matricule,
                nom: data.message.nom, 
                prenom: data.message.prenom,
                nomSite: data.message.nomSite,
                date: data.message.date,
                listProduits: data.message.listProduits,
                etat: data.message.etat,
                remarque: data.message.remarque,
                nomCharge: data.message.nomCharge,
                prenomCharge: data.message.prenomCharge
              })
            })
            .catch((error)=> {
                console.log('Request failed in my ModifDemande', error)
            });
          }
        

handleSubmitModifDemande() {
    let ctx = this;

    fetch(`${ip}/miseAjourDemande`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `etat=${ctx.state.etat}&dateTraitement=${ctx.state.dateTraitement}&remarque=${ctx.state.remarque}&idMessage=${ctx.props.messageIdfromStore}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           console.log('RESULTAT DE LERENGISTREMENT EN BD USER --->', data)
           ctx.setState({
            etat: data.message.etat,
            dateTraitement: data.message.dateTraitement,
            remarque: data.message.remarque,
            isClick: true 
           })
        })
        .catch((error)=> {
            console.log('Request failed in my handleSubmitModifDemande request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationMajDemandePrdtsPerso" />
          }

        var listPrdts = this.state.listProduits

        var listPrdtsSplit = listPrdts.split(',');
    
        var listPrdtsJoin = listPrdtsSplit.join(' - ')
      
        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Mettre à jour la demande de produits / matériels</h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                    Date: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.date} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                    Demandeur: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nom} {this.state.prenom} </span>(Matricule: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.matricule}</span>) 
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                    Site concerné: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nomSite}</span>
                </Col>
            </Row>

            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>
                     Produits / Matériel manquants: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{listPrdtsJoin}</span>
                </Col>
            </Row>

            <hr style={{width: "500px", marginBottom: "50px", marginTop: "50px"}}/>
            
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px"}}>

                    <Form className="justify-content-center text-left">

                        <FormGroup row style={{marginBottom: "30px"}}>
                            <Label for="etat" md={3}>
                                Etat : 
                            </Label>
                            <Col md={9}>
                                <Input type="select" value={this.state.etat} onChange={(e) => this.setState({etat: e.target.value})}>
                                    <option value="" ></option>
                                    <option value="Commande acceptée" >Commande acceptée</option>
                                    <option value="Commande refusée">Commande refusée</option>
                                </Input>
                            </Col>
                        </FormGroup>

                        <FormGroup row style={{marginBottom: "30px"}}>
                            <Label for="dateTraitement" md={3}>Traitée le:</Label>
                            <Col md={9}>
                            <Input type="date" name="dateTraitement" id="dateTraitement" 
                            placeholder=""
                            onChange={(e) => {this.setState({dateTraitement: e.target.value})}} value={this.state.dateTraitement}/>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="remarque" md={3}>Remarques:</Label>
                            <Col md={9}>
                            <Input type="textarea" name="remarque" id="remarque" 
                            placeholder=""
                            onChange={(e) => {this.setState({remarque: e.target.value}); console.log("remarque", e.target.value)}} value={this.state.remarque}/>
                            </Col>
                        </FormGroup>      

                        <Row className="justify-content-center text-center align-items-center">
                            <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                <Link to="/FeuilleSitesPrdts" style={{color:"white"}}>Annuler</Link></Button>
                            <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                            onClick={()=>{this.handleSubmitModifDemande()}}>Valider</Button>
                        </Row>
                                
                        
                    </Form>
                </Col>
            </Row>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    console.log("ModifDemande", state)
    console.log('je recois de mon reducer ID du message suivant de ModifDemande : ',state.messageId)
  
  
    return { messageIdfromStore: state.messageId }
  }
  
  
    export default connect(
      mapStatetoProps,
      null
      )(ModifDemandePrdtsPerso);