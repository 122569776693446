export default function(idMessageAcompte = '', action) {

  if(action.type == 'connect4') {
       console.log('Dans mon reducer --->',action.idMessageAcompte)
       return action.idMessageAcompte;
  } else {
      return idMessageAcompte;
  }
}

