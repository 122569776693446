import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';
import Select from 'react-select'



class Enregistrement extends Component {

    constructor(props){
        super()
        this.state = ({
            prenom: "",
            nom: "",
            matricule: "",
            numeroTelSalarie: "",
            password: "",
            nomCharge: "",
            prenomCharge:"",
            emailCharge: "",
            numeroTelCharge: "",
            statut: "",
            isUserExist:false,
            listeChargesDeroulante: [],
        })
    }

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/addSalarie`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            listeChargesDeroulante: data.listeCharges,
           })
           console.log('liste', this.state.listeChargesDeroulante)
        })
        .catch((error)=> {
            console.log('Request failed in my AddPlanning', error)
        });
    }


    handleSubmitSignUp(e){
        let ctx = this;
        fetch(`${ip}/enregistrement`, {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body: `prenom=${ctx.state.prenom}&nom=${ctx.state.nom}&matricule=${ctx.state.matricule}&statut=${ctx.state.statut}&password=${ctx.state.password}&nomCharge=${ctx.state.nomCharge}&prenomCharge=${ctx.state.prenomCharge}&emailCharge=${ctx.state.emailCharge}&numeroTelSalarie=${ctx.state.numeroTelSalarie}&numeroTelCharge=${ctx.state.numeroTelCharge}`
        }
        ).then(function(res, err){
          return res.json()
        }).then((data)=>{
            this.setState({
                isUserExist:true
            });
            })
        .catch((error)=> {
            console.log('Request failed in Enregistrement', error)
        });
     }
    


    render(){
        let button

        if (this.state.isUserExist){
            return <Redirect to="/Validation"></Redirect>
          } 



        if (this.state.nom ==="" || this.state.prenom===''|| this.state.matricule === '' || this.state.statut==="" || this.state.numeroTelSalarie ==='' || this.state.nomCharge ==='' || this.state.password === '')
          {
              button = 
              <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                onClick={()=>{}}
                disabled
              >
                Valider
              </Button>
          } else {
              button =
              <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                onClick={()=>{this.handleSubmitSignUp()}}>
                Valider
              </Button>
          }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px", backgroundColor:""}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "30px"}}>
                        Enregistrer un nouveau salarié  </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
              style={{paddingBottom:"60px"}}>             
                <Col className="justify-content-center text-center align-items-center" md={7}>
                    <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>

                        <FormGroup>
                            <Label for="matricule" >Matricule:</Label>
                            <Input type="text" name="matricule" id="matricule" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({matricule: e.target.value})} value={this.state.matricule}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="nom" >Nom:</Label>
                            <Input type="text" name="nom" id="nom" rows={1}
                            placeholder=""
                            required
                            onChange={(e) => this.setState({nom: e.target.value})} value={this.state.nom}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="prenom" >Prénom:</Label>
                            <Input type="text" name="prenom" id="prenom" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({prenom: e.target.value})} value={this.state.prenom}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="numeroTelSalarie" >Numéro de téléphone:</Label>
                            <Input type="text" name="numeroTelSalarie" id="numeroTelSalarie" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({numeroTelSalarie: e.target.value})} value={this.state.numeroTelSalarie}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="statut" >
                                Statut : 
                            </Label>
                            <Input type="select" value={this.state.statut} onChange={(e) => this.setState({statut: e.target.value})}>
                                <option value="" ></option>
                                <option value="Agent de service" >Agent de service</option>
                                <option value="Chef d'équipe">Chef d'équipe</option>
                                <option value="Chargé de clientèle">Chargé de clientèle / Responsable</option>
                                <option value="Contrôleur">Contrôleur</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="password" >Mot de passe:</Label>
                            <Input type="text" name="password" id="password" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({password: e.target.value})} value={this.state.password}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="nomCharge" >Chargé de clientèle / Responsable:</Label>
                            <Select 
                                options={this.state.listeChargesDeroulante.map(t=>({value: t, label: t}))}
                                onChange={(value) => {
                                    console.log(value.value);
                                    this.setState({ nomCharge: value.value, prenomCharge: value.value })}}
                                placeholder="Sélectionnez un chargé de clientèle / responsable"
                            />
                        </FormGroup>

                        <Row className="justify-content-center text-center align-items-center">
                            <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                <Link to="/Salaries" style={{color:"white"}}>Annuler</Link></Button>
                            {button}
                        </Row>
                    </Form>
                </Col>
            </Row>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStateToProps(state) {
    return { userIdfromStore: state.userId }
  }  


  export default connect(
      mapStateToProps,
      null
  )(Enregistrement);