import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class Pointage extends Component {

    constructor(props){
        super(props)
        this.handleSubmitMajProfil = this.handleSubmitMajProfil.bind(this);
        this.state = ({     
            matricule:"",
            prenom:"",
            nom:"",
            numeroTelSalarie:"",
            prenomCharge:"",
            nomCharge:"",
            numeroTelCharge:"",
            emailCharge:"",
            statut:"",
            isUserExist:false,
        })}
 

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/profil?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
          //  console.log('RESULTAT DE pointagesalaries--->', data)
          ctx.setState({
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            numeroTelSalarie: data.user.numeroTelSalarie,
            prenomCharge: data.user.prenomCharge,
            nomCharge: data.user.nomCharge,
            numeroTelCharge: data.user.numeroTelCharge,
            emailCharge: data.user.emailCharge,
            statut: data.user.statut
            })
        })
        .catch((error)=> {
            console.log('Request failed in my Pointage request', error)
        });
    }
    

    handleSubmitMajProfil(){
      let ctx=this;
  
      fetch(`${ip}/profil?id=${ctx.props.userIdfromStore}&matricule=${ctx.state.matricule}`)
      .then(function(res, err){
        return res.json()
      }).then((data)=>{
        if (data.result === true){
            this.setState({
              isUserExist:true
           })
          } else {
            
            this.setState({
              isUserExist:false
           })}
          
        }) 
        .catch((error)=> {
          console.log('Request failed in my profil request', error)
      });
        }




    render(){

      if (this.state.isUserExist){
        return < Redirect to="/MiseAjourProfil" />
      }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link" style={{fontSize: "14px"}}>
                  <Link to="/PointagesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    1. Pointages
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Plannings
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      onClick={()=>{
                      console.log('j ai cliqué sur le Site'); 
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Demandes de congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      onClick={()=>{
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    4. Demandes d'acomptes
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                    5. Profil
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>


            <Row className="justify-content-center text-left align-items-center" 
             style={{}}>
                <Col md={7}  style={{}}>
                  <h1 style={{fontWeight:"bold", fontStyle:'italic', fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px",}}>
                  <img src="./images/homme.png" height="25px" style={{marginRight: "10px"}}/>Collaborateur: 
                    <span style={{fontStyle:'italic', marginLeft: "5px",  fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px"}}>
                        {this.state.nom} {this.state.prenom} <span style={{fontWeight: "normal"}}>(Matricule: {this.state.matricule})</span>
                    </span>
                  </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Profil
                    </h1>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}> 
              <Col md={3} style={{textAlign:"left"}}>
                <img src="./images/homme.png" height="180px" alt="ASP" style={{borderRadius:"8px", borderColor: "black"}}></img>
              </Col>

              <Col md={6} style={{textAlign:"right"}}>
                <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}>
                  <Col md={12} style={{textAlign:"left"}}>
                    <h3 style={{display:"inline", fontSize:"14px", fontWeight:"normal"}}>MATRICULE: </h3>
                    <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.matricule}</span>
                  </Col>
                </Row> 
                <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}>
                  <Col md={12} style={{textAlign:"left"}}>
                    <h3 style={{display:"inline", fontSize:"14px", fontWeight:"normal"}}>NOM:</h3>
                    <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.nom}</span>
                  </Col>
                </Row> 
                <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}>
                  <Col md={12} style={{textAlign:"left"}}>
                    <h3 style={{display:"inline", fontSize:"14px", fontWeight:"normal"}}>PRENOM: </h3>
                    <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.prenom}</span>
                  </Col>
                </Row> 
                <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}>
                  <Col md={12} style={{textAlign:"left"}}>
                    <h3 style={{display:"inline", fontSize:"14px", fontWeight:"normal"}}>NUMERO DE TELEPHONE:</h3>
                    <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.numeroTelSalarie}</span>
                  </Col>
                </Row> 
                <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}>
                  <Col md={12} style={{textAlign:"left"}}>
                    <h3 style={{display:"inline", fontSize:"14px", fontWeight:"normal"}}>STATUT:</h3>
                    <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.statut}</span>
                  </Col>
                </Row> 

              </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginTop:"40px"}} >
                <Col className="justify-content-center align-items-center" md={12} style={{fontWeight:"bolder", textAlign:"center", fontSize:"20px", fontFamily:"Quicksand, 'sans-serif'", marginBottom:"40px"}} className="titre">
                  Chargé de clientèle / Responsable
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center" style={{marginBottom:"20px"}}>
                <Col md={{size: 5, offset: 1}} style={{textAlign:"left"}}>
                  <h3 style={{paddingLeft: "50px", display:"inline", fontSize:"14px", fontWeight:"normal"}}>NOM:</h3>
                  <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.nomCharge}</span>
                </Col>
                <Col md={{size: 5}} style={{textAlign:"left"}}>
                  <h3 style={{paddingLeft: "50px", display:"inline", fontSize:"14px", fontWeight:"normal"}}>PRENOM:</h3>
                  <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.prenomCharge}</span>
                </Col>
            </Row> 
            <Row className="justify-content-center align-items-center" style={{marginBottom:"60px"}}>
                <Col md={{size: 5, offset: 1}} style={{textAlign:"left"}}>
                  <h3 style={{paddingLeft: "50px", display:"inline", fontSize:"14px", fontWeight:"normal"}}>E-MAIL:</h3>
                  <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.emailCharge}</span>
                </Col>
                <Col md={{size: 5}} style={{textAlign:"left"}}>
                  <h3 style={{paddingLeft: "50px", display:"inline", fontSize:"14px", fontWeight:"normal"}}>NUMERO DE TELEPHONE:</h3>
                  <span style={{paddingLeft: "5px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.numeroTelCharge}</span>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    >
                      <Link to="/Salaries" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>
                  <Button 
                    style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    onClick={()=>{this.handleSubmitMajProfil() }}
                    >
                        Mettre à jour
                  </Button>
                </Col>
            </Row>
 

            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
  return { userIdfromStore: state.userId }
}

function mapDispatchToProps(dispatch) {
  return {
    saveId: function(id) {
        dispatch( {type: 'connect',
         id,
        } );
    }
  }
}


export default connect(
  mapStatetoProps,
  mapDispatchToProps
  )(Pointage);