import React, { Component }  from 'react';
import './styles.css';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText, Badge} from 'reactstrap';

import ip from './ipconfig';


class Menu extends Component {

    constructor(props){
        super(props);
        this.state = ({     
            nbreAnomalie: "",
            nbreDemandeAcompte: "",
            nbreDemandeCp: "",
            nbreDemandePrdt: "",
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/menu`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nbreDemandeAcompte: data.nbreDemandeAcompte,
                nbreDemandeCp: data.nbreDemandeCp, 
                nbreDemandePrdt: data.nbreDemandePrdt,
                nbreAnomalie: data.nbreAnomalie,
              })
            })
            .catch((error)=> {
                console.log('Request failed in my MaterielDetails', error)
            });
          }




    render() {
        return (

        <Navbar expand="md">
            <NavbarBrand href="/Salaries"><img src="/images/logo.png" height="30px" width="120px" alt="Avantage Services et Propreté"></img></NavbarBrand>
            <NavbarText></NavbarText>
            
            <Nav className="link" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret style={{marginLeft: "20px", color:"black", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                        Collaborateurs 
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="/Salaries" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                            fontSize: "15px"}}>Liste des collaborateurs</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/Enregistrement" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>
                                    Enregistrer un collaborateur
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret style={{color:"black", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'", marginRight: "20px", marginLeft: "20px"}}>
                        Sites
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="/ListeSites" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                            fontSize: "15px"}}>Liste des sites</Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="/EnregistrementSite" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>
                                    Enregistrer un site
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem style={{paddingRight:"20px"}}>
                    <NavLink>
                        <Link to="/Demandes" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>Produits 
                        </Link>
                        {this.state.nbreDemandePrdt!=="0"?(
                        <Badge color="danger" style={{fontSize: "9px", verticalAlign: 'super', marginLeft: "3px", borderRadius: "8px"}}>
                            {this.state.nbreDemandePrdt}
                        </Badge>): (null)}
                    </NavLink>
                </NavItem>

                <NavItem style={{paddingRight:"20px"}}>
                    <NavLink>
                        <Link to="/DemandesConges" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>Congés
                        </Link>
                        {this.state.nbreDemandeCp!="0"?(
                        <Badge color="danger" style={{ fontSize: "9px", verticalAlign: 'super', marginLeft: "3px", borderRadius: "8px"}}>
                            {this.state.nbreDemandeCp}
                        </Badge>) : (null)}
                    </NavLink>
                </NavItem>

                <NavItem style={{paddingRight:"20px"}}>
                    <NavLink>
                        <Link to="/DemandesAcomptes" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>Acomptes
                        </Link>
                        {this.state.nbreDemandeAcompte!="0"?(
                        <Badge color="danger" style={{fontSize: "9px",verticalAlign: 'super', marginLeft: "3px", borderRadius: "8px"}}>
                            {this.state.nbreDemandeAcompte}
                        </Badge>) : (null)}
                    </NavLink>
                </NavItem>
                <NavItem style={{paddingRight:"20px"}}>
                    <NavLink>
                        <Link to="/ListeSitesAnomalies" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>Anomalies
                        </Link>
                        {this.state.nbreAnomalie!="0"?(
                        <Badge color="danger" style={{fontSize: "9px", verticalAlign: 'super', marginLeft: "3px", borderRadius: "8px"}}>
                            {this.state.nbreAnomalie}
                        </Badge>) : (null)}
                    </NavLink>
                </NavItem>
                <NavItem style={{paddingRight:"20px"}}>
                    <NavLink>
                        <Link to="/ListeMateriels" className="link" style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                                fontSize: "15px"}}>Matériels
                        </Link>
                    </NavLink>
                </NavItem>               
            </Nav>

           
      </Navbar>

           
        )
    }
}

export default Menu;