export default function(idMessageCp = '', action) {

  if(action.type == 'connect3') {
       console.log('Dans mon reducer --->',action.idMessageCp)
       return action.idMessageCp;
  } else {
      return idMessageCp;
  }
}

