import React, {Component} from 'react';
import { Button,  } from 'reactstrap';
import {Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux'; 
import './App.css';
import ip from './ipconfig';
import './styles.css';

 
class TableauListeReparations extends Component {
  constructor(props) {
    super(props);
    this.handleSubmitMaterielDetails = this.handleSubmitMaterielDetails.bind(this)

    this.state = {
      isClick: false,
      nomSite: this.props.nomSite,
      idMateriel: this.props.idMateriel,
      date: this.props.date

      };
    }


    handleSubmitMaterielDetails(){
      let ctx=this;
  
      fetch(`${ip}/materielVoir?idMateriel=${ctx.state.idMateriel}`)
      .then(function(res, err){
        return res.json()
      }).then((data)=>{
          ctx.props.saveId(data.materielDetail._id)
          console.log('idMateriel', data.materielDetail._id)
          ctx.setState ({
              isClick:true,
            })
          }
        ) 
        .catch((error)=> {
          console.log('Request failed in my handleSubmitMaterielDetails request', error)
      });
    }

    

  render() {

    if (this.state.isClick){
      return < Redirect to="/MaterielDetails" />
    }

      return (
       
          <tr className="text-center">
            <th scope="row" style={{width:"200px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}>{this.props.dateIntervention}</th>
            <td style={{width:"200px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.typeIntervention}</td>
            <td style={{width:"200px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.remarque}</td>
            {/* <td style={{width:"100px"}}><Button style={{backgroundColor:"white", border:"none"}} onClick={this.handleSubmitMaterielDetails} >
              <img src="./images/oeil.png" height="30px" /></Button>
            </td> */}
          </tr>                    

      );
  }
}

function mapStatetoProps(state) {
  console.log("TableauListeSitesMateriels", state)
  console.log('je recois de mon reducer ID du message suivant de TableauListeSitesMateriels : ',state.materielId)


  return { materielIdfromStore: state.materielId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMateriel) {
        dispatch( {type: 'connect7',
         idMateriel,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(TableauListeReparations);