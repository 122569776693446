import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class MiseAjourProfil extends Component {

    constructor(props){
        super(props);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.state = ({     
            matricule: '',
            prenom:"",
            nom:"",
            numeroTelSalarie:"",
            prenomCharge:"",
            nomCharge:"",
            numeroTelCharge:"",
            emailCharge:"",
            password: "",
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/profil?id=${ctx.props.userIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              //  console.log('RESULTAT DE pointagesalaries--->', data)
              ctx.setState({
                matricule: data.user.matricule,
                prenom: data.user.prenom,
                nom: data.user.nom,
                numeroTelSalarie: data.user.numeroTelSalarie,
                prenomCharge: data.user.prenomCharge,
                nomCharge: data.user.nomCharge,
                numeroTelCharge: data.user.numeroTelCharge,
                emailCharge: data.user.emailCharge,
                password: data.user.password
                })
            })
            .catch((error)=> {
                console.log('Request failed in my Pointage request', error)
            });
        }
            

handleSubmitUpdate() {
    let ctx = this;

    fetch(`${ip}/miseAjourProfil`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `id=${ctx.props.userIdfromStore}&statut=${ctx.state.statut}&nom=${ctx.state.nom}&prenom=${ctx.state.prenom}&password=${ctx.state.password}&prenomCharge=${ctx.state.prenomCharge}&nomCharge=${ctx.state.nomCharge}&matricule=${ctx.state.matricule}&emailCharge=${ctx.state.emailCharge}&numeroTelSalarie=${ctx.state.numeroTelSalarie}&numeroTelCharge=${ctx.state.numeroTelCharge}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           console.log('RESULTAT DE LERENGISTREMENT EN BD USER --->', data)
           ctx.setState({
            nom: data.user.nom,
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            numeroTelSalarie: data.user.numeroTelSalarie,
            prenomCharge: data.user.prenomCharge,
            nomCharge: data.user.nomCharge,
            numeroTelCharge: data.user.numeroTelCharge,
            emailCharge: data.user.emailCharge,
            password: data.user.password,
            statut: data.user.statut,
            isClick: true                   
           })
        })
        .catch((error)=> {
            console.log('Request failed in my /miseAjourSite request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationMajProfil" />
          }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Mettre à jour le profil</h1>
                </Col>
            </Row>

            <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>
                <Row className="justify-content-center align-items-center" 
                style={{paddingBottom:"60px"}} form>             
                    <Col className="justify-content-center align-items-center" md={8}>
                            <FormGroup>
                                <Label for="matricule" >Matricule:</Label>
                                <Input type="text" name="nom" id="matricule" rows={1}
                                placeholder={this.state.matricule}
                                onChange={(e) => {this.setState({matricule: e.target.value}); console.log("matricule", e.target.value)}} value={this.state.matricule}/>
                            </FormGroup>

                            
                            <FormGroup>
                                <Label for="nom" >Nom:</Label>
                                <Input type="text" name="nom" id="nom" rows={1}
                                placeholder={this.state.nom}
                                onChange={(e) => {this.setState({nom: e.target.value}); console.log("nom", e.target.value)}} value={this.state.nomSite}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="prenom" >Prenom:</Label>
                                <Input type="text" name="prenom" id="prenom" rows={1}
                                placeholder={this.state.prenom}
                                onChange={(e) =>{this.setState({prenom: e.target.value}); console.log(e.target.value)}} value={this.state.prenom}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="numeroTelSalarie" >Numéro de téléphone:</Label>
                                <Input type="text" name="numeroTelSalarie" id="numeroTelSalarie" rows={1}
                                placeholder={this.state.numeroTelSalarie}
                                onChange={(e) =>{this.setState({numeroTelSalarie: e.target.value});}} value={this.state.numeroTelSalarie}/>
                            </FormGroup>

                            <FormGroup>
                            <Label for="statut" >
                                Statut : 
                            </Label>
                            <Input type="select" value={this.state.statut} onChange={(e) => this.setState({statut: e.target.value})}>
                                <option value="" ></option>
                                <option value="Agent de service" >Agent de service</option>
                                <option value="Chef d'équipe">Chef d'équipe</option>
                                <option value="Chargé de clientèle">Chargé de clientèle / Responsable</option>
                                <option value="Contrôleur">Contrôleur</option>
                            </Input>
                            </FormGroup>

                            <FormGroup >
                                <Label for="password" >Mot de passe:</Label>
                                <Input type="text" name="password" id="password" rows={1}
                                placeholder={this.state.password}
                                onChange={(e) =>{this.setState({password: e.target.value});}} value={this.state.password}/>
                            </FormGroup>


                            <Row className="justify-content-center align-items-center" style={{marginTop:"40px"}} >
                <Col className="justify-content-center align-items-center" md={12} style={{fontWeight:"bolder", textAlign:"center", fontSize:"20px", fontFamily:"Quicksand, 'sans-serif'", marginBottom:"40px"}} className="titre">
                  Chargé de clientèle / Responsable
                </Col>
            </Row>


                            <FormGroup >
                                <Label for="nomCharge" >Nom:</Label>
                                <Input type="text" name="nomCharge" id="nomCharge" rows={1}
                                placeholder={this.state.prenomCharge}
                                onChange={(e) =>{this.setState({nomCharge: e.target.value});}} value={this.state.nomCharge}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="prenomCharge" >Prénom:</Label>
                                <Input type="text" name="prenomCharge" id="prenomCharge" rows={1}
                                placeholder={this.state.prenomCharge}
                                onChange={(e) =>{this.setState({prenomCharge: e.target.value});}} value={this.state.prenomCharge}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="numeroTelCharge" >Numéro de téléphone:</Label>
                                <Input type="text" name="numeroTelCharge" id="numeroTelCharge" rows={1}
                                placeholder={this.state.numeroTelCharge}
                                onChange={(e) =>{this.setState({numeroTelCharge: e.target.value});}} value={this.state.numeroTelCharge}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="emailCharge" >E-mail:</Label>
                                <Input type="text" name="emailCharge" id="emailCharge" rows={1}
                                placeholder={this.state.emailCharge}
                                onChange={(e) =>{this.setState({emailCharge: e.target.value});}} value={this.state.emailCharge}/>
                            </FormGroup>

                            <Row className="justify-content-center text-center align-items-center">
                                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                    <Link to="/Pointage" style={{color:"white"}}>Annuler</Link></Button>
                                <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                                onClick={()=>{console.log("clic"); this.handleSubmitUpdate()}}>Valider</Button>
                            </Row>
                        
                    </Col>
                </Row>
            </Form>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    console.log('je recois de mon reducer ID suivant : ',state.userId)
  
    return { userIdfromStore: state.userId }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      saveId: function(id) {
          dispatch( {type: 'connect',
           id,
          } );
      }
    }
  }
  
  
  export default connect(
    mapStatetoProps,
    mapDispatchToProps
    )(MiseAjourProfil);