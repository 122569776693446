import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Table  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';
import TableFilter from 'react-table-filter';
import TableauListeReparations from './TableauListeReparations';



class MaterielDetails extends Component {

    constructor(props){
        super(props);
        this._filterUpdated = this._filterUpdated.bind(this);
        this.handleSubmitMaterielMaj= this.handleSubmitMaterielMaj.bind(this);
        this.state = ({     
            nomSite: "",
            nomMateriel: "",
            dateAchat: "",
            remarque: "",
            marque: "",
            allReparations:[],
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/materielDetails?idMateriel=${ctx.props.materielIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nomMateriel: data.materielDetail.nomMateriel,
                marque: data.materielDetail.marque, 
                nomSite: data.materielDetail.nomSite,
                dateAchat: data.materielDetail.dateAchat,
                remarque: data.materielDetail.remarque,
                allReparations: data.reparations,
                nbre: data.nbre
              })
            })
            .catch((error)=> {
                console.log('Request failed in my MaterielDetails', error)
            });
          }

          _filterUpdated = (newData, filtersConfiguration) => {
            this.setState({
                allReparations: newData
            });
        }
    
        

handleSubmitMaterielMaj() {
    let ctx = this;

    fetch(`${ip}/miseAjourAnomalie?idMateriel=${ctx.props.materielIdfromStore}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            isClick: true 
           })
        })
        .catch((error)=> {
            console.log('Request failed in my handleSubmitMaterielMaj request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/MiseAjourMateriel" />
          }


          var reparationsList = [];
          var reparationsListCopy = [ ...this.state.allReparations]
          
          reparationsList = reparationsListCopy.map((data,i) =>{
          return  (  
          <TableauListeReparations
              key={data._id}
              nomSite={data.nomSite}
              nomMateriel={data.nomMateriel}
              idMateriel={data._id}
              dateIntervention={data.dateIntervention}
              typeIntervention={data.typeIntervention}
              remarque={data.remarque}
              />)})
      


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Détails</h1>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Matériel: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", }}>{this.state.nomMateriel} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Site: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", }}> {this.state.nomSite} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Marque: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.marque} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Date d'achat: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.dateAchat} </span>
                </Col>
            </Row>
            {this.state.remarque !==""?(
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px", fontSize: "15px"}}>
                   Remarque: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.remarque} </span>
                </Col>
            </Row>): (null)}

            <Row className="justify-content-center text-center align-items-center">
                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                    <Link to="/ListeMaterielsParSite" style={{color:"white"}}>Retour</Link>
                </Button>
                <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                    <Link to="/MiseAjourMateriel" style={{color:"white"}}>Mettre à jour</Link>
                </Button>
            </Row>



            <hr style={{width: "500px", marginBottom: "50px", marginTop: "50px"}}/>
            
            <Row className="justify-content-center text-center">
                <Col className="justify-content-center" md={6} style={{marginBottom: "20px", fontWeight: 'bold', fontFamily:"Quicksand, 'sans-serif'", fontSize: "18px", textDecoration:"underline"}}>
                    Interventions          
                </Col>
            </Row>

            {this.state.nbre === 0 ? (
            <Row className="justify-content-center text-center align-items-center" 
            style={{marginTop:"40px", marginBottom:"50px", fontFamily:"Quicksand, 'sans-serif'"}}>
               <Col md={12}>
                   <span style={{fontStyle: "italic", fontSize:"16px"}}>Aucune intervention n'a été effectuée sur ce matériel.</span>
               </Col>
           </Row>):(

            <Row className="justify-content-center text-center align-items-center" style={{marginTop: "40px", marginBottom: "40px"}}>
                <Table hover id="table{-to-xls" 
                  className="tableau text-center align-items-center" 
                  style={{width:"1000px"}}>

                    <thead>
                      <TableFilter 
                        rows={this.state.allSites}
                        onFilterUpdate={this._filterUpdated}>
                            <th name="nomSite" filterkey="nomSite" casesensitive={'true'} showsearch={'true'}
                              style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"200px"}} >
                              DATE
                            </th>
                            <th name="adresse" filterkey="adresse" casesensitive={'true'} showsearch={'true'}
                              style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"200px"}} >
                             INTERVENTION
                            </th>
                            <th name="adresse" filterkey="adresse" casesensitive={'true'} showsearch={'true'}
                              style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"200px"}} >
                             REMARQUE
                            </th>

                            {/* <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                              VOIR
                            </th> */}
                      </TableFilter>
                    </thead> 
                    <tbody>
                      {reparationsList}
                    </tbody>
                </Table>
            </Row>)}


            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                    <Link to="/ListeMaterielsParSite" style={{color:"white"}}>Retour</Link>
                </Button>
                <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                    <Link to="/SignalerReparation" style={{color:"white"}}>Signaler une intervention</Link>
                </Button>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
  console.log('materielIdfromStore', state.materielId)
    return { materielIdfromStore: state.materielId }
}
  
  
function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idMateriel) {
          dispatch( {type: 'connect7',
           idMateriel,
          } );
       }
      }
    }
    
    export default connect(
      mapStatetoProps, 
      mapDispatchToProps
    )(MaterielDetails);