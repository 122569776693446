import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';
import Select from 'react-select'



class EnregistrementSite extends Component {

    constructor(props){
        super()
        this.state = ({
            nomCharge: "",
            prenomCharge:"",
            nomSite:"",
            adresseSite:"",
            nomClientSite:"",
            prenomClientSite:"",
            numeroTelClientSite:"",
            emailClientSite:'',
            isSiteExist: false,
            listeChargesDeroulante: [],
        })
    }

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/addSite`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            listeChargesDeroulante: data.listeCharges,
           })
           console.log('liste', this.state.listeChargesDeroulante)
        })
        .catch((error)=> {
            console.log('Request failed in my AddSite', error)
        });
    }



    handleSubmitSignUp(e){
        let ctx = this;
        fetch(`${ip}/enregistrementSite`, {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body: `prenomCharge=${ctx.state.prenomCharge}&nomCharge=${ctx.state.nomCharge}&nomSite=${ctx.state.nomSite}&adresseSite=${ctx.state.adresseSite}&nomClientSite=${ctx.state.nomClientSite}&prenomClientSite=${ctx.state.prenomClientSite}&numeroTelClientSite=${ctx.state.numeroTelClientSite}&emailClientSite=${ctx.state.emailClientSite}`
        }
        )
        .then((data)=>{
            this.setState({
                isSiteExist:true
            });
            })
        .catch((error)=> {
            console.log('Request failed in EnregistrementSite', error)
        });
     }
    


    render(){

        let button;

        if (this.state.nomSite ==='' || this.state.nomCharge === ''){
            button =
           <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                
                disabled>
                Valider
            </Button>      
        } else {
            button =
           <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                onClick={()=>{this.handleSubmitSignUp()}}>
                Valider
            </Button> 
        }

        if (this.state.isSiteExist){
            return <Redirect to="/ValidationNouveauSite"></Redirect>

        }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px", backgroundColor:""}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "30px"}}>
                        Enregistrer un nouveau site </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
              style={{paddingBottom:"60px"}}>             
                <Col className="justify-content-center text-center align-items-center" md={7}>
                    <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>

                        <FormGroup>
                            <Label for="nomSite" >Nom du site:</Label>
                            <Input type="text" name="nomSite" id="nomSite" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({nomSite: e.target.value})} value={this.state.nomSite}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="adresseSite" >Adresse:</Label>
                            <Input type="text" name="adresseSite" id="adresseSite" rows={1}
                            placeholder=""
                            required
                            onChange={(e) => this.setState({adresseSite: e.target.value})} value={this.state.adresseSite}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="nomCharge" >Chargé de clientèle / Responsable:</Label>
                            <Select 
                                options={this.state.listeChargesDeroulante.map(t=>({value: t, label: t}))}
                                onChange={(value) => {
                                    console.log(value.value);
                                    this.setState({ nomCharge: value.value, prenomCharge: value.value })}}
                                placeholder="Sélectionnez un chargé de clientèle / responsable"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="nomClientSite" >Nom du client:</Label>
                            <Input type="text" name="nomClientSite" id="nomClientSite" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({nomClientSite: e.target.value})} value={this.state.nomClientSite}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="prenomClientSite" >Prénom du client:</Label>
                            <Input type="text" name="prenomClientSite" id="prenomClientSite" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({prenomClientSite: e.target.value})} value={this.state.prenomClientSite}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="numeroTelClientSite" >Numéro de téléphone:</Label>
                            <Input type="text" name="numeroTelClientSite" id="numeroTelClientSite" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({numeroTelClientSite: e.target.value})} value={this.state.numeroTelClientSite}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="emailClientSite" >E-mail:</Label>
                            <Input type="text" name="emailClientSite" id="emailClientSite" rows={1}
                            placeholder=""
                            onChange={(e) => this.setState({emailClientSite: e.target.value})} value={this.state.emailClientSite}/>
                        </FormGroup>




                        <Row className="justify-content-center text-center align-items-center">
                            <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                <Link to="/ListeSites" style={{color:"white"}}>Annuler</Link></Button>
                            {button}                  
                        </Row>
                    </Form>
                </Col>
            </Row>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStateToProps(state) {
    return { userIdfromStore: state.userId }
  }  


  export default connect(
      mapStateToProps,
      null
  )(EnregistrementSite);