import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Row, Col, Table, Container, Input, Button} from 'reactstrap';
import {connect} from 'react-redux'; 
import {Redirect, Link} from 'react-router-dom';
import './App.css';
import ip from './ipconfig';


 
class TableauFeuillePointageAll extends Component {
 
    constructor(props) {
        super(props);
        this.capitalize = this.capitalize.bind(this)

        this.state = {
            day:this.props.day,
            date:this.props.date,
            nomSite: this.props.nomSite,
            heureArrivee:this.props.heureArrivee,
            heureDepart:this.props.heureDepart,
            nbreHeureTravaillee: this.props.nbreHeureTravaillee
            };
      }

      capitalize() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    render() {
        var daytrouver = this.props.day
        var dayLettre = daytrouver[0].toUpperCase()+daytrouver.slice(1)
        console.log('capitalise', dayLettre)

        return (

            <tr>
                <th scope="row" style={{width:"50px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{dayLettre}</th>
                <td style={{width:"50px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.date}</td>
                <td style={{width:"50px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.nomSite}</td>
                <td style={{width:"50px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.heureArrivee}</td>
                <td style={{width:"50px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.heureDepart}</td>
                <td style={{width:"50px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold", color:'red'}}>{this.props.nbreHeureTravaillee}</td>
            </tr>  
        
        );
    }
}
 
function mapStatetoProps(state) {

    return { userIdfromStore: state.userId, siteIdfromStore: state.siteId }
  }
  
//   function mapDispatchToProps(dispatch) {
//     return {
//       saveId: function(idSite) {
//         console.log('tableau Feuillepointage user ttt')
//           dispatch( {type: 'connect1',
//            idSite,
//           } );
//       }
//     }
//   }


  export default connect(
    mapStatetoProps,
    null
    )(TableauFeuillePointageAll);