import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import ip from './ipconfig';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Button} from 'reactstrap';
import {connect} from 'react-redux'; 


class DemandeCpPersoConfirmSup extends Component {   
    constructor(props){
        super(props);
        this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this)

        this.state = ({     
            nomSite: '',
            listProduits: '',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/modifierDemandeCp?idMessage=${ctx.props.messageCpIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                matricule: data.message.matricule,
                nom: data.message.nom, 
                prenom: data.message.prenom,
                date: data.message.date,
                etat: data.message.etat,
                dateTraitement: data.message.dateTraitement,
                remarque: data.message.remarque,
                nomCharge: data.message.nomCharge,
                prenomCharge: data.message.prenomCharge,
                dateDebutCp: data.message.dateDebutCp,
                dateFinCp: data.message.dateFinCp,
                nbreJoursCp: data.message.nbreJoursCp,
              })
            })
            .catch((error)=> {
                console.log('Request failed in my SupDemandeCp', error)
            });
          }
        


          handleSubmitConfirm() {
            let ctx = this;
        
            fetch(`${ip}/deleteDemandeCp?id=${ctx.props.userIdfromStore}&idMessage=${ctx.props.messageCpIdfromStore}`)
                .then(function(res, err){
                  return res.json()
                }).then((data)=> {
                   ctx.setState({
                    isClick: true                   
                   })
                })
                .catch((error)=> {
                    console.log('Request failed in my /messageconfirm request', error)
                });
            }
        




    render(){

        if (this.state.isClick){
            return < Redirect to="/SuccesDeleteDemandeCpPerso" />
          }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" 
             style={{height:"550px" }}>
                <Col md={5}>
                    <img src="./images/danger.jpg" height="300px" alt="Enregistrement réussi" style={{borderRadius:"8px"}}></img>
                </Col>

                <Col >
                    <Row className=" align-items-center justify-content-center ">
                        <h1 style={{ fontWeight:"normal", paddingTop: "60px",  fontFamily:"Quicksand, 'sans-serif'",fontSize: "20px", paddingBottom:"10px",}}>
                        Vous allez supprimer la demande de congé de 
                        </h1>
                    </Row>                    
                    <Row className=" align-items-center justify-content-center ">
                        <h1 style={{ fontWeight:"bolder",  fontFamily:"Quicksand, 'sans-serif'",fontSize: "20px", paddingBottom:"40px",}}>
                            {this.state.nom} {this.state.prenom} 
                        </h1>
                    </Row>


                    <Row className="text-center align-items-center justify-content-center">
                        <h1 style={{fontWeight:"bolder", paddingBottom:"20px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "24px"}}>
                        Confirmez-vous cette suppression ?
                        </h1>
                    </Row>
                    <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "20px"}}>
                        <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                            <Link to="/DemandesCongesPerso" style={{color:"white"}}>Retour</Link>
                        </Button>
                        <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                            onClick={()=>{ this.handleSubmitConfirm()}}>
                            Confirmer
                        </Button>
                    </Row>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {

    return { messageCpIdfromStore: state.messageCpId }
  }
  
  
  function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idMessageCp) {
          dispatch( {type: 'connect3',
           idMessageCp,
          } );
       }
      }
    }
    
    export default connect(
      mapStatetoProps, 
      mapDispatchToProps
    )(DemandeCpPersoConfirmSup);