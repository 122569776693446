import React, { Fragment }  from 'react';
import './styles.css';
import Menu from './Menu';
import TableauDemandesCpPerso from './TableauDemandesCpPerso';
import Footer from './Footer';
import ip from './ipconfig';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Input, Button, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {connect} from 'react-redux';

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";


const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};


class DemandesCongesPerso extends React.Component {

    constructor() {
      super();
      this.handleSubmitDemandeModifierCp=this.handleSubmitDemandeModifierCp.bind(this)
      this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);
      this.handleSubmitConfirmSup = this.handleSubmitConfirmSup.bind(this)
    
      this.state={
        matricule:"",
        prenom:"",
        nom:"",
        prenomCharge:"",
        nomCharge:"",
        nbre: "",
        allDemandes:[],
        visibleFilters: [],
        isClick: false,
        isClick1: false,
      }};
      
    
      componentDidMount(){
        let ctx=this;
    
        fetch(`${ip}/demandesCpPerso?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
            return res.json()
          }).then((data)=> {
            ctx.setState({
              matricule: data.user.matricule,
              prenom: data.user.prenom,
              nom: data.user.nom,
              prenomCharge: data.user.prenomCharge,
              nomCharge: data.user.nomCharge,  
              allDemandes: data.message,
              nbre: data.nbre
            })
          })
          .catch((error)=> {
              console.log('Request failed', error)
          });
            }

        toggleFilter = id => {
          this.setState(({ visibleFilters }) => {
            let update = [...visibleFilters];
            const index = update.indexOf(id);
            index < 0 ? update.push(id) : update.splice(index, 1);
            return { visibleFilters: update };
          });
        };



        filterCaseInsensitive = (filter, row) => {
          const id = filter.pivotId || filter.id;
          const content = row[id];
      if (typeof content !== 'undefined') {
      
          if (filter.value.length < 4) {
              // filter by text in the table or if it's a object, filter by key
              if (typeof content === 'object' && content !== null && content.key) {
                  return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
              } else {
                  return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
              }
          } else {
              // filter by text in the table or if it's a object, filter by key
              if (typeof content === 'object' && content !== null && content.key) {
                  return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
              } else {
                  return String(content).toLowerCase().includes(filter.value.toLowerCase());
              }
          }
      
      }}
      
      
        handleSubmitDemandeModifierCp(data){
          let ctx=this;
      
          fetch(`${ip}/demandesModifCp?idConge=${data._id}`)
          .then(function(res, err){
            return res.json()
          }).then((data)=>{
              ctx.props.saveId(data.message._id)
              ctx.setState ({
                  isClick:true,
                })
              }
            ) 
            .catch((error)=> {
              console.log('Request failed in my tableaudemandeCpPerso request', error)
          });
        }
          
        getTrPropsType(state, rowInfo, column) {
          const { original } = rowInfo;
          const { color } = original;    
          return {
            
              style: {
                  borderColor: color
              },
          }
        }
        
  
        handleSubmitConfirmSup(data){
          let ctx=this;
      
          fetch(`${ip}/demandesModifCp?idConge=${data._id}`)
          .then(function(res, err){
            return res.json()
          }).then((data)=>{
              ctx.props.saveId(data.message._id)
              ctx.setState ({
                  isClick1:true,
                })
              }
            ) 
            .catch((error)=> {
                  console.log('Request failed in my tableaudemande request', error)
          });
        }
      
      
      
          
    render(){

    var demandesList = [];
    var demandesListCopy=[...this.state.allDemandes]
    
    demandesList = demandesListCopy.map((data,i) =>{
    return  (  
      {
        matricule: data.matricule,
        nom: data.nom,
        idConge: data._id,
        prenom: data.prenom,
        date: data.date,
        periode:{dateDebutCp: data.dateDebutCp, dateFinCp: data.dateFinCp,},
        etat: data.etat,
        voir: <Button style={{backgroundColor:"white", border:"none"}} 
            onClick={()=>{
              this.handleSubmitDemandeModifierCp(data) 
            }}>
        <img src="./images/oeil.png" height="30px" /></Button>,
        supprimer: <Button style={{backgroundColor:"white", border:"none"}} 
          onClick={()=>{
            this.handleSubmitConfirmSup(data) 
          }}>
        <img src="./images/annulation.png" height="25px" /></Button>
      }
      )})
         


      if (this.state.isClick){
        return < Redirect to="/ModifDemandeCpPerso" />
      }
  
      if (this.state.isClick1){
        return < Redirect to="/DemandeCpPersoConfirmSup" />
      }



        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link">
                  <Link to="/PointagesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    1. Pointages
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Plannings
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link className="link" 
                    style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px"}}
                  >
                    3. Demandes de congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      onClick={()=>{
                       
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    4. Demandes d'acomptes
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Profil
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>

            <Row className="justify-content-center text-left align-items-center" 
             style={{}}>
                <Col md={7}  style={{}}>
                  <h1 style={{fontWeight:"bold", fontStyle:'italic', fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px",}}>
                  <img src="./images/homme.png" height="25px" style={{marginRight: "10px"}}/>Collaborateur: 
                    <span style={{fontStyle:'italic', marginLeft: "5px",  fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px"}}>
                        {this.state.nom} {this.state.prenom} <span style={{fontWeight: "normal"}}>(Matricule: {this.state.matricule})</span>
                    </span>
                  </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Demandes de congés </h1>
                </Col>
            </Row>
  
            {this.state.nbre === 0 ? (
            <Row className="justify-content-center text-center align-items-center" 
            style={{marginTop:"40px", marginBottom:"50px", height:"18px", fontFamily:"Quicksand, 'sans-serif'"}}>
               <Col md={12}>
                   <span style={{fontStyle: "italic", fontSize:"20px"}}>Il n'y a pas de demande de congés.</span>
               </Col>
           </Row>):(
            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
              <ReactTable
                previousText="Précédent"
                nextText="Suivant"
                rowsText="lignes"
                ofText= 'à'
                defaultFilterMethod={this.filterCaseInsensitive}
                data={demandesList}
                style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
                resolveData={data => data.map(row => row)} 
                columns={[
                  {
                    columns: [
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Date",                        
                        accessor: "date",
                        filterable: true,
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 150,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Période</div>,
                        label: "Période",
                        accessor: 'periode',
                        Cell     : row => {
                          if (row.row.periode.dateFinCp==='null'){
                          return (
                            <div>
                              {row.row.periode.dateDebutCp}
                            </div>
                          )} else {
                            return (
                              <div>
                              Du {row.row.periode.dateDebutCp} au {row.row.periode.dateFinCp}
                            </div>
                            )
                          }
                        },
                        style:{fontWeight: 'bold', margin: 'auto',},
                        minWidth: 200,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', }}>Etat</div>,
                        accessor: "etat",
                        getProps: (state, rowInfo, column) => {
                          return {
                              style: {
                                  color: rowInfo && rowInfo.row.etat ==="En cours de traitement" ? 'red' : null,
                              },
                          };
                      },

                        style:{fontWeight: 'bolder', margin: 'auto'},
                        minWidth: 200,
                        
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Voir</div>,
                        accessor: "voir",
                        minWidth: 100,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Supprimer</div>,
                        accessor: "supprimer",
                        minWidth: 100,
                      }
                    ],
                    
                  },
                  
                
                ]
                
                }
                getTheadFilterProps={(state, rowInfo, column, instance) => {
                  return {
                    style:
                      this.state.visibleFilters.length === 0
                        ? { display: "none" }
                        : null
                  };
                }}
                getTheadFilterThProps={(state, rowInfo, column, instance) => {
                  return {
                    className:
                      this.state.visibleFilters.indexOf(column.id) < 0
                        ? "hiddenFilter"
                        : null
                  };
                }}
                defaultPageSize={10}
                getTrPropsType= {this.getTrPropsType}
                className="-striped -highlight justify-content-center text-center align-items-center "
              />
            </Row>

          
            
            )}

            <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    >
                      <Link to="/Salaries" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {

  return { userIdfromStore: state.userId, messageCpIdfromStore: state.messageCpId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMessageCp) {
        dispatch( {type: 'connect3',
         idMessageCp,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(DemandesCongesPerso);