import React, { Component }  from 'react';
import './App.css';
import Homepage from './Homepage';
import Salaries from './Salaries';
import Pointage from './Pointage';
import Enregistrement from './Enregistrement';
import Erreur from './Erreur';
import AddPlanning from './AddPlanning';
import Validation from './Validation';
import ValidationMaj from './ValidationMaj';
import FeuillePointage from './FeuillePointage';
import Demandes from './Demandes';
import ModifDemande from './ModifDemande';
import DemandesConges from './DemandesConges';
import DemandesAcomptes from './DemandesAcomptes';
import ModifDemandeCp from './ModifDemandeCp';
import SitesPerso from './SitesPerso';
import PointagesPerso from './PointagesPerso';
import DemandesCongesPerso from './DemandesCongesPerso';
import DemandesAcomptesPerso from './DemandesAcomptesPerso';
import ModifDemandeAcompte from './ModifDemandeAcompte';
import DemandesPrdtsPerso from './DemandesPrdtsPerso';
import FeuilleSitesPrdts from './FeuilleSitesPrdts';
import ValidationMajDemandePrdtsPerso from './ValidationMajDemandePrdtsPerso';
import ValidationMajDemandeAcomptePerso from './ValidationMajDemandeAcomptePerso';
import ValidationMajDemandeCpPerso from './ValidationMajDemandeCpPerso';
import ModifDemandePrdtsPerso from './ModifDemandePrdtsPerso';
import ValidationMajSitesPerso from './ValidationMajSitesPerso';
import ValidationMajProfil from './ValidationMajProfil';
import MiseAjourProfil from './MiseAjourProfil';
import ModifDemandeCpPerso from './ModifDemandeCpPerso';
import ValidationMajDemandeCp from './ValidationMajDemandeCp'
import ModifDemandeAcomptePerso from './ModifDemandeAcomptePerso';
import ValidationMajDemandeAcompte from './ValidationMajDemandeAcompte';
import ValidationMajDemandePrdts from './ValidationMajDemandePrdts';
import EnregistrementSite from './EnregistrementSite';
import ListeSites from './ListeSites';
import SiteDetails from './SiteDetails';
import ValidationNouveauSite from './ValidationNouveauSite';
import MiseAjourSite from './MiseAjourSite';
import ValidationMajSites from './ValidationMajSites';
import ValidationMajPlanningSites from './ValidationMajPlanningSites'
import ListeSitesAnomalies from './ListeSitesAnomalies'
import ListeAnomaliesParSite from './ListeAnomaliesParSite'
import TraitementAnomalie from './TraitementAnomalie'
import ValidationAnomalieTraitee from './ValidationAnomalieTraitee'
import ListeMateriels from './ListeMateriels'
import ListeMaterielsParSite from './ListeMaterielsParSite'
import MaterielDetails from './MaterielDetails'
import MiseAjourMateriel from './MiseAjourMateriel'
import ValidationMajMateriel from './ValidationMajMateriel'
import SignalerReparation from './SignalerReparation'
import ValidationReparation from './ValidationReparation'
import AddMateriel from './AddMateriel'
import ValidationAddMateriel from './ValidationAddMateriel'
import MaterielConfirmSup from './MaterielConfirmSup'
import SuccesDeleteMateriel from './SuccesDeleteMateriel'
import SiteConfirmSup from './SiteConfirmSup'
import SuccesDeleteSite from './SuccesDeleteSite'
import PlanningConfirmSup from './PlanningConfirmSup'
import SuccesDeletePlanning from './SuccesDeletePlanning'
import DemandeConfirmSup from './DemandeConfirmSup'
import SuccesDeleteDemande from './SuccesDeleteDemande'
import DemandeCpConfirmSup from './DemandeCpConfirmSup'
import SuccesDeleteDemandeCp from './SuccesDeleteDemandeCp'
import DemandeAcompteConfirmSup from './DemandeAcompteConfirmSup'
import SuccesDeleteDemandeAcompte from './SuccesDeleteDemandeAcompte'
import DemandeCpPersoConfirmSup from './DemandeCpPersoConfirmSup'
import SuccesDeleteDemandeCpPerso from './SuccesDeleteDemandeCpPerso'
import DemandeAcomptePersoConfirmSup from './DemandeAcomptePersoConfirmSup'
import SuccesDeleteDemandeAcomptePerso from './SuccesDeleteDemandeAcomptePerso'
import PointagesPersoSites from './PointagesPersoSites'


import 'bootstrap/dist/css/bootstrap.css';
import userId from './reducers/myReducer';
import siteId from './reducers/myReducer1';
import messageId from './reducers/myReducer2';
import messageCpId from './reducers/myReducer3';
import messageAcompteId from './reducers/myReducer4';
import siteInfoId from './reducers/myReducer5';
import anomalieId from './reducers/myReducer6';
import materielId from './reducers/myReducer7';


import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, combineReducers}  from 'redux';
import MiseAjourPlanningSite from './MiseAjourPlanningSite';

const store = createStore(combineReducers({userId, siteId, messageId, messageCpId, messageAcompteId, siteInfoId, anomalieId, materielId, }));




class App extends Component {
  render() {
    return (
      <Provider store={store}>
      <Router>
          <Switch>
                   <Route path="/" exact component={Homepage} />
                   <Route path="/Salaries" exact component={Salaries} />
                   <Route path="/Pointage" exact component={Pointage} />
                   <Route path="/Enregistrement" exact component={Enregistrement} />
                   <Route path="/Erreur" exact component={Erreur} />
                   <Route path="/AddPlanning" exact component={AddPlanning}/>
                   <Route path="/MiseAjourPlanningSite" exact component={MiseAjourPlanningSite}/>
                   <Route path="/FeuillePointage" exact component={FeuillePointage}/>
                   <Route path="/Demandes" exact component={Demandes}/>
                   <Route path="/ModifDemande" exact component={ModifDemande}/>
                   <Route path="/Validation" exact component={Validation}/>
                   <Route path="/ValidationMaj" exact component={ValidationMaj}/>
                   <Route path="/DemandesConges" exact component={DemandesConges}/>
                   <Route path="/DemandesAcomptes" exact component={DemandesAcomptes}/>
                   <Route path="/ModifDemandeCp" exact component={ModifDemandeCp}/>
                   <Route path="/SitesPerso" exact component={SitesPerso}/>
                   <Route path="/PointagesPerso" exact component={PointagesPerso}/>
                   <Route path="/DemandesCongesPerso" exact component={DemandesCongesPerso}/>
                   <Route path="/DemandesAcomptesPerso" exact component={DemandesAcomptesPerso}/>
                   <Route path="/ModifDemandeAcompte" exact component={ModifDemandeAcompte}/>
                   <Route path="/DemandesPrdtsPerso" exact component={DemandesPrdtsPerso}/>
                   <Route path="/FeuilleSitesPrdts" exact component={FeuilleSitesPrdts}/>
                   <Route path="/ModifDemandePrdtsPerso" exact component={ModifDemandePrdtsPerso}/>
                   <Route path="/ValidationMajDemandePrdtsPerso" exact component={ValidationMajDemandePrdtsPerso}/>
                   <Route path="/ValidationMajDemandeAcomptePerso" exact component={ValidationMajDemandeAcomptePerso}/>
                   <Route path="/ValidationMajDemandeCpPerso" exact component={ValidationMajDemandeCpPerso}/>
                   <Route path="/ValidationMajSitesPerso" exact component={ValidationMajSitesPerso}/>
                   <Route path="/MiseAjourProfil" exact component={MiseAjourProfil}/>
                   <Route path="/ValidationMajProfil" exact component={ValidationMajProfil}/>
                   <Route path="/ModifDemandeCpPerso" exact component={ModifDemandeCpPerso}/>
                   <Route path="/ValidationMajDemandeCp" exact component={ValidationMajDemandeCp}/>
                   <Route path="/ValidationMajDemandeAcompte" exact component={ValidationMajDemandeAcompte}/>
                   <Route path="/ModifDemandeAcomptePerso" exact component={ModifDemandeAcomptePerso}/>
                   <Route path="/ValidationMajDemandePrdts" exact component={ValidationMajDemandePrdts}/>
                   <Route path="/EnregistrementSite" exact component={EnregistrementSite}/>
                   <Route path="/ListeSites" exact component={ListeSites}/>
                   <Route path="/ValidationAddMateriel" exact component={ValidationAddMateriel}/>
                   <Route path="/Sitedetails" exact component={SiteDetails}/>
                   <Route path="/MiseAjourSite" exact component={MiseAjourSite}/>
                   <Route path="/ValidationMajSites" exact component={ValidationMajSites}/>
                   <Route path="/ValidationMajPlanningSites" exact component={ValidationMajPlanningSites}/>
                   <Route path="/ListeSitesAnomalies" exact component={ListeSitesAnomalies}/>
                   <Route path="/ListeAnomaliesParSite" exact component={ListeAnomaliesParSite}/>
                   <Route path="/TraitementAnomalie" exact component={TraitementAnomalie}/>
                   <Route path="/ValidationAnomalieTraitee" exact component={ValidationAnomalieTraitee}/>
                   <Route path="/ListeMateriels" exact component={ListeMateriels}/>
                   <Route path="/ListeMaterielsParSite" exact component={ListeMaterielsParSite}/>
                   <Route path="/MaterielDetails" exact component={MaterielDetails}/>
                   <Route path="/MiseAjourMateriel" exact component={MiseAjourMateriel}/>
                   <Route path="/ValidationMajMateriel" exact component={ValidationMajMateriel}/>
                   <Route path="/SignalerReparation" exact component={SignalerReparation}/>
                   <Route path="/ValidationReparation" exact component={ValidationReparation}/>
                   <Route path="/AddMateriel" exact component={AddMateriel}/>
                   <Route path="/ValidationAddMateriel" exact component={ValidationAddMateriel}/>
                   <Route path="/ValidationNouveauSite" exact component={ValidationNouveauSite}/>
                   <Route path="/MaterielConfirmSup" exact component={MaterielConfirmSup}/>
                   <Route path="/SuccesDeleteMateriel" exact component={SuccesDeleteMateriel}/>
                   <Route path="/SiteConfirmSup" exact component={SiteConfirmSup}/>
                   <Route path="/SuccesDeleteSite" exact component={SuccesDeleteSite}/>
                   <Route path="/PlanningConfirmSup" exact component={PlanningConfirmSup}/>
                   <Route path="/SuccesDeletePlanning" exact component={SuccesDeletePlanning}/>
                   <Route path="/DemandeConfirmSup" exact component={DemandeConfirmSup}/>
                   <Route path="/SuccesDeleteDemande" exact component={SuccesDeleteDemande}/>
                   <Route path="/DemandeCpConfirmSup" exact component={DemandeCpConfirmSup}/>
                   <Route path="/SuccesDeleteDemandeCp" exact component={SuccesDeleteDemandeCp}/>
                   <Route path="/DemandeAcompteConfirmSup" exact component={DemandeAcompteConfirmSup}/>
                   <Route path="/SuccesDeleteDemandeAcompte" exact component={SuccesDeleteDemandeAcompte}/>
                   <Route path="/DemandeCpPersoConfirmSup" exact component={DemandeCpPersoConfirmSup}/>
                   <Route path="/SuccesDeleteDemandeCpPerso" exact component={SuccesDeleteDemandeCpPerso}/>
                   <Route path="/DemandeAcomptePersoConfirmSup" exact component={DemandeAcomptePersoConfirmSup}/>
                   <Route path="/SuccesDeleteDemandeAcomptePerso" exact component={SuccesDeleteDemandeAcomptePerso}/>
                   <Route path="/PointagesPersoSites" exact component={PointagesPersoSites}/>

          </Switch>
      </Router>
      </Provider>

    );
  }
}

export default App;