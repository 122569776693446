import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class MiseAjourSite extends Component {

    constructor(props){
        super(props);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.state = ({     
            nomSite: '',
            nomCharge: '',
            prenomCharge: '',
            adresseSite: '',
            nomClientSite: '',
            prenomClientSite: '',
            numeroTelClientSite: '',
            emailClientSite: '',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/infoSiteAmettreAjour?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteInfoIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nomSite: data.site.nomSite,
                adresseSite: data.site.adresseSite,
                nomClientSite: data.site.nomClientSite,
                prenomClientSite: data.site.prenomClientSite,
                numeroTelClientSite: data.site.numeroTelClientSite,
                nomCharge: data.site.nomCharge,
                prenomCharge: data.site.prenomCharge,
                emailClientSite: data.site.emailClientSite
              })
            })
            .catch((error)=> {
                console.log('Request failed in my infoSiteAmettreAjour', error)
            });
          }
        

handleSubmitUpdate() {
    let ctx = this;

    fetch(`${ip}/miseAjourSite`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `idSite=${ctx.props.siteInfoIdfromStore}&emailClientSite=${ctx.state.emailClientSite}&nomSite=${ctx.state.nomSite}&adresseSite=${ctx.state.adresseSite}&nomClientSite=${ctx.state.nomClientSite}&prenomClientSite=${ctx.state.prenomClientSite}&numeroTelClientSite=${ctx.state.numeroTelClientSite}&prenomCharge=${ctx.state.prenomCharge}&nomCharge=${ctx.state.nomCharge}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            lundiArriveeTheorie: data.site.lundiArriveeTheorie,
            lundiDepartTheorie: data.site.lundiDepartTheorie,
            mardiArriveeTheorie:data.site.mardiArriveeTheorie,
            mardiDepartTheorie: data.site.mardiDepartTheorie,
            mercrediArriveeTheorie:data.site.mercrediArriveeTheorie,
            mercrediDepartTheorie: data.site.mercrediDepartTheorie,
            jeudiArriveeTheorie:data.site.jeudiArriveeTheorie,
            jeudiDepartTheorie: data.site.jeudiDepartTheorie,
            vendrediArriveeTheorie:data.site.vendrediArriveeTheorie,
            vendrediDepartTheorie: data.site.vendrediDepartTheorie,
            samediArriveeTheorie:data.site.samediArriveeTheorie,
            samediDepartTheorie: data.site.samediDepartTheorie,
            dimancheArriveeTheorie:data.site.dimancheArriveeTheorie,
            dimancheDepartTheorie: data.site.dimancheDepartTheorie,
            nomSite: data.site.nomSite,
            adresseSite: data.site.adresseSite ,
            nomCharge: data.site.nomCharge,
            prenomCharge: data.site.prenomCharge,
            prenomClientSite: data.site.prenomClientSite,
            nomClientSite: data.site.nomClientSite,
            emailClientSite: data.site.emailClientSite,
            isClick: true                   
           })
        })
        .catch((error)=> {
            console.log('Request failed in my /miseAjourPlanningSite request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationMajSites" />
          }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Mettre à jour le site</h1>
                </Col>
            </Row>

            <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>
                <Row className="justify-content-center align-items-center" 
                style={{paddingBottom:"60px"}} form>             
                    <Col className="justify-content-center align-items-center" md={6}>
                            <FormGroup>
                                <Label for="nomSite" style={{fontWeight: 'bolder'}}>Nom du site:</Label>
                                <Input type="text" name="nomSite" id="nomSite" rows={1}
                                placeholder={this.state.nomSite}
                                onChange={(e) => {this.setState({nomSite: e.target.value}); console.log("nomSite", e.target.value)}} value={this.state.nomSite}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="adresseSite" style={{fontWeight: 'bolder'}}>Adresse:</Label>
                                <Input type="text" name="adresseSite" id="adresseSite" rows={1}
                                placeholder={this.state.adresseSite}
                                onChange={(e) =>{this.setState({adresseSite: e.target.value}); console.log(e.target.value)}} value={this.state.adresseSite}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="nomCharge" style={{fontWeight: 'bolder'}}>Nom du chargé de clientèle / Responsable:</Label>
                                <Input type="text" name="nomCharge" id="nomCharge" rows={1}
                                placeholder={this.state.nomCharge}
                                onChange={(e) =>{this.setState({nomCharge: e.target.value});}} value={this.state.nomCharge}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="prenomCharge" style={{fontWeight: 'bolder'}}>Prénom du Chargé de clientèle / Responsable:</Label>
                                <Input type="text" name="prenomCharge" id="prenomCharge" rows={1}
                                placeholder={this.state.prenomCharge}
                                onChange={(e) =>{this.setState({prenomCharge: e.target.value});}} value={this.state.prenomCharge}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="nomClientSite" style={{fontWeight: 'bolder'}} >Nom du client:</Label>
                                <Input type="text" name="nomClientSite" id="nomClientSite" rows={1}
                                placeholder={this.state.nomClientSite}
                                onChange={(e) =>{this.setState({nomClientSite: e.target.value});}} value={this.state.nomClientSite}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="prenomClientSite" style={{fontWeight: 'bolder'}}>Prénom du client:</Label>
                                <Input type="text" name="prenomClientSite" id="prenomClientSite" rows={1}
                                placeholder={this.state.prenomClientSite}
                                onChange={(e) =>{this.setState({prenomClientSite: e.target.value});}} value={this.state.prenomClientSite}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="numeroTelClientSite" style={{fontWeight: 'bolder'}}>Numero de téléphone du client:</Label>
                                <Input type="text" name="numeroTelClientSite" id="numeroTelClientSite" rows={1}
                                placeholder={this.state.numeroTelClientSite}
                                onChange={(e) =>{this.setState({numeroTelClientSite: e.target.value});}} value={this.state.numeroTelClientSite}/>
                            </FormGroup>

                            <FormGroup >
                                <Label for="emailClientSite" style={{fontWeight: 'bolder'}}>E-mail du client:</Label>
                                <Input type="text" name="numeroTelClientSite" id="emailClientSite" rows={1}
                                placeholder={this.state.emailClientSite}
                                onChange={(e) =>{this.setState({emailClientSite: e.target.value});}} value={this.state.emailClientSite}/>
                            </FormGroup>


                            <Row className="justify-content-center text-center align-items-center">
                                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                    <Link to="/SiteDetails" style={{color:"white"}}>Annuler</Link></Button>
                                <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                                onClick={()=>{this.handleSubmitUpdate()}}>Valider</Button>
                            </Row>
                        
                    </Col>
                </Row>
            </Form>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
  
    return { userIdfromStore: state.userId, siteInfoIdfromStore: state.siteInfoId }
  }
  
  
    export default connect(
      mapStatetoProps,
      null
      )(MiseAjourSite);