import React, { Component }  from 'react';
import './styles.css';
import Footer from './Footer';
import ip from './ipconfig';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connect} from 'react-redux';
import {Row, Col,  Container, Input, Button, FormGroup, Label, Form, FormFeedback } from 'reactstrap';


class Homepage extends Component {

    constructor(props) {
        super(props);
        this.handleSubmitSignIn = this.handleSubmitSignIn.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.state = ({
          matricule: '',
          password: '',
          isUserExist:false,
        });
      }
    
      handleSubmitSignIn(){   
        let ctx = this;  
        fetch(`${ip}/sign-in?matricule=${ctx.state.matricule}&password=${ctx.state.password}`)
      .then(function(res, err){
        return res.json();
      }).then((data)=>{
        if (data.result === true){
          ctx.props.saveId(data.user._id)    
            this.setState({
              isUserExist:true,
              isAll: 'vrai'
           })
           console.log('is', this.state.isPassword)
          } 
          else if (data.resultMatricule === false && data.resultPassword === true) {
            this.setState({
              isAll: 'faux',
              isMatricule: 'faux',
              isPassword: 'vrai',
           })
           console.log('data.resultMatricule === false', this.state.isMatricule)
          }
          else if (data.resultMatricule === true && data.resultPassword === false) {
            this.setState({
              isAll: 'faux',
              isMatricule: 'vrai',
              isPassword: 'faux',
           })
           console.log('data.resultPassword === false', this.state.isPassword)

          }else  {
            this.setState({
              isAll: 'faux',
              isMatricule: 'faux',
              isPassword: 'faux',
           })

           console.log('data.result === false', this.state.isAll)
          }
          
        })
             .catch((error)=> {
              console.log('Request failed in my Sign-In Home request', error)
          });
            }

            handleEnter(e) {
              if (e.charCode == 13) {
              this.handleSubmitSignIn();
              }
          }
            

    render(){
      let passwordButton;
      let matriculeButton;

      if (this.state.isUserExist){
        return <Redirect to="/Salaries"></Redirect>
      } 


      if ((this.state.isPassword==='vrai' && this.state.isMatricule ==='vrai') || (!this.state.isPassword && !this.state.isMatricule)){
        
        passwordButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="password">Votre mot de passe:</Label>
          <Input type="password" name="password" id="password" placeholder=""
          style={{width:"300px"}}
          value={this.state.password}
          onChange={(e) => this.setState({password: e.target.value})} 
          onKeyPress={this.handleEnter}
          required/>
        </FormGroup>

        matriculeButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="matricule" style={{textAlign:"left"}}>Votre identifiant:</Label>
          <Input type="text" name="matricule" id="matricule" placeholder=""
          style={{width:"300px"}}
          onChange={(e) => this.setState({matricule: e.target.value})} 
          required
        />
        </FormGroup>

      } else if (this.state.isPassword ==='faux' && this.state.isMatricule ==='vrai'){
        passwordButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="password">Votre mot de passe:</Label>
          <Input type="password" name="password" id="password" placeholder=""
          style={{width:"300px"}}
          value={this.state.password}
          onChange={(e) => this.setState({password: e.target.value})} 
          onKeyPress={this.handleEnter}
          invalid
          required/>
        </FormGroup>

        matriculeButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="matricule" style={{textAlign:"left"}}>Votre identifiant:</Label>
          <Input type="text" name="matricule" id="matricule" placeholder=""
          style={{width:"300px"}}
          onChange={(e) => this.setState({matricule: e.target.value})} 
          required
        />
        </FormGroup>

      } else if (this.state.isPassword ==='vrai' && this.state.isMatricule ==='faux'){
        passwordButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="password">Votre mot de passe:</Label>
          <Input type="password" name="password" id="password" placeholder=""
          style={{width:"300px"}}
          value={this.state.password}
          onChange={(e) => this.setState({password: e.target.value})} 
          onKeyPress={this.handleEnter}
          required/>
        </FormGroup>

        matriculeButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="matricule" style={{textAlign:"left"}}>Votre identifiant:</Label>
          <Input type="text" name="matricule" id="matricule" placeholder=""
          style={{width:"300px"}}
          onChange={(e) => this.setState({matricule: e.target.value})} 
          required
          invalid
        />
        </FormGroup>
      } else if (this.state.isAll === false){ 

        passwordButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="password">Votre mot de passe:</Label>
          <Input type="password" name="password" id="password" placeholder=""
          style={{width:"300px"}}
          value={this.state.password}
          onChange={(e) => this.setState({password: e.target.value})} 
          onKeyPress={this.handleEnter}
          invalid
          required/>
        </FormGroup>

        matriculeButton = 
        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
          <Label for="matricule" style={{textAlign:"left"}}>Votre identifiant:</Label>
          <Input type="text" name="matricule" id="matricule" placeholder=""
          style={{width:"300px"}}
          onChange={(e) => this.setState({matricule: e.target.value})} 
          invalid
          required
        />
        </FormGroup>
      } 


        return (

        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            
            <Row id={'hautpage'} className="justify-content-center text-center align-items-center" 
             style={{backgroundColor:"#E5F0FE", paddingTop:"40px"}}>
                 <Col>
                    <img src="./images/logo.png" alt="Avantages Services et Propreté"></img>
                 </Col>
            </Row>

            
            <Row className="justify-content-center text-center align-items-center" 
             style={{backgroundColor:"#E5F0FE"}}>
                 <Col>
                    <h1 style={{fontWeight:"bolder", paddingTop:"50px", fontSize:"50px", fontFamily:"Quicksand, 'sans-serif'"}}>
                      Portail des pointages des salariés</h1>
                 </Col>
            </Row>

            <Row className="justify-content-center align-items-center" 
             style={{height:"500px", backgroundColor:"#E5F0FE", textAlign:"right"}}>
                <Col md={5}>
                    <img src="./images/accueil2.jpg" height="300px" alt="accueil" style={{borderRadius:"8px"}}></img>
                </Col>

                <Col md={7}>
                    <Row className="justify-content-center text-center align-items-center">
                        <h1 style={{fontWeight:"bolder", paddingBottom:"20px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "24px"}}>
                            Connectez-vous </h1>
                    </Row>
                    <Form >
                    <Row className="justify-content-center text-center align-items-center">
                        {/* <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
                            <Label for="matricule" style={{textAlign:"left"}}>Votre identifiant:</Label>
                            <Input type="text" name="matricule" id="matricule" placeholder=""
                            style={{width:"300px"}}
                            onChange={(e) => this.setState({matricule: e.target.value})} 
                            required
                           />
                            </FormGroup> */}
                            {matriculeButton}
                    </Row>

                    <Row className="justify-content-center text-center align-items-center">
                        {/* {this.state.isPassword===false?(
                        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
                            <Label for="password">Votre mot de passe:</Label>
                            <Input type="password" name="password" id="password" placeholder=""
                            style={{width:"300px"}}
                            onChange={(e) => this.setState({password: e.target.value})} 
                            required/>
                        </FormGroup>
                        ):(
                        <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
                          <Label for="password">Votre mot de passe:</Label>
                          <Input type="password" name="password" id="password" placeholder=""
                          style={{width:"300px"}}
                          invalid
                          onChange={(e) => this.setState({password: e.target.value})} 
                          required/>
                      </FormGroup>
                      )} */}
                      {passwordButton}
                      {/* <FormGroup style={{textAlign:"left", fontFamily:"Quicksand, 'sans-serif'", fontSize:"16px"}}>
                            <Label for="password">Votre mot de passe:</Label>
                            <Input type="password" name="password" id="password" placeholder=""
                            style={{width:"300px"}}
                            onChange={(e) => this.setState({password: e.target.value})} 
                            onKeyPress={this.handleEnter}
                            required/>
                        </FormGroup> */}
                    </Row>
                    <Row className="justify-content-center text-center align-items-center">
                      <FormGroup>
                        <Button style={{ fontSize: "14px", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bolder",backgroundColor:"#0B4E9D"}}
                            
                            onClick={()=> {console.log(this.state.password);
                              this.handleSubmitSignIn()}}>
                              Entrer
                          </Button>
                      </FormGroup> 
                    </Row>
                    </Form>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
  return {
    saveId: function(id) {
        dispatch( {type: 'connect',
         id,
        } );
    }
  }
}

export default connect(
    null, 
    mapDispatchToProps
)(Homepage);