import React, { Fragment } from "react";
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import ip from './ipconfig';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Input, Button} from 'reactstrap';
import {connect} from 'react-redux';

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";



const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};





class DemandesAcomptes extends React.Component {

    constructor() {
      super();
      this.handleSubmitDemandeModifierAcompte = this.handleSubmitDemandeModifierAcompte.bind(this)
      this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);
      this.handleSubmitConfirmSup = this.handleSubmitConfirmSup.bind(this)

      this.state={
        allDemandes:[],
        visibleFilters: [],
        isClick: false,
        isClick1: false,
      }};
      
    
      componentDidMount(){
        let ctx=this;
    
        fetch(`${ip}/demandesAcomptes`)
        .then(function(res, err){
            return res.json()
          }).then((data)=> {
            ctx.setState({
              allDemandes: data.message,
            })
            console.log(ctx.state.allDemandes)
          })
          .catch((error)=> {
              console.log('Request failed', error)
          });
            }

      toggleFilter = id => {
        this.setState(({ visibleFilters }) => {
          let update = [...visibleFilters];
          const index = update.indexOf(id);
          index < 0 ? update.push(id) : update.splice(index, 1);
          return { visibleFilters: update };
        });
      };


      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
    if (typeof content !== 'undefined') {
    
        if (filter.value.length < 4) {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
            }
        } else {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
    }}

      
      getTrPropsType(state, rowInfo, column) {
        const { original } = rowInfo;
        const { color } = original;    
        return {
         
            style: {
                borderColor: color
            },
        }
      }


      handleSubmitDemandeModifierAcompte(data){
        let ctx=this;
    
        fetch(`${ip}/demandesModifAcompte?idAcompte=${data._id}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=>{
            ctx.props.saveId(data.message._id)
            // console.log("tableaudemandeAcompte", data.message._id)
            ctx.setState ({
                isClick:true,
              })
            }
          ) 
          .catch((error)=> {
            console.log('Request failed in my tableaudemandeAcompte request', error)
        });
      }
  
      handleSubmitConfirmSup(data){
        let ctx=this;
    
        fetch(`${ip}/demandesModifAcompte?idAcompte=${data._id}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=>{
            ctx.props.saveId(data.message._id)
            // console.log("tableaudemandeAcompte", data.message._id)
            ctx.setState ({
                isClick1:true,
              })
            }
          ) 
          .catch((error)=> {
            console.log('Request failed in my tableaudemandeAcompte request', error)
        });
      }

    

    render(){

    var demandesList = [];
    var demandesListCopy=[...this.state.allDemandes]
    
    demandesList = demandesListCopy.map((data,i) =>{
    return  (  
      {
        matricule: data.matricule,
        nom: data.nom,
        idAcompte: data._id,
        prenom: data.prenom,
        charge:{nomCharge: data.nomCharge, prenomCharge: data.prenomCharge},
        date: data.date,
        montantAccompte: data.montantAccompte,
        etat: data.etat,
        voir: <Button style={{backgroundColor:"white", border:"none"}} 
            onClick={()=>{
              this.handleSubmitDemandeModifierAcompte(data) 
            }}>
        <img src="./images/oeil.png" height="30px" /></Button>,
        supprimer: <Button style={{backgroundColor:"white", border:"none"}} 
          onClick={()=>{
            this.handleSubmitConfirmSup(data) 
        }}>
      <img src="./images/annulation.png" height="25px" /></Button>
      }
      )})
        

      if (this.state.isClick){
        return < Redirect to="/ModifDemandeAcompte" />
      }
  
      if (this.state.isClick1){
        return < Redirect to="/DemandeAcompteConfirmSup" />
      }
  
  

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"80px", fontFamily:"Quicksand, 'sans-serif'"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Demandes d'acomptes</h1>
                </Col>
            </Row>
  

            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
              <ReactTable
                previousText="Précédent"
                nextText="Suivant"
                rowsText="lignes"
                ofText= 'à'
                defaultFilterMethod={this.filterCaseInsensitive}
                data={demandesList}
                style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
                resolveData={data => data.map(row => row)} 
                columns={[
                  {
                    columns: [
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),        
                        label: 'Date',    
                        accessor: "date",
                        filterable: true,
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 100,
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Matricule",
                        accessor: "matricule",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto',},
                        minWidth: 110,
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                            
                          />
                        ),
                        label: "Nom",
                        accessor: "nom",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto'},
                        minWidth: 200
                      },
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Prénom",
                        accessor: "prenom",
                        filterable: true,
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 200,
                        getProps: (state, rowInfo, column) => {
                          return {
                              style: {
                                
                              },
                          };
                      },

                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Somme demandée</div>,
                        label: "Somme",
                        accessor: 'montantAccompte',
                        Cell     : row => {
                          return (
                            <div>
                              {row.row.montantAccompte} €
                            </div>
                          )
                        },
                        style:{fontWeight: 'bold', margin: 'auto',},
                        minWidth: 120,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', }}>Etat</div>,
                        accessor: "etat",
                        getProps: (state, rowInfo, column) => {
                          return {
                              style: {
                                  color: rowInfo && rowInfo.row.etat ==="En cours de traitement" ? 'red' : null,
                              },
                          };
                      },

                        style:{fontWeight: 'bolder', margin: 'auto'},
                        minWidth: 150,                      
                    },
                    {
                      Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Voir</div>,
                      accessor: "voir",
                      minWidth: 80,
                    },
                    {
                      Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Supprimer</div>,
                      accessor: "supprimer",
                      minWidth: 80,
                    }
                    ],
                    
                  },
                 
                 
                ]
                
                }
                getTheadFilterProps={(state, rowInfo, column, instance) => {
                  return {
                    style:
                      this.state.visibleFilters.length === 0
                        ? { display: "none" }
                        : null
                  };
                }}
                getTheadFilterThProps={(state, rowInfo, column, instance) => {
                  return {
                    className:
                      this.state.visibleFilters.indexOf(column.id) < 0
                        ? "hiddenFilter"
                        : null
                  };
                }}
                defaultPageSize={10}
                getTrPropsType= {this.getTrPropsType}
                className="-striped -highlight justify-content-center text-center align-items-center "
              />
          </Row>

            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {

  return { messageAcompteIdfromStore: state.messageAcompteId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMessageAcompte) {
        dispatch( {type: 'connect4',
         idMessageAcompte,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(DemandesAcomptes);