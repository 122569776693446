import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class AddMateriel extends Component {

    constructor(props){
        super(props);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.state = ({     
            nomSite: '',
            adresseSite: '',
            nomMateriel: '',
            marque: '',
            dateAchat: '',
            remarque: '',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/listeMaterielsParSite?idSite=${this.props.siteInfoIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nomSite: data.site.nomSite,
                adresseSite: data.site.adresseSite
              })
            })
            .catch((error)=> {
                console.log('Request failed in my SignalerReparation', error)
            });
          }
        

handleSubmitUpdate() {
    let ctx = this;

    fetch(`${ip}/addMateriel?idSite=${ctx.state.siteInfoIdfromStore}`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `idSite=${ctx.props.siteInfoIdfromStore}&nomSite=${ctx.state.nomSite}&nomMateriel=${ctx.state.nomMateriel}&dateAchat=${ctx.state.dateAchat}&remarque=${ctx.state.remarque}&marque=${ctx.state.marque}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            isClick: true                   
           })
        })
        .catch((error)=> {
            console.log('Request failed in my /miseAjourPlanningSite request', error)
        });
    }
        
    
    render(){
      let button;

        if (this.state.isClick){
            return < Redirect to="/ValidationAddMateriel" />
          }

        
        if (this.state.nomMateriel==="" || this.state.marque===""){
          button =
          <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
            onClick={()=>{ }}
            disabled>
            Valider
          </Button>

        } else {
          button =
          <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
            onClick={()=>{this.handleSubmitUpdate()}}>
            Valider
          </Button>
        }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Nouveau matériel</h1>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginBottom:"40px", fontSize:"14px"}}> 
                <Col style={{textAlign:"center"}}>
                  SITE:                   
                  <span style={{paddingLeft:"5px", paddingRight:"30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                    {this.state.nomSite}
                  </span>
                  ADRESSE:                   
                    <span style={{paddingLeft:"5px", paddingRight: "30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                      {this.state.adresseSite}
                    </span>
                </Col>
            </Row>

            <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>
                <Row className="justify-content-center align-items-center" 
                style={{paddingBottom:"60px"}} form>             
                    <Col className="justify-content-center align-items-center" md={6}>
                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="nomMateriel" style={{fontWeight: 'bolder'}}>Matériel:</Label>
                                <Input type="text" name="nomMateriel" id="nomMateriel" rows={1}
                                placeholder=""
                                onChange={(e) => {this.setState({nomMateriel: e.target.value});}} />
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="marque" style={{fontWeight: 'bolder'}}>Marque:</Label>
                                <Input type="text" name="marque" id="marque" rows={1}
                                placeholder=""
                                onChange={(e) => {this.setState({marque: e.target.value});}} />
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="dateAchat" style={{fontWeight: 'bolder'}}>Date d'achat:</Label>
                                <Input type="date" name="dateAchat" id="dateAchat" rows={1}
                                    placeholder=""
                                    onChange={(e) => {this.setState({dateAchat: e.target.value}); }} />
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="remarque" style={{fontWeight: 'bolder'}}>Remarque:</Label>
                                <Input type="textarea" name="remarque" id="remarque" 
                                placeholder=""
                                onChange={(e) => {this.setState({remarque: e.target.value})}}/>
                            </FormGroup>

                            <Row className="justify-content-center text-center align-items-center">
                                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                    <Link to="/ListematerielsParSite" style={{color:"white"}}>Annuler</Link></Button>
                                {button}
                            </Row>
                        
                    </Col>
                </Row>
            </Form>

            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    console.log("TableauListeSitesMateriels", state)
    console.log('je recois de mon reducer ID du message suivant de TableauListeSitesMateriels : ',state.siteInfoId)
  
  
    return { siteInfoIdfromStore: state.siteInfoId }
  }
  
  
  function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idSiteInfo) {
          dispatch( {type: 'connect5',
           idSiteInfo,
          } );
       }
      }
    }
    
    export default connect(
      mapStatetoProps, 
      mapDispatchToProps
    )(AddMateriel);