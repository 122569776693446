import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import ip from './ipconfig';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Input, Button} from 'reactstrap';


class SuccesDeleteDemandeCp extends Component {   

    render(){

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" 
             style={{height:"500px", backgroundColor:"#E5F0FE", textAlign:"right"}}>
                <Col md={5}>
                    <img src="./images/succes.jpg" height="300px" alt="Enregistrement réussi" style={{borderRadius:"8px"}}></img>
                </Col>

                <Col md={7}>
                    <Row className="justify-content-center text-center align-items-center">
                        <h1 style={{fontWeight:"bolder", paddingBottom:"20px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "24px"}}>
                        La demande de congés a bien été supprimée !
                        </h1>
                    </Row>
                    <Row className="justify-content-center text-center align-items-center">
                        <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                            <Link to="/DemandesConges" style={{color:"white"}}>Retour</Link>
                        </Button>
                    </Row>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

export default SuccesDeleteDemandeCp;