import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';

import moment from 'moment';
import 'moment/locale/fr'



class MiseAjourPlanningSite extends Component {

    constructor(props){
        super(props);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.state = ({     
            nomSite:'',
            nomCharge: '',
            prenomCharge: '',
            adresseSite: '',
            nomClientSite: '',
            prenomClientSite: '',
            numeroTelClientSite: '',
            lundiArriveeTheorie: '',
            lundiDepartTheorie: '',
            mardiArriveeTheorie: '',
            mardiDepartTheorie: '',
            mercrediArriveeTheorie: '',
            mercrediDepartTheorie: '',
            jeudiArriveeTheorie: '',
            jeudiDepartTheorie: '',
            vendrediArriveeTheorie: '',
            vendrediDepartTheorie: '',
            samediArriveeTheorie: '',
            samediDepartTheorie: '',
            dimancheArriveeTheorie: '',
            dimancheDepartTheorie: '',
            nbreHeureLundiTheorie: '',
            nbreHeureMardiTheorie: '',
            nbreHeureMercrediTheorie: '',
            nbreHeureJeudiTheorie: '',
            nbreHeureVendrediTheorie: '',
            nbreHeureSamediTheorie: '',
            nbreHeureDimancheTheorie: '',
            nbreHeureSemaine: '',
            nbreHeureMois:'',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/infoPlanningAmettreAjour?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                matricule: data.user.matricule,
                nom: data.user.nom,
                prenom: data.user.prenom,
                lundiArriveeTheorie: data.site.lundiArriveeTheorie,
                lundiDepartTheorie: data.site.lundiDepartTheorie,
                mardiArriveeTheorie:data.site.mardiArriveeTheorie,
                mardiDepartTheorie: data.site.mardiDepartTheorie,
                mercrediArriveeTheorie:data.site.mercrediArriveeTheorie,
                mercrediDepartTheorie: data.site.mercrediDepartTheorie,
                jeudiArriveeTheorie:data.site.jeudiArriveeTheorie,
                jeudiDepartTheorie: data.site.jeudiDepartTheorie,
                vendrediArriveeTheorie:data.site.vendrediArriveeTheorie,
                vendrediDepartTheorie: data.site.vendrediDepartTheorie,
                samediArriveeTheorie:data.site.samediArriveeTheorie,
                samediDepartTheorie: data.site.samediDepartTheorie,
                dimancheArriveeTheorie:data.site.dimancheArriveeTheorie,
                dimancheDepartTheorie: data.site.dimancheDepartTheorie,
                nbreHeureLundiTheorie: data.site.nbreHeureLundiTheorie,
                nbreHeureMardiTheorie: data.site.nbreHeureMardiTheorie,
                nbreHeureMercrediTheorie: data.site.nbreHeureMercrediTheorie,
                nbreHeureJeudiTheorie: data.site.nbreHeureJeudiTheorie,
                nbreHeureVendrediTheorie: data.site.nbreHeureVendrediTheorie,
                nbreHeureSamediTheorie: data.site.nbreHeureSamediTheorie,
                nbreHeureDimancheTheorie: data.site.nbreHeureDimancheTheorie, 
                nbreHeureSemaine: data.site.nbreHeureSemaine,
                nbreHeureMois: data.site.nbreHeureMois,   
                nomSite: data.site.nomSite,
                adresseSite: data.infoSite.adresseSite,
                nomClientSite: data.infoSite.nomClientSite,
                prenomClientSite: data.infoSite.prenomClientSite,
                numeroTelClientSite: data.infoSite.numeroTelClientSite,
                nomCharge: data.user.nomCharge,
                prenomCharge: data.user.prenomCharge
              })
            })
            .catch((error)=> {
                console.log('Request failed in my MiseAjourPlanningSite', error)
            });
          }
        

handleSubmitUpdate() {
    let ctx = this;

    var diffLundi =((moment(this.state.lundiDepartTheorie, "HH:mm")).diff(moment(this.state.lundiArriveeTheorie, "HH:mm"), "minutes"))/60
    var diffMardi =((moment(this.state.mardiDepartTheorie, "HH:mm")).diff(moment(this.state.mardiArriveeTheorie, "HH:mm"), "minutes"))/60
    var diffMercredi =((moment(this.state.mercrediDepartTheorie, "HH:mm")).diff(moment(this.state.mercrediArriveeTheorie, "HH:mm"), "minutes"))/60
    var diffJeudi =((moment(this.state.jeudiDepartTheorie, "HH:mm")).diff(moment(this.state.jeudiArriveeTheorie, "HH:mm"), "minutes"))/60
    var diffVendredi =((moment(this.state.vendrediDepartTheorie, "HH:mm")).diff(moment(this.state.vendrediArriveeTheorie, "HH:mm"), "minutes"))/60
    var diffSamedi =((moment(this.state.samediDepartTheorie, "HH:mm")).diff(moment(this.state.samediArriveeTheorie, "HH:mm"), "minutes"))/60
    var diffDimanche =((moment(this.state.dimancheDepartTheorie, "HH:mm")).diff(moment(this.state.dimancheArriveeTheorie, "HH:mm"), "minutes"))/60
    
    var diffLundiDecimale = Math.round(diffLundi*10)/10
    var diffMardiDecimale = Math.round(diffMardi*10)/10
    var diffMercrediDecimale = Math.round(diffMercredi*10)/10
    var diffJeudiDecimale = Math.round(diffJeudi*10)/10
    var diffVendrediDecimale = Math.round(diffVendredi*10)/10
    var diffSamediDecimale = Math.round(diffSamedi*10)/10
    var diffDimancheDecimale = Math.round(diffDimanche*10)/10

    var nbreHeureSemaineDecimale = diffLundiDecimale + diffMardiDecimale + diffMercrediDecimale + diffJeudiDecimale + diffVendrediDecimale + diffSamediDecimale + diffDimancheDecimale
    var nbreHeureMoisDecimale = Math.round((nbreHeureSemaineDecimale * 52/12)*10)/10 


    fetch(`${ip}/miseAjourPlanningSite?`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `nomCharge=${ctx.state.nomCharge}&prenomCharge=${ctx.state.prenomCharge}&adresseSite=${ctx.state.adresseSite}&nom=${ctx.state.nom}&prenom=${ctx.state.prenom}&matricule=${ctx.state.matricule}&idSite=${this.props.siteIdfromStore}&lundiArriveeTheorie=${this.state.lundiArriveeTheorie}&lundiDepartTheorie=${this.state.lundiDepartTheorie}&mardiArriveeTheorie=${this.state.mardiArriveeTheorie}&mardiDepartTheorie=${this.state.mardiDepartTheorie}&mercrediArriveeTheorie=${this.state.mercrediArriveeTheorie}&mercrediDepartTheorie=${this.state.mercrediDepartTheorie}&jeudiArriveeTheorie=${this.state.jeudiArriveeTheorie}&jeudiDepartTheorie=${this.state.jeudiDepartTheorie}&vendrediArriveeTheorie=${this.state.vendrediArriveeTheorie}&vendrediDepartTheorie=${this.state.vendrediDepartTheorie}&samediArriveeTheorie=${this.state.samediArriveeTheorie}&samediDepartTheorie=${this.state.samediDepartTheorie}&dimancheArriveeTheorie=${this.state.dimancheArriveeTheorie}&dimancheDepartTheorie=${this.state.dimancheDepartTheorie}&nomClientSite=${ctx.state.nomClientSite}&prenomClientSite=${ctx.state.prenomClientSite}&numeroTelClientSite=${ctx.state.numeroTelClientSite}&nbreHeureLundiTheorie=${diffLundiDecimale}&nbreHeureMardiTheorie=${diffMardiDecimale}&nbreHeureMercrediTheorie=${diffMercrediDecimale}&nbreHeureJeudiTheorie=${diffJeudiDecimale}&nbreHeureVendrediTheorie=${diffVendrediDecimale}&nbreHeureSamediTheorie=${diffSamediDecimale}&nbreHeureDimancheTheorie=${diffDimancheDecimale}&nbreHeureSemaine=${nbreHeureSemaineDecimale}&nbreHeureMois=${nbreHeureMoisDecimale}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            lundiArriveeTheorie: data.site.lundiArriveeTheorie,
            lundiDepartTheorie: data.site.lundiDepartTheorie,
            mardiArriveeTheorie:data.site.mardiArriveeTheorie,
            mardiDepartTheorie: data.site.mardiDepartTheorie,
            mercrediArriveeTheorie:data.site.mercrediArriveeTheorie,
            mercrediDepartTheorie: data.site.mercrediDepartTheorie,
            jeudiArriveeTheorie:data.site.jeudiArriveeTheorie,
            jeudiDepartTheorie: data.site.jeudiDepartTheorie,
            vendrediArriveeTheorie:data.site.vendrediArriveeTheorie,
            vendrediDepartTheorie: data.site.vendrediDepartTheorie,
            samediArriveeTheorie:data.site.samediArriveeTheorie,
            samediDepartTheorie: data.site.samediDepartTheorie,
            dimancheArriveeTheorie:data.site.dimancheArriveeTheorie,
            dimancheDepartTheorie: data.site.dimancheDepartTheorie,
            nbreHeureLundiTheorie: data.site.nbreHeureLundiTheorie,
            nbreHeureMardiTheorie: data.site.nbreHeureMardiTheorie,
            nbreHeureMercrediTheorie: data.site.nbreHeureMercrediTheorie,
            nbreHeureJeudiTheorie: data.site.nbreHeureJeudiTheorie,
            nbreHeureVendrediTheorie: data.site.nbreHeureVendrediTheorie,
            nbreHeureSamediTheorie: data.site.nbreHeureSamediTheorie,
            nbreHeureDimancheTheorie: data.site.nbreHeureDimancheTheorie,
            isClick: true,
           })
        })
        .catch((error)=> {
            console.log('Request failed in my /miseAjourPlanningSite request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationMajPlanningSites" />
          }

          var diffLundi =((moment(this.state.lundiDepartTheorie, "HH:mm")).diff(moment(this.state.lundiArriveeTheorie, "HH:mm"), "minutes"))/60
          var diffMardi =((moment(this.state.mardiDepartTheorie, "HH:mm")).diff(moment(this.state.mardiArriveeTheorie, "HH:mm"), "minutes"))/60
          var diffMercredi =((moment(this.state.mercrediDepartTheorie, "HH:mm")).diff(moment(this.state.mercrediArriveeTheorie, "HH:mm"), "minutes"))/60
          var diffJeudi =((moment(this.state.jeudiDepartTheorie, "HH:mm")).diff(moment(this.state.jeudiArriveeTheorie, "HH:mm"), "minutes"))/60
          var diffVendredi =((moment(this.state.vendrediDepartTheorie, "HH:mm")).diff(moment(this.state.vendrediArriveeTheorie, "HH:mm"), "minutes"))/60
          var diffSamedi =((moment(this.state.samediDepartTheorie, "HH:mm")).diff(moment(this.state.samediArriveeTheorie, "HH:mm"), "minutes"))/60
          var diffDimanche =((moment(this.state.dimancheDepartTheorie, "HH:mm")).diff(moment(this.state.dimancheArriveeTheorie, "HH:mm"), "minutes"))/60
          
          var diffLundiDecimale = Math.round(diffLundi*10)/10
          var diffMardiDecimale = Math.round(diffMardi*10)/10
          var diffMercrediDecimale = Math.round(diffMercredi*10)/10
          var diffJeudiDecimale = Math.round(diffJeudi*10)/10
          var diffVendrediDecimale = Math.round(diffVendredi*10)/10
          var diffSamediDecimale = Math.round(diffSamedi*10)/10
          var diffDimancheDecimale = Math.round(diffDimanche*10)/10
      
          var nbreHeureSemaineDecimale = diffLundiDecimale + diffMardiDecimale + diffMercrediDecimale + diffJeudiDecimale + diffVendrediDecimale + diffSamediDecimale + diffDimancheDecimale
          var nbreHeureMoisDecimale = Math.round((nbreHeureSemaineDecimale * 52/12)*10)/10 
      


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Mettre à jour le planning </h1>
                </Col>
            </Row>

            <Row className="justify-content-center  " 
             style={{ height:"50px", textAlign:"left"}}>
                <Col md={6}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal"}}>SITE: </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.nomSite}</span> 
                </Col>
            </Row>

            <Row className="justify-content-center" 
             style={{ height:"50px", textAlign:"left"}}>
                <Col md={6}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal"}}>ADRESSE: </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.state.adresseSite}</span> 
                </Col>
            </Row>


            <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>
                <Row className="justify-content-center align-items-center" 
                style={{paddingBottom:"60px"}} form>             
                    <Col className="justify-content-center align-items-center" md={9}>
                        
                        <Row className="justify-content-center align-items-center" style={{marginTop:"40px"}} >
                            <Col style={{fontWeight:"bolder", textAlign:"center"}} className="titre">PLANNING
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"40px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                                LUNDI:
                            </Col>
                       
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="lundiArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>
                                        Arrivée:
                                    </Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="lundiArriveeTheorie" id="lundiArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => {this.setState({lundiArriveeTheorie: e.target.value}); console.log(e.target.value)}} value={this.state.lundiArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="lundiDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>
                                        Départ:
                                    </Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="lundiDepartTheorie" id="lundiDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({lundiDepartTheorie: e.target.value})} value={this.state.lundiDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffLundiDecimale}
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"20px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                                MARDI:
                            </Col>
                       
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="mardiArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Arrivée:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="mardiArriveeTheorie" id="mardiArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({mardiArriveeTheorie: e.target.value})} value={this.state.mardiArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="mardiDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Départ:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="mardiDepartTheorie" id="mardiDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({mardiDepartTheorie: e.target.value})} value={this.state.mardiDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffMardiDecimale}
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"20px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                                MERCREDI:
                            </Col>
                       
                            <Col  style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="mercrediArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Arrivée:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="mercrediArriveeTheorie" id="mercrediArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({mercrediArriveeTheorie: e.target.value})} value={this.state.mercrediArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="mercrediDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Départ:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="mercrediDepartTheorie" id="mercrediDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({mercrediDepartTheorie: e.target.value})} value={this.state.mercrediDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffMercrediDecimale}
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"20px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                                JEUDI:
                            </Col>
                       
                            <Col  style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="jeudiArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Arrivée:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="jeudiArriveeTheorie" id="jeudiArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({jeudiArriveeTheorie: e.target.value})} value={this.state.jeudiArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="jeudiDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Départ:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="jeudiDepartTheorie" id="jeudiDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({jeudiDepartTheorie: e.target.value})} value={this.state.jeudiDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffJeudiDecimale}
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"20px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                                VENDREDI:
                            </Col>
                       
                            <Col  style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="vendrediArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Arrivée:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="vendrediArriveeTheorie" id="vendrediArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({vendrediArriveeTheorie: e.target.value})} value={this.state.vendrediArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="vendrediDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Départ:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="vendrediDepartTheorie" id="vendrediDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({vendrediDepartTheorie: e.target.value})} value={this.state.vendrediDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffVendrediDecimale}
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"20px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                               SAMEDI:
                            </Col>
                       
                            <Col  style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="samediArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Arrivée:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="samediArriveeTheorie" id="samediArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({samediArriveeTheorie: e.target.value})} value={this.state.samediArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="samediDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Départ:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="samediDepartTheorie" id="samediDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({samediDepartTheorie: e.target.value})} value={this.state.samediDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffSamediDecimale}
                            </Col>
                        </Row>


                        <Row form className="justify-content-center align-items-center" style={{marginTop:"20px"}} >
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                                DIMANCHE:
                            </Col>
                       
                            <Col  style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="dimancheArriveeTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Arrivée:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="dimancheArriveeTheorie" id="dimancheArriveeTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({dimancheArriveeTheorie: e.target.value})} value={this.state.dimancheArriveeTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col style={{verticalAlign: "middle", display:"table"}} className="justify-content-center text-center align-items-center">
                                <FormGroup row className="justify-content-center align-items-center" >
                                    <Label for="dimancheDepartTheorie" className="justify-content-center align-items-center" style={{verticalAlign: "middle", display:"table-cell"}}>Départ:</Label>
                                <Col style={{verticalAlign: "middle"}}>
                                    <Input type="time" className="justify-content-center text-center align-items-center" name="dimancheDepartTheorie" id="dimancheDepartTheorie" placeholder="" style={{width:"100px", height:"35px"}}
                                    onChange={(e) => this.setState({dimancheDepartTheorie: e.target.value})} value={this.state.dimancheDepartTheorie}/>
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md={2} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                             Nbre d'heures:
                            </Col>
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"47px"}} >
                            {diffDimancheDecimale}
                            </Col>
                        </Row>

                        <Row form className="justify-content-center align-items-center" style={{marginTop:"40px"}} >
                            <Col md={4} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"20px"}} >
                                NBRE D'HEURES PAR SEMAINE: 
                            </Col>
                  
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"20px"}} >
                            {nbreHeureSemaineDecimale}
                            </Col>
                        </Row>
                        <Row form className="justify-content-center align-items-center" style={{marginTop:"40px"}} >
                            <Col md={4} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"20px"}} >
                                NBRE D'HEURES PAR MOIS: 
                            </Col>
                  
                            <Col md={1} style={{fontWeight:"bolder", textAlign:"left", verticalAlign: "middle", height:"20px"}} >
                            {nbreHeureMoisDecimale}
                            </Col>
                        </Row>


                        <Row className="justify-content-center text-center align-items-center" style={{marginTop: "40px"}}>
                            <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                <Link to="/SitesPerso" style={{color:"white"}}>Annuler</Link></Button>
                            <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                            onClick={()=>{console.log("clic"); this.handleSubmitUpdate()}}>Valider</Button>
                        </Row>
                        
                    </Col>
                </Row>
            </Form>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
  
    return { userIdfromStore: state.userId, siteIdfromStore: state.siteId }
  }
  
  
    export default connect(
      mapStatetoProps,
      null
      )(MiseAjourPlanningSite);