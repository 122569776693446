import React, {Component} from 'react';
import {Row, Col, Table, Container, Input, Button} from 'reactstrap';
import {connect} from 'react-redux'; 
import {Redirect, Link} from 'react-router-dom';
import './App.css';
import ip from './ipconfig';


 
class TableauSitesPrdts extends Component {
 
    constructor(props) {
        super(props);
        this.handleSubmitFeuillePrdts = this.handleSubmitFeuillePrdts.bind(this);

        this.state = {
            nomSite:this.props.nomSite,
            adresseSite: this.props.adresseSite,
            isClick:false,
            };
      }


  


      handleSubmitFeuillePrdts(){
        let ctx=this;
    
        fetch(`${ip}/tableauSitesPrdts?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}&nomSite=${ctx.state.nomSite}&adresseSite=${ctx.state.adresseSite}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=>{   
                ctx.props.saveId(data.site._id)
                console.log("feuille produits", data.site._id)
                ctx.setState ({
                    isClick:true,
                   
                 })
                }
              ) 
          .catch((error)=> {
            console.log('Request failed in my tableauSitesPrdts request', error)
        });
    }

 
    render() {

        if (this.state.isClick){
            console.log("CONDITION DU L AJOUT DE SITE:", this.state.isUserExist)
            return < Redirect to="/FeuilleSitesPrdts" />
          }

 
        return (

            <tr className="text-center">
            <th scope="row" style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}>{this.props.nomSite}</th>
            <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.adresseSite}</td>
            <td style={{width:"100px"}}><Button style={{backgroundColor:"white", border:"none"}} onClick={()=>{console.log('je suis dans le ttt'); this.handleSubmitFeuillePrdts() }}>
              <img src="./images/oeil.png" height="30px" /></Button></td>
            </tr>                 
       
        );
    }
}
 
function mapStatetoProps(state) {
    console.log("tableau pointage", state)
    console.log('je recois de mon reducer ID du user suivant de tableaupointage : ',state.userId)
    console.log('je recois de mon reducer ID du site suivant de tableaupointage : ',state.siteId)
  
  
    return { userIdfromStore: state.userId, siteIdfromStore: state.siteId }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idSite) {
        console.log('tableauSitesPrdts user ttt')
          dispatch( {type: 'connect1',
          idSite
          }
        )
      }
    }
  }
  
  
    export default connect(
      mapStatetoProps,
      mapDispatchToProps
      )(TableauSitesPrdts);