import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Table  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class SiteDetails extends Component {

    constructor(props){
        super(props);
        this.handleSubmitSite= this.handleSubmitSite.bind(this);
        this.state = ({     
            nomSite: '',
            nomCharge: '',
            prenomCharge: '',
            adresseSite: '',
            nomClientSite: '',
            prenomClientSite: '',
            numeroTelClientSite: '',
            emailClientSite:'',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/infoSiteAmettreAjour?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteInfoIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nomSite: data.site.nomSite,
                adresseSite: data.site.adresseSite,
                nomClientSite: data.site.nomClientSite,
                prenomClientSite: data.site.prenomClientSite,
                numeroTelClientSite: data.site.numeroTelClientSite,
                nomCharge: data.site.nomCharge,
                prenomCharge: data.site.prenomCharge,
                emailClientSite: data.site.emailClientSite
              })
            })
            .catch((error)=> {
                console.log('Request failed in my MaterielDetails', error)
            });
          }

   
        

          handleSubmitSite(){
            let ctx=this;
        
            fetch(`${ip}/modifSite?nomSite=${ctx.state.nomSite}&nomClientSite=${ctx.state.nomClientSite}&prenomClientSite=${ctx.state.prenomClientSite}&adresseSite=${ctx.state.adresseSite}&nomCharge=${ctx.state.nomCharge}&prenomCharge=${ctx.state.prenomCharge}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=>{
                ctx.props.saveId(data.site._id)
                console.log("TableauListeGeneralSites", data.site._id)
                ctx.setState ({
                    isClick:true,
                  })
                }
              ) 
              .catch((error)=> {
                console.log('Request failed in my TableauListeGeneralSites request', error)
            });
          }
              
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/MiseAjourSite" />
          }



        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Détails</h1>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Site: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", }}>{this.state.nomSite} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Adresse: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", }}> {this.state.adresseSite} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                   Client: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nomClientSite} {this.state.prenomClientSite}</span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    Numéro de téléphone: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.numeroTelClientSite} </span>
                </Col>
            </Row>
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "30px", fontSize: "15px"}}>
                    E-mail: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.emailClientSite} </span>
                </Col>
            </Row>

            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px", fontSize: "15px"}}>
                   Chargé de clientèle / Responsable: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nomCharge} {this.state.prenomCharge} </span>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" style={{ height:"160px"}}>
                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                    <Link to="/ListeSites" style={{color:"white"}}>Retour</Link>
                </Button>
                <Button 
                style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                onClick={this.handleSubmitSite}>
                    Mettre à jour
                </Button>
            </Row>

            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
    return { userIdfromStore: state.userId, siteInfoIdfromStore: state.siteInfoId }
}
  
  
function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idSiteInfo) {
          dispatch( {type: 'connect5',
           idSiteInfo,
          } );
       }
      }
    }
    
    export default connect(
      mapStatetoProps, 
      mapDispatchToProps
    )(SiteDetails);