import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label, Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import moment from 'moment';
import 'moment/locale/fr'



class TraitementAnomalie extends Component {

    constructor(props){
        super(props);
        this.handleSubmitAnomalieTraitee = this.handleSubmitAnomalieTraitee.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.pickImageOrdi = this.pickImageOrdi.bind(this)
        // this.uploadPhoto = this.uploadPhoto.bind(this)

        this.state = ({     
            nomSite: "",
            matricule: "",
            nom: "",
            prenom: "",
            nomCharge: "",
            prenomCharge: "",
            date: "",
            remarque: "",
            etat: "",
            dateTraitement: "",
            remarqueTraitement: "",
            photoAnomalie: "",
            photoAnomalieTraitement: "",
            isClick: false,
            loading: "",
            show: false,
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/anomalieAtraiter?idAnomalie=${ctx.props.anomalieIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                matricule: data.anomalieDetail.matricule,
                nom: data.anomalieDetail.nom, 
                prenom: data.anomalieDetail.prenom,
                nomSite: data.anomalieDetail.nomSite,
                date: data.anomalieDetail.date,
                dateTraitement: data.anomalieDetail.dateTraitement,
                remarque: data.anomalieDetail.remarque,
                nomCharge: data.anomalieDetail.nomCharge,
                prenomCharge: data.anomalieDetail.prenomCharge,
                typeAnomalie: data.anomalieDetail.typeAnomalie,
                photoAnomalie: data.anomalieDetail.photoAnomalie,
                photoAnomalieTraitement: data.anomalieDetail.photoAnomalieTraitement,
                remarqueTraitement: data.anomalieDetail.remarqueTraitement
              })
            })
            .catch((error)=> {
                console.log('Request failed in my traitementAnomalie', error)
            });
          }
        

          handleShow(){
            this.setState({
                show:!this.state.show,
            })
          }
          
          handleClose(){
            this.setState({
                show: false,
            })
          }

    

        //   async pickImageOrdi (e) {
            
        //     const files = e.target.files
        //     this.setState({loading: true})
        //       var data = new FormData();

        //       data.append('file', files[0])
                
        
        //       console.log ('data', data)
        
        //       await fetch(`https://api.cloudinary.com/v1_1/dtqklb6nk/image/upload`, {
        //         method: 'POST',
        //         headers: {
        //           'Content-Type': 'multipart/form-data',
        //         },
        //         body: data
        //       })
        //       .then((res) => {
        //         return res.json();
        //       })
        //       .then((data) => {
        //         console.log("data from cloudinary" + data.data)
        //         this.setState({
        //           photoAnomalieTraitement: data.data
        //         });
        //       })
        //       .catch((error) => {
        //           console.log(error)
        //       });
        //     }          
        
        pickImageOrdi = async(e) => {
            const files = e.target.files[0]
            console.log('files', files)
        
            
              const data = new FormData();
                data.append('traitementAnomalie', 
                  files
              );
              for (var pair of data.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
        
              await fetch(`${ip}/savePhotoTraitementFromOrdi?idAnomalie=${this.props.anomalieIdfromStore}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                body: data
              })
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                console.log("data from cloudinary" + data.data)
                this.setState({
                  photoAnomalieTraitement: data.data
                });
              })
              .catch((error) => {
                  console.log(error)
              });
            
          };
          

        //   async uploadPhoto(e){
        //     const files = e.target.files
        //     const data= new FormData()
        //     data.append('file', files[0])
        //     data.append('upload_preset', 'camille')
        //     this.setState({loading: true})
        //     const res = await fetch('https://api.cloudinary.com/v1_1/dtqklb6nk/', {
        //         method: 'POST',
        //         body: data
        //       })
        //     const file = await res.json()
            
        //     this.setState({photoAnomalieTraitement: file.secure_url})
        //     this.setState({loading: false})
        //   }
        

        handleSubmitAnomalieTraitee() {
            let ctx = this;

            fetch(`${ip}/miseAjourAnomalie`,{
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `photoAnomalieTraitement=${this.state.photoAnomalieTraitement}&dateTraitement=${ctx.state.dateTraitement}&remarqueTraitement=${ctx.state.remarqueTraitement}&idAnomalie=${ctx.props.anomalieIdfromStore}`
            })
                .then(function(res, err){
                return res.json()
                }).then((data)=> {
                ctx.setState({
                    etat: data.anomalieTraitee.etat,
                    remarque: data.anomalieTraitee.remarque,
                    isClick: true 
                })
                })
                .catch((error)=> {
                    console.log('Request failed in my handleSubmitAnomalieTraitee request', error)
                });
            }
                
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationAnomalieTraitee" />
          }


        let photoAnomalieView 
        let photoAnomalieTraitementView

        let dateTraitementView
        let remarqueTraitementView

        if(this.state.dateTraitement!==undefined || this.state.dateTraitement!==""){
            dateTraitementView = 
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontSize: "15px"}}>
                    Traitée le: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}>{this.state.dateTraitement}</span>
                </Col>
            </Row>
        } 
        else {
            dateTraitementView = 
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontSize: "15px"}}>
                    Traitée le: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}>/</span>
                </Col>
            </Row>
        }

        if(this.state.remarqueTraitement!==undefined || this.state.remarqueTraitement!==""){
            remarqueTraitementView = 
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontSize: "15px"}}>
                    Remarque: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}>{this.state.remarqueTraitement}</span>
                </Col>
            </Row>
        } 
        else {
           remarqueTraitementView = 
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontSize: "15px"}}>
                    Remarque: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}>/</span>
                </Col>
            </Row>
        }


        
        if(this.state.photoAnomalie===undefined || this.state.photoAnomalie===null){
            photoAnomalieView = 
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px", fontSize: "15px", fontStyle: "italic"}}>
                    Pas de photo
                </Col>
            </Row>
        } 
        else {
            photoAnomalieView = 
            <Row className="justify-content-center text-center">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px", fontSize: "15px"}}>
                    <img src={ this.state.photoAnomalie} width="150px" alt="Photo de l'anomalie" style={{borderRadius:"8px"}}></img>
                </Col>
            </Row>
        }

        if(this.state.photoAnomalieTraitement=="undefined" || this.state.photoAnomalieTraitement==null){
            photoAnomalieTraitementView = 
            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px", fontSize: "15px", fontStyle: "italic"}}>
                    Pas de photo
                </Col>
            </Row>
        } 
        else {
            photoAnomalieTraitementView = 
            <Row className="justify-content-center text-center">
                <Col className="justify-content-center" md={6} style={{marginBottom: "15px", fontSize: "15px"}}>
                    <img src={ this.state.photoAnomalieTraitement} width="150px" alt="Photo du traitement de l'anomalie" style={{borderRadius:"8px"}}></img>
                </Col>
            </Row>
        }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Anomalie</h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-left">
                <Col className="justify-content-center" md={6}>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontWeight: 'bold', fontFamily:"Quicksand, 'sans-serif'", fontSize: "16px", textDecoration:"underline"}}>
                            Description          
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px", fontSize: "15px"}}>
                            Site: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", }}>{this.state.day} {this.state.nomSite} </span>
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px", fontSize: "15px"}}>
                            Type d'anomalie: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.typeAnomalie} </span>
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px", fontSize: "15px"}}>
                            Détectée le: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.date} </span>
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px", fontSize: "15px"}}>
                            Par: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nom} {this.state.prenom} </span>(Matricule: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.matricule}</span>) 
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px", fontSize: "15px"}}>
                            Chargé de clientèle / Responsable: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px"}}>{this.state.nomCharge} {this.state.prenomCharge}</span>
                        </Col>
                    </Row>

                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px", fontSize: "15px"}}>
                            Photo de l'anomalie: 
                        </Col>
                    </Row>
                    {photoAnomalieView}

                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "50px", fontSize: "15px"}}>
                            Remarque: <span style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight: "bold", fontSize: "15px", fontSize: "15px"}}>{this.state.remarque}</span>
                        </Col>
                    </Row>
                </Col>

                <Col className="justify-content-center" md={6}>
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "50px", fontWeight: 'bold', fontFamily:"Quicksand, 'sans-serif'", fontSize: "16px", textDecoration:"underline"}}>
                            Traitement de l'anomalie           
                        </Col>
                    </Row>
                    
                    {dateTraitementView}

                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontSize: "15px"}}>
                            Photo du traitement de l'anomalie: 
                        </Col>
                    </Row>
                    
                    {photoAnomalieTraitementView} 

                    {remarqueTraitementView}
                    
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "20px", fontSize: "15px"}}>
                            <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}  
                                onClick={this.handleShow}>
                                    {!this.state.show ? 'Modifier' : 'Fermer'}
                            </Button>
                        </Col>
                    </Row>

                    {this.state.show && (
                                                        
                    <Row className="justify-content-center text-left">
                        <Col className="justify-content-center" md={10} style={{marginBottom: "15px"}}>
                            {/* <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder", fontSize: "15px" }} 
                                onClick={()=>{this.pickImageOrdi()}}>Télécharger une photo</Button>
                                <input type="file" name="traitementAnomalie" id= "traitementAnomalie"
                                    placeholder=""
                                    onChange={this.pickImageOrdi} 
                                    /> 
                                    {this.state.loading ? (
                                    <h6> Chargement ...</h6>
                                    ) : (null)}
                                    {this.state.loading === false? (
                                        <Button className="btn-warning" style={{color:"white"}} onClick={this.updatePhoto}> Valider </Button>
                                    ):(null)} */}

                            <Form className="justify-content-center text-left">

                                <FormGroup row style={{marginBottom: "30px"}}>
                                    <Label for="dateTraitement" md={3}>Traitée le:</Label>
                                    <Col md={9}>
                                        <Input type="date" name="dateTraitement" id="dateTraitement" 
                                        placeholder=""
                                        onChange={(e) => {this.setState({
                                            dateTraitement: moment(e.target.value,"YYYY-MM-DD").format("DD/MM/YYYY") });
                                        }} 
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label for="remarque" md={3}>Remarques:</Label>
                                    <Col md={9}>
                                    <Input type="textarea" name="remarque" id="remarque" 
                                    placeholder=""
                                    onChange={(e) => {this.setState({remarqueTraitement: e.target.value})}} value={this.state.remarqueTraitement}/>
                                    </Col>
                                </FormGroup>      

                                <Row className="justify-content-center text-center align-items-center">
                                    <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                        <Link to="/ListeAnomaliesParSite" style={{color:"white"}}>Annuler</Link></Button>
                                    <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                                    onClick={()=>{this.handleSubmitAnomalieTraitee()}}>Valider</Button>
                                </Row>

                            </Form>
                        </Col>
                    </Row>)}


                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
  
    return { anomalieIdfromStore: state.anomalieId }
}
  
  
    export default connect(
      mapStatetoProps,
      null
      )(TraitementAnomalie);