export default function(idSiteInfo = '', action) {

  if(action.type == 'connect5') {
       console.log('Dans mon reducer --->',action.idSiteInfo)
       return action.idSiteInfo;
  } else {
      return idSiteInfo;
  }
}

