import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button, Modal, ModalFooter, ModalHeader,ModalBody } from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux'; 
import './App.css';
import ip from './ipconfig';


 
class TableauFeuilleSitesPrdtsPerso extends Component {
  constructor(props) {
      super(props);
      this.handleSubmitDemandeModifier = this.handleSubmitDemandeModifier.bind(this)
      this.toggle = this.toggle.bind(this);
      this.state = {
          modal: props.initialModalState,
          fade: true,
          isClick: false,
          nom: this.props.nom,
          nomSite: this.props.nomSite,
          nomCharge: this.props.nomCharge,
          prenomCharge: this.props.prenomCharge,
          listProduits: this.props.listProduits,
          prenom: this.props.prenom,
          etat: this.props.etat,
          date: this.props.date,
          matricule: this.props.matricule,
          remarque: this.props.remarque,
          dateTraitement: this.props.dateTraitement,
          _id: this.props._id
      };       
          
    }


    toggle() {
      this.setState({
          modal: !this.state.modal,
          fade: !this.state.fade
      });
  }

    handleSubmitDemandeModifier(){
      let ctx=this;
  
      fetch(`${ip}/demandesModifPrdts?idMessage=${ctx.state._id}&id=${ctx.props.userIdfromStore}&date=${ctx.state.date}&listProduits=${ctx.state.listProduits}&nomCharge=${ctx.props.nomCharge}&prenomCharge=${ctx.props.prenomCharge}&nomSite=${ctx.state.nomSite}`)
      .then(function(res, err){
        return res.json()
      }).then((data)=>{
          ctx.props.saveId(data.message._id)
          console.log("tableauFeuilleSitesPrdts", data.message._id)
          ctx.setState ({
              isClick:true,
            })
          }
        ) 
        .catch((error)=> {
          console.log('Request failed in my tableauFeuilleSitesPrdts request', error)
      });
        }

       


  render() {

    if (this.state.isClick){
      return < Redirect to="/ModifDemandePrdtsPerso" />
    }

    var dateTraitementDemande= this.state.dateTraitement
    var dateTraitementDemandeSplit = dateTraitementDemande.split('-')
    var dateTraitementDemandeJoin = dateTraitementDemandeSplit.join('/')

    var listPrdts = this.state.listProduits
    var listPrdtsSplit = listPrdts.split(',');
    var listPrdtsJoin = listPrdtsSplit.join(' - ')


    var button

    if (this.props.etat === "Commande acceptée"){
      button = <span style={{color: "green", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
          {this.props.etat}
        </span>
    } 
    else if (this.props.etat === "Commande refusée"){
      button= <span style={{color: "red", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
          {this.props.etat}
        </span>
    }
    else {
     button = <span style={{color: "orange", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
          {this.props.etat}
        </span>
    }



      return (
                    <tr className="text-center">
                      <th scope="row" style={{ fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}>{this.props.date}</th>
                      <td style={{ fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{listPrdtsJoin}</td>

                      <td style={{ fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{button}</td>
                      
                      <td><Button style={{backgroundColor:"white", border:"none"}} 
                        onClick={this.toggle} >
                        <img src="./images/oeil.png" height="30px" /></Button>
                        
                <Modal isOpen={this.state.modal} toggle={this.toggle}
                       fade={this.state.fade}
                       className={this.props.className}
                       >
                    <ModalHeader toggle={this.toggle} style={{fontWeight:"bolder", backgroundColor:"#EAF4FB", fontFamily:"Quicksand, 'sans-serif'", fontSize:"14px"}}>Détails de la demande</ModalHeader>
                    <ModalBody style={{display:"inline",  backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Date: 
                        <span style={{paddingLeft:"5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.date}
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Demandeur: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                           {this.props.nom} {this.props.prenom} (Matricule: {this.props.matricule})
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Site: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.nomSite}
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Chargé de clientèle / Responsable concerné: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.nomCharge} {this.props.prenomCharge}
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Liste des produits / matériel manquants: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {listPrdtsJoin}
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Etat: 
                          {button}
                    </ModalBody>

                    {this.props.etat !== "A commander" ? (
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Demande traitée le: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {dateTraitementDemandeJoin}
                        </span>
                    </ModalBody>) : (null)
                    }

                    {this.props.etat !== "A commander" ? (
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Remarques: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.remarque}
                        </span>
                    </ModalBody>) : (null)
                    }

                    <ModalFooter style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        <Button style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}
                         onClick={this.toggle}>Fermer</Button>
                        <Button style={{backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}
                        onClick={this.handleSubmitDemandeModifier}>Mettre à jour</Button>
                    </ModalFooter>
                </Modal></td>

                    </tr>                    
  
      );
  }
}

function mapStatetoProps(state) {
  console.log("tableauFeuilleSitesPrdtsPerso ", state)
  console.log('je recois de mon reducer ID du message suivant de tableauFeuilleSitesPrdtsPerso  : ',state.messageId)


  return { userIdfromStore: state.userId, messageIdfromStore: state.messageId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMessage) {
      console.log('ttt')
        dispatch( {type: 'connect2',
         idMessage,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(TableauFeuilleSitesPrdtsPerso);