import React, { Component }  from 'react';
import './styles.css';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Row, Col,  Container, Input } from 'reactstrap';



class Footer extends Component {
    render(){
        return (
            <Container className="justify-content-center text-center align-items-center" fluid={true} style={{backgroundColor:"#E5F0FE"}}>
                   
                <Row className="justify-content-center text-center align-items-center"> 
                    <h5 style={{fontSize:"12px", margin:"20px", fontFamily:"Quicksand, 'sans-serif'"}}>@2020 AVANTAGE Services et Propreté - Tous droits réservés </h5>
                </Row>
            
            </Container>
        )
    }
}

export default Footer;