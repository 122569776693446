import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Row, Col, Table, Container, Input, Button} from 'reactstrap';
import {connect} from 'react-redux'; 
import {Redirect, Link} from 'react-router-dom';
import './App.css';
import ip from './ipconfig';


 
class TableauSite extends Component {
 
    constructor(props) {
        super(props);
        this.handleSubmitMaj = this.handleSubmitMaj.bind(this);
        // this.handleSubmitFeuillePointage = this.handleSubmitFeuillePointage.bind(this);
        this.handleSubmitConfirmSup =  this.handleSubmitConfirmSup.bind(this)

        this.state = {
            nomSite:this.props.nomSite,
            adresseSite: this.props.adresseSite,
            nomClientSite: this.props.nomClientSite,
            prenomClientSite: this.props.prenomClientSite,
            numeroTelClientSite: this.props.numeroTelClientSite,
            lundiArriveeTheorie: this.props.lundiArriveeTheorie,
            lundiDepartTheorie: this.props.lundiDepartTheorie,
            mardiArriveeTheorie: this.props.mardiArriveeTheorie,
            mardiDepartTheorie: this.props.mardiDepartTheorie,
            mercrediArriveeTheorie: this.props.mercrediArriveeTheorie,
            mercrediDepartTheorie: this.props.mercrediDepartTheorie,
            jeudiArriveeTheorie: this.props.jeudiArriveeTheorie,
            jeudiDepartTheorie: this.props.jeudiDepartTheorie,
            vendrediArriveeTheorie: this.props.vendrediArriveeTheorie,
            vendrediDepartTheorie: this.props.vendrediDepartTheorie,
            samediArriveeTheorie: this.props.samediArriveeTheorie,
            samediDepartTheorie: this.props.samediDepartTheorie,
            dimancheArriveeTheorie: this.props.dimancheArriveeTheorie,
            dimancheDepartTheorie: this.props.dimancheDepartTheorie,
            nbreHeureLundiTheorie: this.props.nbreHeureLundiTheorie,
            nbreHeureMardiTheorie: this.props.nbreHeureMardiTheorie,
            nbreHeureMercrediTheorie: this.props.nbreHeureMercrediTheorie,
            nbreHeureJeudiTheorie: this.props.nbreHeureJeudiTheorie,
            nbreHeureVendrediTheorie: this.props.nbreHeureVendrediTheorie,
            nbreHeureSamediTheorie: this.props.nbreHeureSamediTheorie,
            nbreHeureDimancheTheorie: this.props.nbreHeureDimancheTheorie,
            nbreHeureSemaine: this.props.nbreHeureSemaine,
            nbreHeureMois: this.props.nbreHeureMois,
            isClick: false,
            isClickMaj: false,
            isClickSup: false,
            };
      }


      handleSubmitMaj(){
        let ctx=this;
    
        fetch(`${ip}/tableauPointageAmaj?id=${ctx.props.userIdfromStore}&nomSite=${ctx.state.nomSite}&adresseSite=${ctx.state.adresseSite}`)
        .then(function(res, err){
            return res.json()
          }).then((data)=>{   
                 ctx.props.saveId(data.site._id)
                  console.log("pointage", data.site._id)
                  this.setState ({
                      isClickMaj:true,
                   })
                  }
                ) 
            .catch((error)=> {
            console.log('Request failed in my tableauPointage request', error)
        });
    }
  


    // handleSubmitFeuillePointage(){
    //     let ctx=this;
    
    //     fetch(`${ip}/feuillePointage?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}&nomSite=${ctx.state.nomSite}&adresseSite=${ctx.state.adresseSite}`)
    //     .then(function(res, err){
    //       return res.json()
    //     }).then((data)=>{   
    //             ctx.props.saveId(data.site._id)
    //             console.log("pointage", data.site._id)
    //             ctx.setState ({
    //                 isClick:true,
                   
    //              })
    //             }
    //           ) 
    //       .catch((error)=> {
    //         console.log('Request failed in my tableauPointage request', error)
    //     });
    // }

    handleSubmitConfirmSup(){
        let ctx=this;
    
        fetch(`${ip}/feuillePointage?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}&nomSite=${ctx.state.nomSite}&adresseSite=${ctx.state.adresseSite}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=>{   
                ctx.props.saveId(data.site._id)
                console.log("pointage", data.site._id)
                ctx.setState ({
                    isClickSup:true,
                   
                 })
                }
              ) 
          .catch((error)=> {
            console.log('Request failed in my tableauPointage request', error)
        });
    }

    


 
    render() {
 
        if (this.state.isClick){
            return < Redirect to="/FeuillePointage" />
          }

        if (this.state.isClickMaj){
            return < Redirect to="/MiseAjourPlanningSite" />
          }

        if (this.state.isClickSup){
            return < Redirect to="/PlanningConfirmSup" />
          }


        return (
            <Container>

            <Row className="justify-content-center" style={{marginBottom:"20px", textAlign:"left"}}>
                <Col md={12}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal"}}>SITE: </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.props.nomSite}</span> 
                </Col>
            </Row>
            <Row className="justify-content-center" style={{marginBottom:"20px", textAlign:"left"}}>
                <Col md={12}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal", textAlign:"left"}}> ADRESSE:  </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.props.adresseSite}</span>      
                </Col>
            </Row>
            <Row className="justify-content-center" style={{marginBottom:"20px", textAlign:"left"}}>
                <Col md={12}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal", textAlign:"left"}}> CLIENT:  </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.props.nomClientSite} {this.props.prenomClientSite}</span>      
                </Col>
            </Row>
            <Row className="justify-content-center" style={{marginBottom:"20px", textAlign:"left"}}>
                <Col md={12}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal", textAlign:"left"}}> NUMERO DE TELEPHONE:  </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.props.numeroTelClientSite}</span>      
                </Col>
            </Row>



            <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"5px"}} >
                <Col className="justify-content-center align-items-center" md={12} >

                    <Table hover id="table-to-xls" className="tableau" >
                        <thead>
                            <tr style={{backgroundColor:"#C9DCF6", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>
                                <th style={{width:"150px"}}><span style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}></span>
                                    </th>
                                <th style={{width:"100px"}}>LUNDI</th>
                                <th style={{width:"100px"}}>MARDI</th>
                                <th style={{width:"100px"}}>MERCREDI</th>
                                <th style={{width:"100px"}}>JEUDI</th>
                                <th style={{width:"100px"}}>VENDREDI</th>
                                <th style={{width:"100px"}}>SAMEDI</th>
                                <th style={{width:"100px"}}>DIMANCHE</th>
                            </tr>
                        </thead> 
                        <tbody>
                            <tr>
                                <th scope="row" style={{width:"150px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>HEURE ARRIVEE</th>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.lundiArriveeTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.mardiArriveeTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.mercrediArriveeTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.jeudiArriveeTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.vendrediArriveeTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.samediArriveeTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.dimancheArriveeTheorie}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{width:"150px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>HEURE DEPART</th>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.lundiDepartTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.mardiDepartTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.mercrediDepartTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.jeudiDepartTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.vendrediDepartTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.samediDepartTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.dimancheDepartTheorie}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{width:"150px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>NBRE D'HEURES</th>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureLundiTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureMardiTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureMercrediTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureJeudiTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureVendrediTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureSamediTheorie}</td>
                                <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px",fontWeight: "bold"}}>{this.props.nbreHeureDimancheTheorie}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className="justify-content-center" style={{marginBottom:"10px", textAlign:"left", }}>
                <Col md={12}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal", textAlign:"left"}}> Nombre d'heures par semaine:  </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.props.nbreHeureSemaine}</span>      
                </Col>
            </Row>

            <Row className="justify-content-center" style={{marginBottom:"20px", textAlign:"left", }}>
                <Col md={12}>
                    <h3 style={{display:"inline", fontSize:"15px", fontWeight:"normal", textAlign:"left"}}> Nombre d'heures par mois:  </h3>
                    <span style={{display:"inline", fontSize:"15px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>{this.props.nbreHeureMois}</span>      
                </Col>
            </Row>


            <Row className="justify-content-center" style={{textAlign:"left", marginBottom: "20px", marginTop: "10px"}}>
                <Col md={12}>
                    <Button style={{ marginBottom:"10px", marginRight:"20px", fontSize: "14px", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bolder", 
                        backgroundColor:"red", fontSize:"12px", width:"80px"}}
                        onClick={this.handleSubmitConfirmSup}>
                        Supprimer
                    </Button> 
                    <Button style={{ marginBottom:"10px", fontSize: "14px", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bolder", 
                        backgroundColor:"#0B4E9D", fontSize:"12px", width:"80px"}}
                        onClick={this.handleSubmitMaj}>
                        Modifier
                    </Button> 
                </Col>
            </Row>


            <Row className="justify-content-center" style={{textAlign:"center", marginBottom: "20px"}}>
                <Col md={12}>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                    <img src="./images/ligne.png" height="80px" alt="ASP" style={{}}></img>
                </Col>
            </Row>
      
       </Container>
        
        );
    }
}
 
function mapStatetoProps(state) {
  
  
    return { userIdfromStore: state.userId, siteIdfromStore: state.siteId }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idSite) {
          dispatch( {type: 'connect1',
          idSite
          }
        )
      }
    }
  }
  
  
    export default connect(
      mapStatetoProps,
      mapDispatchToProps
      )(TableauSite);