import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import TableauSite from './TableauSite';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class SitesPerso extends Component {

    constructor(props){
        super(props)
        this.handleSubmitAddSite = this.handleSubmitAddSite.bind(this)

        this.state = ({     
            matricule:"",
            prenom:"",
            nom:"",
            numeroTelSalarie:"",
            prenomCharge:"",
            nomCharge:"",
            numeroTelCharge:"",
            emailCharge:"",
            isUserExist:false,
            allSite: [],
        })}
 

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/pointagesalarie?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           var userSiteList = [];
           for (var i =0; i<data.user.listSites.length; i++) {
              userSiteList.push(data.user.listSites[i])
          }
          ctx.setState({
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            numeroTelSalarie: data.user.numeroTelSalarie,
            prenomCharge: data.user.prenomCharge,
            nomCharge: data.user.nomCharge,
            numeroTelCharge: data.user.numeroTelSalarie,
            emailCharge: data.user.emailCharge,
            allSite: userSiteList})
        })
        .catch((error)=> {
            console.log('Request failed in my Pointage request', error)
        });
    }


    handleSubmitAddSite(){
        let ctx=this;
    
        fetch(`${ip}/addSite?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
            return res.json()
          })
          .then((data)=>{
            if (data.result === true){
              ctx.props.saveId(data.user._id)
              console.log("pointage", data.user._id)
              if (data.result === true){      
                this.setState({
                  isUserExist:true
               })
              } else {
                
                this.setState({
                  isUserExist:false
               })}
              }
            }) 
            .catch((error)=> {
              console.log('Request failed in my pointage request', error)
          });
         }


    render(){

      var usersListSite = [];
      var usersListSiteCopy=[...this.state.allSite]
    
    usersListSite = usersListSiteCopy.map((data,i) =>{
    return  (  
        <TableauSite
            key={i}
            nomSite={data.nomSite}
            adresseSite={data.adresseSite}
            nomClientSite={data.nomClientSite}
            prenomClientSite={data.prenomClientSite}
            numeroTelClientSite={data.numeroTelClientSite}
            lundiArriveeTheorie={data.lundiArriveeTheorie}
            lundiDepartTheorie={data.lundiDepartTheorie}
            mardiDepartTheorie={data.mardiDepartTheorie}
            mercrediDepartTheorie={data.mercrediDepartTheorie}
            jeudiDepartTheorie={data.jeudiDepartTheorie}
            vendrediDepartTheorie={data.vendrediDepartTheorie}
            samediDepartTheorie={data.samediDepartTheorie}
            dimancheDepartTheorie={data.dimancheDepartTheorie}
            mardiArriveeTheorie={data.mardiArriveeTheorie}
            mercrediArriveeTheorie={data.mercrediArriveeTheorie}
            jeudiArriveeTheorie={data.jeudiArriveeTheorie}
            vendrediArriveeTheorie={data.vendrediArriveeTheorie}
            samediArriveeTheorie={data.samediArriveeTheorie}
            dimancheArriveeTheorie={data.dimancheArriveeTheorie}
            nbreHeureLundiTheorie={data.nbreHeureLundiTheorie}
            nbreHeureMardiTheorie={data.nbreHeureMardiTheorie}
            nbreHeureMercrediTheorie={data.nbreHeureMercrediTheorie}
            nbreHeureJeudiTheorie={data.nbreHeureJeudiTheorie}
            nbreHeureVendrediTheorie={data.nbreHeureVendrediTheorie}
            nbreHeureSamediTheorie={data.nbreHeureSamediTheorie}
            nbreHeureDimancheTheorie={data.nbreHeureDimancheTheorie}
            nbreHeureSemaine={data.nbreHeureSemaine}
            nbreHeureMois={data.nbreHeureMois}
            idSite={data._id}
            />)})
        
        console.log('usersListSite', usersListSite)

        if (this.state.isUserExist){
            return < Redirect to="/AddSite" />
          }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                
                <BreadcrumbItem className="link">
                  <Link to="/PointagesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    1. Pointages
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link" style={{fontSize: "14px"}}>
                    2. Plannings
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Demandes de congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    4. Demandes d'acomptes
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Profil
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>


            <Row className="justify-content-center text-left align-items-center" 
             style={{}}>
                <Col md={7}  style={{}}>
                  <h1 style={{fontWeight:"bold", fontStyle:'italic', fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px",}}>
                  <img src="./images/homme.png" height="25px" style={{marginRight: "10px"}}/>Collaborateur: 
                    <span style={{fontStyle:'italic', marginLeft: "5px",  fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px"}}>
                        {this.state.nom} {this.state.prenom} <span style={{fontWeight: "normal"}}>(Matricule: {this.state.matricule})</span>
                    </span>
                  </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Plannings
                    </h1>
                </Col>
            </Row>
           
            <Row className="justify-content-center text-center align-items-center" >
              <Col className="justify-content-center align-items-center" md={12}>
                {usersListSite}                
              </Col>
            </Row>   
            <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                   >
                      <Link to="/Salaries" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>

                  <Button 
                    style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    onClick={()=>{
                    this.handleSubmitAddSite() }}>
                      <Link to="/AddPlanning" style={{color:"white"}}>
                        Ajouter un planning
                      </Link>
                  </Button>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    console.log("ttt pointage", state)
    console.log('je recois de mon reducer ID suivant : ',state.userId)
    


    return { userIdfromStore: state.userId }
  }
  
 


  export default connect(
    mapStatetoProps,
    null
    )(SitesPerso);