import React, { Fragment }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import ip from './ipconfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Input, Button} from 'reactstrap';
import {Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux'; 

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";


const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};




class ListeMaterielsParSite extends React.Component {

    constructor() {
      super();
      this.handleSubmitMaterielDetails = this.handleSubmitMaterielDetails.bind(this);
      this.handleSubmitConfirmSup = this.handleSubmitConfirmSup.bind(this);
      this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);
  
      this.state={
        allMateriels:[],
        visibleFilters: [],
        nomSite: "",
        adresseSite: "",
        nbre: "",
        isClick: false,
        isClick1: false
      }};
      
    
      componentDidMount(){
        let ctx=this;
    
        fetch(`${ip}/listeMaterielsParSite?idSite=${this.props.siteInfoIdfromStore}`)
        .then(function(res, err){
            return res.json()
          }).then((data)=> {
            ctx.setState({
              allMateriels: data.materiels,
              nomSite: data.site.nomSite,
              adresseSite: data.site.adresseSite,
              nbre: data.nbre
            })
          })
          .catch((error)=> {
              console.log('Request failed in listeMaterielsParSites', error)
          });
      }


      toggleFilter = id => {
        this.setState(({ visibleFilters }) => {
          let update = [...visibleFilters];
          const index = update.indexOf(id);
          index < 0 ? update.push(id) : update.splice(index, 1);
          return { visibleFilters: update };
        });
      };
  

      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
    if (typeof content !== 'undefined') {

        if (filter.value.length < 4) {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
            }
        } else {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

    }}




        handleSubmitMaterielDetails(data){
          let ctx=this;
      
          fetch(`${ip}/materielVoir?idMateriel=${data._id}`)
          .then(function(res, err){
            return res.json()
          }).then((data)=>{
              ctx.props.saveId(data.materielDetail._id)
              console.log('idMateriel', data.materielDetail._id)
              ctx.setState ({
                  isClick:true,
                })
              }
            ) 
            .catch((error)=> {
              console.log('Request failed in my handleSubmitMaterielDetails request', error)
          });
        }  


        handleSubmitConfirmSup(data){
          let ctx=this;
      
          fetch(`${ip}/materielVoir?idMateriel=${data._id}`)
          .then(function(res, err){
            return res.json()
          }).then((data)=>{
              ctx.props.saveId(data.materielDetail._id)
              console.log('idMateriel', data.materielDetail._id)
              ctx.setState ({
                  isClick1:true,
                })
              }
            ) 
            .catch((error)=> {
              console.log('Request failed in my handleSubmitConfirmSup request', error)
          });
        }  

        
        
        getTrPropsType(state, rowInfo, column) {
          const { original } = rowInfo;
          const { color } = original;    
          return {
            
              style: {
                  borderColor: color
              },
          }
        }


    render(){

    if (this.state.isClick){
      return < Redirect to="/MaterielDetails" />
    }

    if (this.state.isClick1){
      return < Redirect to="/MaterielConfirmSup" />
    }

    var materielsList = [];
    var materielsListCopy = [ ...this.state.allMateriels]
    
    materielsList = materielsListCopy.map((data,i) =>{
    return  (  
      {
        nomMateriel: data.nomMateriel,
        marque: data.marque,
        voir: <Button style={{backgroundColor:"white", border:"none"}} 
                onClick={()=>{
                  this.handleSubmitMaterielDetails(data) 
                }}>
        <img src="./images/oeil.png" height="30px" /></Button>,
        supprimer: <Button style={{backgroundColor:"white", border:"none"}} 
                onClick={()=>{
                  this.handleSubmitConfirmSup(data) 
                }}>
        <img src="./images/annulation.png" height="25px" /></Button>
      }
      )})
        
  
        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"50px", marginBottom:"50px", height:"80px", fontFamily:"Quicksand, 'sans-serif'"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "30px"}}>
                        Matériels</h1>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginBottom:"40px", fontSize:"14px"}}> 
                <Col style={{textAlign:"center"}}>
                  SITE:                   
                  <span style={{paddingLeft:"5px", paddingRight:"30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                    {this.state.nomSite}
                  </span>
                  ADRESSE:                   
                    <span style={{paddingLeft:"5px", paddingRight: "30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                      {this.state.adresseSite}
                    </span>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginBottom:"40px", fontSize:"14px"}}> 
                <Col style={{textAlign:"center"}}>
                  <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                      <Link to="/AddMateriel" style={{color:"white"}}>Ajouter un matériel</Link>
                  </Button>
                </Col>
            </Row>


            {this.state.nbre === 0 ? (
            <Row className="justify-content-center text-center align-items-center" 
            style={{marginTop:"40px", marginBottom:"50px", height:"18px", fontFamily:"Quicksand, 'sans-serif'"}}>
               <Col md={12}>
                   <span style={{fontStyle: "italic", fontSize:"20px"}}>Il n'y a pas de matériel.</span>
               </Col>
           </Row>):(
          <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
            <ReactTable
              previousText="Précédent"
              nextText="Suivant"
              rowsText="lignes"
              ofText= 'à'
              defaultFilterMethod={this.filterCaseInsensitive}
              data={materielsList}
              style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
              resolveData={data => data.map(row => row)} 
              columns={[
                {
                  columns: [
                    {
                      Header: props => (
                        <FilterToggleHeader
                          {...props}
                          onToggle={this.toggleFilter}
                        />
                      ),
                      label: "Matériel",
                      accessor: "nomMateriel",
                      filterable: true,
                      style:{fontWeight: 'bolder', margin: 'auto',},
                      minWidth: 270,
                    
                    },
                    {
                      Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Marque</div>,
                      accessor: "marque",
                      style:{fontWeight: 'normal', margin: 'auto'},
                      minWidth: 400
                    },
                    {
                      Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Voir</div>,
                      accessor: "voir"
                    },
                    {
                      Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Supprimer</div>,
                      accessor: "supprimer"
                    }                       
                  ],
                  
                },
                
              ]
              
              }
              getTheadFilterProps={(state, rowInfo, column, instance) => {
                return {
                  style:
                    this.state.visibleFilters.length === 0
                      ? { display: "none" }
                      : null
                };
              }}
              getTheadFilterThProps={(state, rowInfo, column, instance) => {
                return {
                  className:
                    this.state.visibleFilters.indexOf(column.id) < 0
                      ? "hiddenFilter"
                      : null
                };
              }}
              defaultPageSize={10}
              getTrPropsType= {this.getTrPropsType}
              className="-striped -highlight justify-content-center text-center align-items-center "
            />
          </Row>)}

            <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    >
                      <Link to="/ListeMateriels" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
     
        )
    }
}

function mapStatetoProps(state) {
  return { siteInfoIdfromStore: state.siteInfoId, materielIdfromStore: state.materielId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMateriel) {
        dispatch( {type: 'connect7',
         idMateriel,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(ListeMaterielsParSite);