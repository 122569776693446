import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import TableauPointage from './TableauPointage';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';


class PointagesPersoSites extends Component {

    constructor(props){
        super(props)
        this.state = ({     
            matricule:"",
            prenom:"",
            nom:"",
            isUserExist:false,
            allSite: [],
        })}
 

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/pointageSiteSalarie?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
          return res.json()
        })
        .then((data)=> {
          ctx.setState({
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            allSite: data.site})
        })
        .catch((error)=> {
            console.log('Request failed in my PointagesPerso request', error)
        });
    }


    render(){

      var usersListSite = [];
      var usersListSiteCopy=[...this.state.allSite]
    
    usersListSite = usersListSiteCopy.map((data,i) =>{
    return  (  
        <TableauPointage
            key={i}
            nomSite={data.nomSite}
            adresseSite={data.adresseSite}
            idSite={data._id}
        />)})
        
        console.log('usersListSite', usersListSite)

        if (this.state.isUserExist){
            return < Redirect to="/AddSite" />
          }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link" style={{fontSize: "14px"}}>
                    1. Pointages
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Plannnigs
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      onClick={()=>{
                      console.log('j ai cliqué sur le Site'); 
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Demandes de congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      onClick={()=>{
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    4. Demandes d'acomptes
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Profil
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>

            <Row className="justify-content-center text-left align-items-center" 
             style={{}}>
                <Col md={7}  style={{}}>
                  <h1 style={{fontWeight:"bold", fontStyle:'italic', fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px",}}>
                  <img src="./images/homme.png" height="25px" style={{marginRight: "10px"}}/>Collaborateur: 
                    <span style={{fontStyle:'italic', marginLeft: "5px",  fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px"}}>
                        {this.state.nom} {this.state.prenom} <span style={{fontWeight: "normal"}}>(Matricule: {this.state.matricule})</span>
                    </span>
                  </h1>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Pointages par site
                    </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ marginBottom: "20px"}}>
                <Col md={6}>
                  
                </Col>
                <Col md={6}>
                  <img src="./images/oeil.png" height="25px" />
                  <Link to="/PointagesPerso" 
                      className="link"
                      style={{marginLeft: "5px", textDecoration:"underline" ,fontWeight:"normal", fontStyle: 'italic',  fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}>
                    Tous les pointages
                  </Link>
                </Col>
            </Row>
            
            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "0px"}} >
                
                <Table
                  className="tableau text-center align-items-center" 
                  style={{width:"800px"}}>
  
                    <thead>
                      <th 
                        style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        SITE
                      </th>
                      <th 
                        style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        ADRESSE
                      </th>
                      <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        VOIR
                      </th>
                    </thead> 
                    <tbody>
                      {usersListSite}
                    </tbody>
                </Table>
                  
              </Row>

              <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    >
                      <Link to="/Salaries" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>
                </Col>
            </Row>

  
            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    return { userIdfromStore: state.userId }
  }
  
   export default connect(
    mapStatetoProps,
    null
    )(PointagesPersoSites);