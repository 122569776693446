import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import ip from './ipconfig';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Button} from 'reactstrap';
import {connect} from 'react-redux'; 


class PlanningConfirmSup extends Component {   
    constructor(props){
        super(props);
        this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this)

        this.state = ({     
            nomSite: '',
            nom: '',
            prenom: '',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/infoPlanningAmettreAjour?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                matricule: data.user.matricule,
                nom: data.user.nom,
                prenom: data.user.prenom,
                nomSite: data.site.nomSite,
              })
            })
            .catch((error)=> {
                console.log('Request failed in my PlanningSup', error)
            });
          }


          handleSubmitConfirm() {
            let ctx = this;
        
            fetch(`${ip}/deletePlanning?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}`)
                .then(function(res, err){
                  return res.json()
                }).then((data)=> {
                   ctx.setState({
                    isClick: true                   
                   })
                })
                .catch((error)=> {
                    console.log('Request failed in my /planningconfirm request', error)
                });
            }
        




    render(){

        if (this.state.isClick){
            return < Redirect to="/SuccesDeletePlanning" />
          }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" 
             style={{height:"500px", textAlign:"right"}}>
                <Col md={5}>
                    <img src="./images/danger.jpg" height="300px" alt="Enregistrement réussi" style={{borderRadius:"8px"}}></img>
                </Col>

                <Col md={7}>
                    <Row className="text-center align-items-center justify-content-center">
                        <h1 style={{fontWeight:"normal", paddingTop: "40px",  fontFamily:"Quicksand, 'sans-serif'",fontSize: "20px", paddingBottom:"10px",}}>
                        Vous allez supprimer le planning:
                            <span style={{marginLeft: "10px", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "20px"}}>{this.state.nomSite} </span>
                        </h1>
                    </Row>
                    <Row className="text-center align-items-center justify-content-center">
                        <h1 style={{fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "20px", paddingBottom:"30px",}}>
                            (Collaborateur concerné:
                            <span style={{marginLeft: "10px", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "20px"}}>{this.state.nom} {this.state.prenom} </span>
                            )
                        </h1>
                    </Row>
                    <Row className="text-center align-items-center justify-content-center">
                        <h1 style={{fontWeight:"bolder", paddingBottom:"20px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "24px"}}>
                        Confirmez-vous cette suppression ?
                        </h1>
                    </Row>
                    <Row className="justify-content-center text-center align-items-center">
                        <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                            <Link to="/SitesPerso" style={{color:"white"}}>Retour</Link>
                        </Button>
                        <Button style={{margin:"20px", backgroundColor: "#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                            onClick={()=>{ this.handleSubmitConfirm()}}>
                            Confirmer
                        </Button>
                    </Row>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
  
  
    return { userIdfromStore: state.userId, siteIdfromStore: state.siteId }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      saveId: function(idSite) {
          dispatch( {type: 'connect1',
          idSite
          }
        )
      }
    }
  }
  
  
    export default connect(
      mapStatetoProps,
      mapDispatchToProps
      )(PlanningConfirmSup);