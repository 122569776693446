import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Input, Button, Form, FormGroup, Label  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';



class MiseAjourMateriel extends Component {

    constructor(props){
        super(props);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.state = ({     
            nomSite: '',
            nomMateriel: '',
            marque: '',
            remarque: '',
            dateAchat: '',
            isClick: false
        })}
 

        componentDidMount() {
            let ctx = this;
            fetch(`${ip}/materielDetails?id=${ctx.props.userIdfromStore}&idMateriel=${ctx.props.materielIdfromStore}`)
            .then(function(res, err){
              return res.json()
            }).then((data)=> {
              this.setState({
                nomSite: data.materielDetail.nomSite,
                nomMateriel: data.materielDetail.nomMateriel,
                marque: data.materielDetail.marque,
                remarque: data.materielDetail.remarque,
                dateAchat: data.materielDetail.dateAchat,
              })
            })
            .catch((error)=> {
                console.log('Request failed in my MiseAjourMateriel', error)
            });
          }
        

handleSubmitUpdate() {
    let ctx = this;

    fetch(`${ip}/miseAjourMateriel?idMateriel=${ctx.props.materielIdfromStore}`,{
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `idMateriel=${ctx.props.materielIdfromStore}&nomSite=${ctx.state.nomSite}&nomMateriel=${ctx.state.nomMateriel}&marque=${ctx.state.marque}&remarque=${ctx.state.remarque}&dateAchat=${ctx.state.dateAchat}`
      })
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           ctx.setState({
            isClick: true                   
           })
        })
        .catch((error)=> {
            console.log('Request failed in my /miseAjourMateriel request', error)
        });
    }
        
    
    render(){

        if (this.state.isClick){
            return < Redirect to="/ValidationMajMateriel" />
          }


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true} >
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ height:"120px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Mettre à jour le matériel</h1>
                </Col>
            </Row>

            <Form className="text-left" style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px"}}>
                <Row className="justify-content-center align-items-center" 
                style={{paddingBottom:"60px"}} form>             
                    <Col className="justify-content-center align-items-center" md={6}>
                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="nomMateriel" style={{fontWeight: 'bolder'}}>Matériel:</Label>
                                <Input type="text" name="nomMateriel" id="nomMateriel" rows={1}
                                placeholder={this.state.nomMateriel}
                                onChange={(e) => {this.setState({nomMateriel: e.target.value}); }} value={this.state.nomMateriel}/>
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="adresseSite" style={{fontWeight: 'bolder'}}>Marque:</Label>
                                <Input type="text" name="marque" id="marque" rows={1}
                                placeholder={this.state.marque}
                                onChange={(e) =>{this.setState({marque: e.target.value}); }} value={this.state.marque}/>
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}} >
                                <Label for="nomCharge" style={{fontWeight: 'bolder'}}>Date d'achat:</Label>
                                <Input type="text" name="dateAchat" id="dateAchat" rows={1}
                                placeholder={this.state.dateAchat}
                                onChange={(e) =>{this.setState({dateAchat: e.target.value});}} value={this.state.dateAchat}/>
                            </FormGroup>

                            <FormGroup style={{marginBottom: "40px"}}>
                                <Label for="nomCharge" style={{fontWeight: 'bolder'}}>Remarques:</Label>
                                <Input type="textarea" name="remarque" id="remarque" 
                                placeholder={this.state.remarque}
                                onChange={(e) => {this.setState({remarque: e.target.value})}} value={this.state.remarque}/>
                            </FormGroup>      


                            <Row className="justify-content-center text-center align-items-center">
                                <Button style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}>
                                    <Link to="/MaterielDetails" style={{color:"white"}}>Annuler</Link></Button>
                                <Button style={{margin:"20px", backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }} 
                                onClick={()=>{ this.handleSubmitUpdate()}}>Valider</Button>
                            </Row>
                        
                    </Col>
                </Row>
            </Form>





            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {  
    console.log('materielIdfromStore', state.materielId)
      return { materielIdfromStore: state.materielId }
  }
    
    
  function mapDispatchToProps(dispatch) {
      return {
        saveId: function(idMateriel) {
            dispatch( {type: 'connect7',
             idMateriel,
            } );
         }
        }
      }
    
  
      export default connect(
        mapStatetoProps, 
        mapDispatchToProps
      )(MiseAjourMateriel);