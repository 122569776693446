import React, { Component }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import TableauFeuilleSitesPrdtsPerso from './TableauFeuilleSitesPrdtsPerso';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Input, Button, Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';


class FeuilleSitesPrdts extends Component {

    constructor(props){
        super(props)
        this.state = ({     
            matricule:"",
            prenom:"",
            nom:"",
            nomSite: "",
            adresseSite:"",
            allPrdts: [],
        })}

 

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/feuilleSitesPrdts?id=${ctx.props.userIdfromStore}&idSite=${ctx.props.siteIdfromStore}`)
        .then(function(res, err){
          return res.json()
        }).then((data)=> {
           console.log('RESULTAT DE ENREGISTREMENT EN BD USER feuilleSitesPrdts--->', data)

          ctx.setState({
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            allPrdts: data.message,
            nomSite: data.site.nomSite,
            adresseSite: data.site.adresseSite
          })
           
        })
        .catch((error)=> {
            console.log('Request failed in my FeuilleSitesPrdts request', error)
        });
    }
    



    render(){

      var usersListTime = [];
      var usersListTimeCopy=[...this.state.allPrdts]
    
    usersListTime = usersListTimeCopy.map((data,i) =>{
    return  (  
        <TableauFeuilleSitesPrdtsPerso
            key={i}
            day={data.day}
            date={data.date}
            nomSite={data.nomSite}
            listProduits={data.listProduits}
            etat={data.etat}
            remarque={data.remarque}
            matricule={data.matricule}
            nom={data.nom}
            prenom={data.prenom}
            prenomCharge={data.prenomCharge}
            nomCharge={data.nomCharge}
            dateTraitement={data.dateTraitement}
            _id={data._id}
            />)})
        
        console.log('usersListTime', usersListTime)


        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px"}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    1. Profil
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Sites
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/PointagesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Pointages
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link" style={{fontSize: "14px"}}>
                    4. Demandes produits / matériels
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      onClick={()=>{
                      console.log('j ai cliqué sur le Site'); 
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Demandes congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      onClick={()=>{
                      console.log('j ai cliqué sur le Site'); 
                      }}
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    6. Demandes acomptes
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{}}>
                <Col sm={{ size: 1, offset: 7 }} style={{textAlign: "right"}}>
                  <img src="./images/homme.png" height="20px" alt="ASP" style={{}}></img>
                </Col>

                <Col md={3}>
                    <h3 style={{marginLeft: "-20px", fontStyle:'italic', fontWeight:"normal", textAlign:"left", paddingTop: "10px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>
                      {this.state.nom} {this.state.prenom}
                    </h3>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px", height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Liste des demandes de produits / matériel
                    </h1>
                </Col>
            </Row>

            <Row className="justify-content-center align-items-center" style={{marginBottom:"20px", fontSize:"14px"}}> 
                <Col style={{textAlign:"center"}}>
                  SITE:                   
                  <span style={{paddingLeft:"5px", paddingRight:"30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                    {this.state.nomSite}
                  </span>
                  ADRESSE:                   
                    <span style={{paddingLeft:"5px", paddingRight: "30px", fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'"}}>
                      {this.state.adresseSite}
                    </span>
                </Col>
            </Row>



            <Row className="justify-content-center text-center align-items-center" >
              <Col className="justify-content-center align-items-center" md={10}>

                
                  <Table hover id="table-to-xls" className="tableau text-center align-items-center"  >
                        <thead>
                            <tr style={{backgroundColor:"#C9DCF6", fontFamily:"Quicksand, 'sans-serif'",fontSize: "11px"}}>
                                <th style={{width:"50px"}}><span style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'", fontSize:"10px"}}>DATE</span>
                                </th>
                                <th style={{width:"200px"}}>PRODUITS / MATERIEL</th>
                                <th style={{width:"100px"}}>TRAITEE LE</th>
                                <th style={{width:"50px"}}>MODIFIER</th>
                            </tr>
                        </thead> 
                        <tbody>
                        {usersListTime}   
                        </tbody>
                  </Table>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"40px"}} >
                <Col className="justify-content-center align-items-center" md={2}>
                  <Link to="/DemandesPrdtsPerso">
                    <Button style={{fontSize:"15px"}} onClick={()=>{console.log('j ai cliqué'); }}>Retour
                    </Button>
                  </Link>
                </Col>
            </Row>


            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
  console.log("feuilleSitesPrdts", state)
  console.log('je recois de mon reducer ID du user suivant de feuilleSitesPrdts : ',state.userId)
  console.log('je recois de mon reducer ID du site suivant de feuilleSitesPrdts : ',state.siteId)


  return { userIdfromStore: state.userId, siteIdfromStore: state.siteId }
}


  export default connect(
    mapStatetoProps,
    null
    )(FeuilleSitesPrdts);