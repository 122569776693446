import React, { Fragment }  from 'react';
import './styles.css';
import Menu from './Menu';
import Footer from './Footer';
import TableauPointage from './TableauPointage';
import {Redirect, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ip from './ipconfig';
import {connect} from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr'
import TableauFeuillePointageAll from './TableauFeuillePointageAll';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";


const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};


class PointagesPerso extends React.Component {

    constructor(props){
        super(props)
        this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);

        this.state = ({     
            matricule:"",
            prenom:"",
            nom:"",
            isUserExist:false,
            allTime: [],
            allTimePrint: [],
            visibleFilters: [],
        })}
 

    componentDidMount() {
        let ctx = this;
        fetch(`${ip}/pointageAll?id=${ctx.props.userIdfromStore}`)
        .then(function(res, err){
          return res.json()
        })
        .then((data)=> {
          ctx.setState({
            matricule: data.user.matricule,
            prenom: data.user.prenom,
            nom: data.user.nom,
            allTime: data.pointage,
            allTimePrint: data.pointage,
            })
        })
        .catch((error)=> {
            console.log('Request failed in my PointagesPerso request', error)
        });
    }

    toggleFilter = id => {
      this.setState(({ visibleFilters }) => {
        let update = [...visibleFilters];
        const index = update.indexOf(id);
        index < 0 ? update.push(id) : update.splice(index, 1);
        return { visibleFilters: update };
      });
    };
  
  
    filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      const content = row[id];
  if (typeof content !== 'undefined') {
  
      if (filter.value.length < 4) {
          // filter by text in the table or if it's a object, filter by key
          if (typeof content === 'object' && content !== null && content.key) {
              return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
          } else {
              return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
          }
      } else {
          // filter by text in the table or if it's a object, filter by key
          if (typeof content === 'object' && content !== null && content.key) {
              return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
          } else {
              return String(content).toLowerCase().includes(filter.value.toLowerCase());
          }
      }
  
  }}
  

  getTrPropsType(state, rowInfo, column) {
    const { original } = rowInfo;
    const { color } = original;    
    return {
     
        style: {
            borderColor: color
        },
    }
  }


    render(){

      var usersListTime = [];
      var usersListTimeCopy=[...this.state.allTime]
    
    usersListTime = usersListTimeCopy.map((data,i) =>{
    return  (  
          { 
            day: (data.day)[0].toUpperCase()+(data.day).slice(1),
            date: data.date,
            month: (moment(data.month, "MM-YYYY").format("MMMM YYYY"))[0].toUpperCase()+(moment(data.month, "MM-YYYY").format("MMMM YYYY")).slice(1),
            nomSite: data.nomSite,
            heureArrivee: data.heureArrivee,
            heureDepart: data.heureDepart,
            nbreHeureTravaillee: data.nbreHeureTravaillee
          }
          )})
        
        console.log('usersListTime', usersListTime)

        var usersListTimePrint = [];
        var usersListTimePrintCopy=[...this.state.allTimePrint]
      
      usersListTimePrint = usersListTimePrintCopy.map((data,i) =>{
      return  (  
          <TableauFeuillePointageAll
              key={i}
              day={data.day}
              date={data.date}
              nomSite={data.nomSite}
              heureArrivee={data.heureArrivee}
              heureDepart={data.heureDepart}
              nbreHeureTravaillee={data.nbreHeureTravaillee}
              />)})
          
          console.log('usersListTime', usersListTimePrint)
  



        if (this.state.isUserExist){
            return < Redirect to="/AddSite" />
          }

        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px", marginBottom:"20px",}}>
              <Breadcrumb                       
                style={{ fontWeight:"normal", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", color:"black"}}>
                <BreadcrumbItem className="link" style={{fontSize: "14px"}}>
                    1. Pointages
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/SitesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    2. Plannnigs
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesCongesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    3. Demandes de congés
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/DemandesAcomptesPerso" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    4. Demandes d'acomptes
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem className="link">
                  <Link to="/Pointage" 
                      className="link"
                      style={{fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}
                  >
                    5. Profil
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>

            <Row className="justify-content-center text-left align-items-center" 
             style={{}}>
                <Col md={7}  style={{}}>
                  <h1 style={{fontWeight:"bold", fontStyle:'italic', fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px",}}>
                  <img src="./images/homme.png" height="25px" style={{marginRight: "10px"}}/>Collaborateur: 
                    <span style={{fontStyle:'italic', marginLeft: "5px",  fontWeight:"bold", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px"}}>
                        {this.state.nom} {this.state.prenom} <span style={{fontWeight: "normal"}}>(Matricule: {this.state.matricule})</span>
                    </span>
                  </h1>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"20px",  height:"100px"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "25px"}}>
                        Tous les pointages
                    </h1>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{ }}>
                <Col md={6}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    table="table-to-xls"
                    filename="Feuille de pointage"
                    sheet="tablexls"
                    buttonText="Télécharger"
                    className="buttonExcelAll"/>
                </Col>
                <Col md={6}>
                  <img src="./images/oeil.png" height="25px" />
                  <Link to="/PointagesPersoSites" 
                      className="link"
                      style={{marginLeft: "5px", textDecoration:"underline" ,fontWeight:"normal", fontStyle: 'italic',  fontFamily:"Quicksand, 'sans-serif'",
                      fontSize: "14px"}}>
                    Pointage par site
                  </Link>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" style={{display:'none'}}>
              <Col className="justify-content-center align-items-center" md={10}>
                                
                  <Table hover id="table-to-xls" 
                    className="tableau text-center align-items-center" 
                    style={{width: '1000px', }}>
  
                    <thead>
                      <th 
                        style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        JOUR
                      </th>
                      <th 
                        style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        DATE
                      </th>
                      <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"300px"}} >
                        SITE
                      </th>
                      <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        ARRIVEE
                      </th>
                      <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        DEPART
                      </th>
                      <th style={{backgroundColor:"#C9DCF6", fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "14px", width:"100px"}} >
                        TOTAL
                      </th>
                    </thead> 
                    <tbody>
                      {usersListTimePrint}
                    </tbody>
                </Table>
                </Col>  
              </Row>

              <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
                    <ReactTable 
                      previousText="Précédent"
                      nextText="Suivant"
                      rowsText="lignes"
                      ofText= 'à'
                      defaultFilterMethod={this.filterCaseInsensitive}
                      data={usersListTime}
                      style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
                      resolveData={data => data.map(row => row)} 
                      columns={[
                        {
                          columns: [
                            {
                              Header: props => (
                                <FilterToggleHeader
                                  {...props}
                                  onToggle={this.toggleFilter}
                                />
                              ),
                              label: "Mois",
                              accessor: "month",
                              Cell     : row => {
                                return (
                                  <div style={{}}>
                                    {row.row.month} 
                                  </div>
                                )
                              },
                              filterable: true,
                              style:{fontWeight: 'normal', margin: 'auto'},
                              minWidth: 150,
                            },
                            {
                              Header: props => (
                                <FilterToggleHeader
                                  {...props}
                                  onToggle={this.toggleFilter}
                                />
                              ),
                              label: "Jour",
                              accessor: "day",
                              filterable: true,
                              style:{fontWeight: 'normal', margin: 'auto'},
                              minWidth: 150,
                            },
                            {
                              Header: props => (
                                <FilterToggleHeader
                                  {...props}
                                  onToggle={this.toggleFilter}
                                />
                              ),
                              label: "Date",
                              accessor: "date",
                              filterable: true,
                              style:{fontWeight: 'bolder', margin: 'auto',},
                              minWidth: 100,
                            },
                            {
                              Header: props => (
                                <FilterToggleHeader
                                  {...props}
                                  onToggle={this.toggleFilter}
                                />
                              ),
                              label: "Site",
                              accessor: "nomSite",
                              filterable: true,
                              style:{fontWeight: 'normal', margin: 'auto'},
                              minWidth: 250,
                            },
                            {
                              Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Arrivée</div>,
                              accessor: "heureArrivee",
                              style:{fontWeight: 'normal', margin: 'auto'},
                              minWidth: 100
                            },
                            {
                              Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Départ</div>,
                              accessor: "heureDepart",
                              style:{fontWeight: 'normal', margin: 'auto'},
                              minWidth: 100,
                              getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                    },
                                };
                            },

                            },
                            {
                              Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Total</div>,
                              accessor: "nbreHeureTravaillee",
                              style:{fontWeight: 'bold', margin: 'auto'},
                              minWidth: 100,
                              getProps: (state, rowInfo, column) => {
                                return {
                                    style: {
                                      color: 'red'
                                    },
                                };
                            },
                            },
                          ],
                          
                        },                 
                      ]
                      
                      }
                      getTheadFilterProps={(state, rowInfo, column, instance) => {
                        return {
                          style:
                            this.state.visibleFilters.length === 0
                              ? { display: "none" }
                              : null
                        };
                      }}
                      getTheadFilterThProps={(state, rowInfo, column, instance) => {
                        return {
                          className:
                            this.state.visibleFilters.indexOf(column.id) < 0
                              ? "hiddenFilter"
                              : null
                        };
                      }}
                      defaultPageSize={10}
                      getTrPropsType= {this.getTrPropsType}
                      className="-striped -highlight justify-content-center text-center align-items-center "
                    />
              </Row>


              <Row className="justify-content-center text-center align-items-center" style={{marginBottom:"50px"}} >
                <Col className="justify-content-center align-items-center" md={12}>
                  <Button 
                    style={{margin:"20px", backgroundColor: "grey", fontFamily:"Quicksand, 'sans-serif'",fontSize: "15px", fontWeight:"bolder" }}
                    >
                      <Link to="/Salaries" style={{color:"white"}}>
                        Retour
                      </Link>
                  </Button>
                </Col>
            </Row>

  
            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row>
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
    return { userIdfromStore: state.userId }
  }
  
   export default connect(
    mapStatetoProps,
    null
    )(PointagesPerso);