export default function(idAnomalie = '', action) {

  if(action.type == 'connect6') {
       console.log('Dans mon reducer --->',action.idAnomalie)
       return action.idAnomalie;
  } else {
      return idAnomalie;
  }
}

