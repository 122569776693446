import React, { Fragment }  from 'react';
import './styles.css';
import Menu from './Menu';
import {Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux'; 

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Footer from './Footer';
import ip from './ipconfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col,  Container, Table, Input, Button} from 'reactstrap';

// Import React Table
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";


const FilterToggleHeader = ({ data, column, onToggle }) => {
  return (
    <Fragment>
      <span style={{ padding: "0 10px", fontWeight: 'bolder' }}>{column.label}</span>
      <button onClick={() => onToggle(column.id)}>
      <img src="./images/flecheBas.png" height="10px" alt="ASP" style={{ }}></img>

      </button>
    </Fragment>
  );
};



class ListeMateriels extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmitMateriel = this.handleSubmitMateriel.bind(this);
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this);

      this.state={
        allSites:[],
        visibleFilters: [],
      }};
      
    
      componentDidMount(){
        let ctx=this;
    
        fetch(`${ip}/sites`)
        .then(function(res, err){
            return res.json()
          }).then((data)=> {
            ctx.setState({
              allSites: data.site,
            })
          })
          .catch((error)=> {
              console.log('Request failed in Site', error)
          });
            }

        toggleFilter = id => {
          this.setState(({ visibleFilters }) => {
            let update = [...visibleFilters];
            const index = update.indexOf(id);
            index < 0 ? update.push(id) : update.splice(index, 1);
            return { visibleFilters: update };
          });
        };
    

        handleSubmitMateriel(data){
          let ctx=this;
  
          fetch(`${ip}/anomaliesParSite?nomSite=${data.nomSite}&adresseSite=${data.adresseSite}`)
          .then(function(res, err){
            return res.json()
          })
          .then((data)=>{
            ctx.props.saveId(data.site._id)
            console.log("TableauListeGeneralSites", data.site._id)
            ctx.setState ({
                isClick:true,
              })
            }
          )
        } 
      
      
      getTrPropsType(state, rowInfo, column) {
        const { original } = rowInfo;
        const { color } = original;    
        return {
          
            style: {
                borderColor: color
            },
        }
      }


      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
    if (typeof content !== 'undefined') {

        if (filter.value.length < 4) {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
            }
        } else {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

    }}


    render(){

    if (this.state.isClick){
      return < Redirect to="/ListeMaterielsParSite" />
    }
  
    var sitesList = [];
    var sitesListCopy = [ ...this.state.allSites]
    
    sitesList = sitesListCopy.map((data,i) =>{
    return  (  
      {
        nomSite: data.nomSite,
        adresseSite: data.adresseSite,
        charge:{nomCharge: data.nomCharge, prenomCharge: data.prenomCharge},
        voir: <Button style={{backgroundColor:"white", border:"none"}} 
                onClick={()=>{
                  this.handleSubmitMateriel(data) 
                }}>
        <img src="./images/oeil.png" height="30px" /></Button>
      }
      )})
  
        
  
        return (
        <Container className="justify-content-center text-center align-items-center" fluid={true}>
            <Row id={'hautpage'} style={{backgroundColor:"#E5F0FE"}}>
                <Col md={12}>
                    <Menu/>
                </Col>
            </Row>

            <Row className="justify-content-center text-center align-items-center" 
             style={{marginTop:"50px", marginBottom:"50px", height:"80px", fontFamily:"Quicksand, 'sans-serif'"}}>
                <Col md={12}>
                    <h1 style={{fontWeight:"bolder", fontFamily:"Quicksand, 'sans-serif'",fontSize: "30px"}}>
                        Matériels par sites</h1>
                </Col>
            </Row>


            <Row className="justify-content-center text-center align-items-center" style={{marginBottom: "50px"}}>
              <ReactTable
                previousText="Précédent"
                nextText="Suivant"
                rowsText="lignes"
                ofText= 'à'
                data={sitesList}
                defaultFilterMethod={this.filterCaseInsensitive}
                style={{fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}
                resolveData={data => data.map(row => row)} 
                columns={[
                  {
                    columns: [
                      {
                        Header: props => (
                          <FilterToggleHeader
                            {...props}
                            onToggle={this.toggleFilter}
                          />
                        ),
                        label: "Sites",
                        accessor: "nomSite",
                        filterable: true,
                        style:{fontWeight: 'bolder', margin: 'auto',},
                        minWidth: 270,
                       
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Adresse</div>,
                        accessor: "adresseSite",
                        style:{fontWeight: 'normal', margin: 'auto'},
                        minWidth: 400
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Chargé de clientèle/Responsable</div>,
                        accessor: 'charge',
                        Cell     : row => {
                          return (
                            <div>
                              {row.row.charge.nomCharge} {row.row.charge.prenomCharge}
                            </div>
                          )
                        },
                        style:{fontWeight: 'normal', margin: 'auto',},
                        minWidth: 250,
                      },
                      {
                        Header: props => <div style={{fontWeight:'bolder', margin:'auto'}}>Voir</div>,
                        accessor: "voir"
                      } ,
                                           
                    ],
                    
                  },
                  
                ]
                
                }
                getTheadFilterProps={(state, rowInfo, column, instance) => {
                  return {
                    style:
                      this.state.visibleFilters.length === 0
                        ? { display: "none" }
                        : null
                  };
                }}
                getTheadFilterThProps={(state, rowInfo, column, instance) => {
                  return {
                    className:
                      this.state.visibleFilters.indexOf(column.id) < 0
                        ? "hiddenFilter"
                        : null
                  };
                }}
                defaultPageSize={10}
                getTrPropsType= {this.getTrPropsType}
                className="-striped -highlight justify-content-center text-center align-items-center "
              />
        </Row>

            <Row className="scroll_to_top">
                <a href="#hautpage"><img src="./images/icons8-flèche-haut-30.png"></img></a>
            </Row>

            <Row className="justify-content-center text-center align-items-center" >
                <Footer/>
            </Row>
            
        </Container>
        )
    }
}

function mapStatetoProps(state) {
  return { siteInfoIdfromStore: state.siteInfoId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idSiteInfo) {
        dispatch( {type: 'connect5',
         idSiteInfo,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(ListeMateriels);