import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button, Modal, ModalFooter, ModalHeader,ModalBody } from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux'; 
import './App.css';
import ip from './ipconfig';


 
class TableauDemandesAcomptesPerso extends Component {
  constructor(props) {
      super(props);
      this.handleSubmitDemandeModifierAcompte = this.handleSubmitDemandeModifierAcompte.bind(this)
      this.toggle = this.toggle.bind(this);
      this.state = {
        modal: props.initialModalState,
        fade: true,
        isClick: false,
        nom: this.props.nom,
        prenom: this.props.prenom,
        matricule: this.props.matricule,
        prenomCharge: this.props.prenomCharge,
        nomCharge: this.props.nomCharge,
        etat: this.props.etat,
        date: this.props.date,
        dateTraitement: this.props.dateTraitement,
        montantAccompte: this.props.montantAccompte,
        remarque: this.props.remarque
      };       
          
    }


    toggle() {
      this.setState({
          modal: !this.state.modal,
          fade: !this.state.fade
      });
  }

    handleSubmitDemandeModifierAcompte(){
      let ctx=this;
  
      fetch(`${ip}/demandesModifAcompte?matricule=${ctx.state.matricule}&nom=${ctx.state.nom}&prenom=${ctx.state.prenom}&date=${ctx.state.date}&nomCharge=${ctx.state.nomCharge}&prenomCharge=${ctx.state.prenomCharge}&montantAccompte=${ctx.state.montantAccompte}`)
      .then(function(res, err){
        return res.json()
      }).then((data)=>{
          ctx.props.saveId(data.message._id)
          console.log("tableaudemandeAcompte", data.message._id)
          ctx.setState ({
              isClick:true,
            })
          }
        ) 
        .catch((error)=> {
          console.log('Request failed in my tableaudemandeAcomptePerso request', error)
      });
    }

       


  render() {

    if (this.state.isClick){
      return < Redirect to="/ModifDemandeAcomptePerso" />
    }

    var dateTraitementDemande= this.state.dateTraitement
    var dateTraitementDemandeSplit = dateTraitementDemande.split('-')
    var dateTraitementDemandeJoin = dateTraitementDemandeSplit.join('/')

    var button

    if (this.props.etat === "Acceptée"){
      button = <span style={{color: "green", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
          {this.props.etat}
        </span>
    } 
    else if (this.props.etat === "Refusée"){
      button= <span style={{color: "red", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
          {this.props.etat}
        </span>
    }
    else {
     button = <span style={{color: "orange", paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold",}}>
          {this.props.etat}
        </span>
    }




      return (
                    <tr className="text-center">
                      <th scope="row" style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px", }}>{this.props.date}</th>
                      <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{this.props.montantAccompte} €</td>
                      <td style={{width:"100px", fontFamily:"Quicksand, 'sans-serif'",fontSize: "12px"}}>{button}</td>
                      <td style={{width:"100px"}}><Button style={{backgroundColor:"white", border:"none"}} 
                        onClick={this.toggle} >
                        <img src="./images/oeil.png" height="30px" /></Button>
                        
                <Modal isOpen={this.state.modal} toggle={this.toggle}
                       fade={this.state.fade}
                       className={this.props.className}
                       >
                    <ModalHeader toggle={this.toggle} style={{fontWeight:"bolder", backgroundColor:"#EAF4FB", fontFamily:"Quicksand, 'sans-serif'", fontSize:"14px"}}>Détails de la demande</ModalHeader>
                    <ModalBody style={{display:"inline",  backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Date de la demande: 
                        <span style={{paddingLeft:"5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.date}
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Demandeur: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                           {this.props.nom} {this.props.prenom} </span>
                        (Matricule: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>{this.props.matricule}</span>)
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Chargé de clientèle / Responsable concerné: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.nomCharge} {this.props.prenomCharge}
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Montant demandé: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.montantAccompte} €
                        </span>
                    </ModalBody>
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Etat: 
                          {button}
                    </ModalBody>

                    {this.props.etat !== "En cours de traitement" ? (
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Demande traitée le: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {dateTraitementDemandeJoin}
                        </span>
                    </ModalBody>) : (null)
                    }

                    {this.props.etat !== "En cours de traitement" ? (
                    <ModalBody style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"14px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        Remarques: 
                        <span style={{paddingLeft: "5px", display:"inline", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}>
                          {this.props.remarque}
                        </span>
                    </ModalBody>) : (null)
                    }

                    <ModalFooter style={{display:"inline", backgroundColor:"#EAF4FB", fontSize:"12px", fontFamily:"Quicksand, 'sans-serif'"}} >
                        <Button style={{fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}
                         onClick={this.toggle}>Fermer</Button>
                        <Button style={{backgroundColor:"#0B4E9D", fontFamily:"Quicksand, 'sans-serif'", fontWeight:"bold", fontSize:"14px"}}
                        onClick={this.handleSubmitDemandeModifierAcompte}>Mettre à jour</Button>
                    </ModalFooter>
                </Modal></td>

                    </tr>                    
  
      );
  }
}

function mapStatetoProps(state) {
  console.log("tableau demandes Acomptes perso", state)
  console.log('je recois de mon reducer ID du message suivant de tableaudemande Acomptes perso : ',state.messageAcompteId)


  return { messageAcompteIdfromStore: state.messageAcompteId }
}


function mapDispatchToProps(dispatch) {
  return {
    saveId: function(idMessageAcompte) {
      console.log('ttt')
        dispatch( {type: 'connect4',
         idMessageAcompte,
        } );
     }
    }
  }
  
  export default connect(
    mapStatetoProps, 
    mapDispatchToProps
  )(TableauDemandesAcomptesPerso);